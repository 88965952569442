import { Box, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { FaClock, FaTimes } from 'react-icons/fa';
import { MdMarkAsUnread } from 'react-icons/md';
import TaskDetails from 'screens/ChoppingInterface/TaskDetails';
import styles from 'screens/Notifications/Notification.module.css';
import closeAction from '../../assets/icons/closeAction.svg';
import { debounce } from 'lodash';

export const TaskDetailsPopup = ({
	open,
	handleClose,
	modalRef,
	selectedTask,
	headerVisible,
	currentNotification,
	handleHeaderScroll,
	handleCloseAsUnread,
	saveForLater,
	activeWorksheet,
	item_id,
	openNextTaskOnNotficationPage,
	comment_id,
	updateTaskOnChoppingInterface,
	shouldScrollToComment,
	parentTitle,
	showDescBox,
	hyperlinkedComment,
	changeEstimate = () => {},
	unreadsPresent,
	updatePrefetchedTask = () => {},
	openedTask
}) => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		if (!open) {
			setTimeout(() => {
				setIsModalOpened(false);
			}, 350);
		} else {
			setIsModalOpened(true);
		}
	}, [open]);

	const scrollhandler = ev => {
		const isScrolling = !!ev.target.scrollTop;
		setIsScrolling(isScrolling);
	};
	return (
		<Modal
			open={isModalOpened}
			aria-labelledby='modal-modal-title'
			onClose={handleClose}
			aria-describedby='modal-modal-description'
			className='modal_root'
			sx={{
				'& > .MuiBackdrop-root': {
					background: 'rgba(0, 0, 0, 0.75);background: rgba(0, 0, 0, 0.75)'
				}
			}}
		>
			<Box
				className={`modal_container ${open ? 'fade_in_bottom' : 'fade_out_bottom'}`}
				ref={modalRef}
			>
				<div
					style={{
						display: 'flex',
						gap: '20px',
						justifyContent: 'flex-end',
						cursor: 'pointer',
						position: 'absolute',
						width: isScrolling ? '100%' : 'fit-content',
						// transition: 'transform 0.1s ease-in-out',
						// transform: `translateY(${headerVisible})`,
						top: `0px`,
						right: 0,
						background: '#30343C',
						paddingTop: '26px',
						paddingBottom: '15px',
						overflow: 'hidden',
						height: '40px',
						paddingRight: '20px',
						zIndex: 13
					}}
				>
					{/* {(unreadsPresent || (currentNotification && !currentNotification?.saved_for_later)) && ( */}
					<>
						{unreadsPresent ? (
							<button
								onClick={() => {
									saveForLater(currentNotification?._id);
								}}
								className={styles.tdpHeaderBtn}
								title='Save For Later'
								style={{ zIndex: 100 }}
							>
								<FaClock size={16} />
							</button>
						) : (
							' '
						)}
					</>
					{/* // )} */}
					{/* {(unreadsPresent || currentNotification) && ( */}
					{unreadsPresent ? (
						<button
							title='Close as Unread'
							className={styles.tdpHeaderBtn}
							onClick={handleCloseAsUnread}
							style={{ color: 'white', width: '50px', zIndex: 1000 }}
						>
							<MdMarkAsUnread size={18} />
						</button>
					) : (
						' '
					)}

					{/* )} */}
					<button
						title='Close'
						className={styles.tdpHeaderBtn}
						onClick={handleClose}
						style={{ zIndex: 100, background: '#30343C' }}
					>
						{/* <div className={`fa_cross_icon`}>
							<FaTimes size={10} />
						</div> */}
						<img src={closeAction} alt='close' />
					</button>
				</div>
				<Box className={`modal_body pt-0`} onScroll={ev => debounce(scrollhandler)(ev)}>
					<TaskDetails
						activeWorksheet={activeWorksheet}
						selectedTask={selectedTask}
						item_id={item_id}
						openNextTaskOnNotficationPage={openNextTaskOnNotficationPage}
						comment_id={comment_id}
						updateTaskOnChoppingInterface={updateTaskOnChoppingInterface}
						shouldScrollToComment={shouldScrollToComment}
						parentTitle={parentTitle}
						showDescBox={showDescBox}
						hyperlinkedComment={hyperlinkedComment}
						changeEstimate={changeEstimate}
						updatePrefetchedTask={updatePrefetchedTask}
						openedTask={openedTask}
					/>
				</Box>
			</Box>
		</Modal>
	);
};
