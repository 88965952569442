import React from 'react';
import './NotificationAction.css'
import saveLaterAction from '../../assets/icons/saveLaterAction.svg'

const SaveLaterButton = ({onClick}) => {
    return (
        <button onClick={onClick} className='notification-action-button'>
            <img src={saveLaterAction} />
        </button>
    )
}

export default SaveLaterButton