import React from 'react'
import CountContainer from './CountContainer'
import './NotificationComponentsStyles.css'

const TabSwitch = ({tabname,isActive ,count,changeFilterTab}) => {
  return (
    <div onClick={changeFilterTab} className={`tab-switch ${isActive && 'active-tab'}`}>
        <CountContainer count={count}/>
        <span>{tabname}</span>
    </div>
  )
}

export default TabSwitch