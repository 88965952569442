import React, { useState } from 'react'
import './CommonHeader.css'
const CommonHeader = ({
    activeScreen,
    marginBottom,
    position = 'sticky',
    //props for notification
    title,
    onTabChange = () => { },
    tabActive,
    markAllNotifications,

    //for worksheet
    children
}) => {
    const [active, setActive] = useState(false);
    const screens = {
        notification: 'notification',
        worksheet: 'worksheet',
    }

    return (
        <div  style={{ marginBottom: marginBottom, position: position }} className={`dashboard-header`}>
            {
                activeScreen === screens.notification && <>
                    <div className='h-title'>
                        <h1>{title}</h1>
                    </div>
                    <div className='header-tabs'>
                        <div className='header-left-contents'>
                            <span onClick={() => onTabChange('unread_notifications')} className={`${tabActive === 'unread_notifications' && 'tab-active'}`}>
                                Unread
                            </span>
                            <span onClick={() => onTabChange('all_notifications')} className={`${tabActive === 'all_notifications' && 'tab-active'}`}>
                                All
                            </span>
                            <span onClick={() => onTabChange('unread_mentions')} className={`${tabActive === 'unread_mentions' && 'tab-active'}`}>
                                Unread Mentions
                            </span>
                        </div>
                        <div className='header-right-content'>
                            <span onClick={() => markAllNotifications()} style={{ color: '#fff', opacity: '1' }}>
                                Mark All
                            </span>
                        </div>

                    </div>

                </>
            }
            {
                activeScreen === screens.worksheet && <>
                    {children}
                </>
            }


        </div>
    )
}

export default CommonHeader