import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../screens/TeamWork/TeamWork.css';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PerformanceCharts from './PerformanceCharts';

const token = localStorage.getItem('red_wing_token');

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: 'absolute',
		width: '100%',
		height: '100vh',
		background: 'rgba(0,0,0,0.5)'
	},
	hide_heading: {
		display: 'none'
	}
}));

function PerformanceUser() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [ref, setRef] = useState(null);
	const [active, setActive] = useState('Monthly');
	const [weeklyData, setWeeklyData] = useState(null);
	const [monthlyData, setMonthlyData] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);
	const [currentId, setCurrentId] = useState('');

	const classes = useStyles();
	const [loading, setLoading] = useState(true);

	const [users, setUsers] = useState(null);
	const [weeklyIndex, setWeeklyIndex] = useState(null);
	const [monthlyIndex, setMonthlyIndex] = useState(null);
	//const [userId, setUserId] = useState('');

	const [weeklyChartData, setWeeklyChartData] = useState(null);
	const [weeklyDates, setWeeklyDates] = useState(null);

	const [monthlyChartData, setMonthlyChartData] = useState(null);
	const [monthlyDates, setMonthlyDates] = useState(null);

	const [userData, setUserData] = useState(null);

	const id = searchParams.get('userId');

	useEffect(() => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/past_performance.php?userid=${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*'
				}
			})
			.then(res => {
				console.log({ res });
				setWeeklyData(res.data.weeks_data);
				setMonthlyData(res.data.months_data);
				setUsers(res.data.users);

				let userData = res.data.users.filter(item => item.user_id === Number(id))[0];
				//console.log({ userData });
				setUserData(userData);

				let weeklyArr = [];
				let weeklyDate = [];
				res.data.weeks_data.map(item => weeklyArr.push(item.weekly_completed_budget));
				res.data.weeks_data.map(item =>
					weeklyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				setWeeklyChartData({ weeklyArr, weeklyDate });

				setWeeklyDates(weeklyDate);

				let monthlyArr = [];
				let monthlyDate = [];
				res.data.months_data.map(item => monthlyArr.push(item.monthly_completed_budget));
				res.data.months_data.map(item =>
					monthlyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				setMonthlyChartData({ monthlyArr, monthlyDate });
				setMonthlyDates(monthlyDate);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}, [id]);
	// 	if (value > 70) {
	// 		return '#5FB924';
	// 	} else if (value < 50) {
	// 		return '#FF0000';
	// 	} else if (value >= 50 && value <= 70) {
	// 		return '#FCBA03';
	// 	}
	// };

	// const weeklyOptions = {
	// 	chart: {
	// 		id: 'basic-bar',
	// 		toolbar: {
	// 			show: false,
	// 			tools: {
	// 				download: false // <== line to add
	// 			}
	// 		}
	// 	},
	// 	grid: {
	// 		xaxis: {
	// 			lines: {
	// 				show: false
	// 			}
	// 		},
	// 		yaxis: {
	// 			lines: {
	// 				show: false
	// 			}
	// 		}
	// 	},
	// 	annotations: {
	// 		yaxis: [
	// 			{
	// 				y: 40,
	// 				borderColor: '#FFFFFF'
	// 			}
	// 		]
	// 	},

	// 	xaxis: {
	// 		categories: weeklyDates,
	// 		labels: {
	// 			style: {
	// 				colors: 'white',
	// 				fontSize: '12px'
	// 			}
	// 		}
	// 	},
	// 	yaxis: {
	// 		min: 0,
	// 		max: 40,
	// 		tickAmount: 2,
	// 		labels: {
	// 			style: {
	// 				colors: 'white',
	// 				fontSize: '12px'
	// 			}
	// 		}
	// 	}
	// };
	// const weeklySeries = [
	// 	{
	// 		name: 'series-1',
	// 		data: weeklyChartData
	// 	}
	// ];

	// const monthlyOptions = {
	// 	chart: {
	// 		id: 'basic-bar',
	// 		toolbar: {
	// 			show: false,
	// 			tools: {
	// 				download: false // <== line to add
	// 			}
	// 		}
	// 	},
	// 	grid: {
	// 		xaxis: {
	// 			lines: {
	// 				show: false
	// 			}
	// 		},
	// 		yaxis: {
	// 			lines: {
	// 				show: false
	// 			}
	// 		}
	// 	},
	// 	annotations: {
	// 		yaxis: [
	// 			{
	// 				y: 160,
	// 				borderColor: '#FFFFFF'
	// 			}
	// 		]
	// 	},

	// 	xaxis: {
	// 		categories: monthlyDates,
	// 		labels: {
	// 			style: {
	// 				colors: 'white',
	// 				fontSize: '12px'
	// 			}
	// 		}
	// 	},
	// 	yaxis: {
	// 		min: 0,
	// 		max: 160,
	// 		tickAmount: 2,
	// 		labels: {
	// 			style: {
	// 				colors: 'white',
	// 				fontSize: '12px'
	// 			}
	// 		}
	// 	}
	// };
	// const monthlySeries = [
	// 	{
	// 		name: 'series-1',
	// 		data: monthlyChartData
	// 	}
	// ];

	console.log({ monthlyChartData }, { weeklyChartData });

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<div
				//className={styles.responsive1}
				style={{ display: 'flex', gap: '2vw', width: '100%', color: 'white', padding: '3vh 2vw' }}
			>
				<div style={{ width: '100%' }}>
					{!loading && (
						<PerformanceCharts
							weeklyData={weeklyData}
							monthlyData={monthlyData}
							weeklyDates={weeklyDates}
							monthlyDates={monthlyDates}
							weeklyChartData={weeklyChartData}
							monthlyChartData={monthlyChartData}
							userData={userData}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default PerformanceUser;
