import axios from 'axios';

const BASE_URL = process.env.REACT_APP_LARAVEL_API_URL;

const ACCESS_TOKEN = localStorage.getItem('access_token');

const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: `Bearer ${ACCESS_TOKEN}`,
		'Access-Control-Allow-Origin': '*'
	}
});

export default api;

