import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import * as axiosBase from 'axios';
import moment from 'moment/moment';
import { Backdrop, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

const useStyles = makeStyles({
    TextField: {
        fontSize: '15px !important',
        background: 'white !important',
        width: '280px !important'

    },
    h5:
    {
        color: 'white'
    },
    backdrop: {
		zIndex: 100000,
		color: '#fff',
		position: 'absolute',
		width: 'fit-content',
		height: 'fit-content',
		background: 'rgba(0,0,0,0.5)'
	},
});


// "user_id": 33630774,
//     "start_date": "2022-09-27",
//     "end_date": "2022-09-30",
//     "reason": "adfadsfasdf asdffasdf"

export default function LeaveForm() {
    const [value, setValue] = useState(dayjs('2022-08-18T21:11:54'));
    const [Data, setData] = useState('');
    const [user_id, setuser_id] = useState('');
    const [start_date, setstart_date] = useState('');
    const [end_date, setend_date] = useState('');
    const [reason, setreason] = useState('');
    const [reasonState, setreasonState] = useState('');
    const [reasonText, setreasonText] = useState('');
    const [ReasonTextFlag, setReasonTextFlag] = useState(false);
    const [success, setSuccess] = useState('');
    const [errors, setErrors] = useState([]);
    const [leaveType, setLeaveType] = useState('full_day');
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const ReasonHandler=(e)=>{
        if(e.target.value==='Other')
        {
            setReasonTextFlag(true);
            setreasonState(e.target.value);
        }
        else
        {
            setReasonTextFlag(false);
            setreasonText('');
            setreasonState(e.target.value);
        }
    }
    useEffect(()=>{
        setreason(reasonState + (reasonText?` - ${reasonText}`:'') );
        setSuccess('');
    },[reasonText, reasonState ])


    const SubmitHandler = (e) => {
        setLoading(true);
        setErrors([]);
        e.preventDefault();
        const StartDateNEW = moment(start_date).format('YYYY-MM-DD');
        const EndDateNEW = moment(end_date).format('YYYY-MM-DD');
        const isHalfDay = leaveType === 'half_day';
        axios
            .post(
                `${process.env.REACT_APP_LARAVEL_API_URL}/leave`,
                { start_date: StartDateNEW , end_date: EndDateNEW , reason: reason, half_day: isHalfDay },
            )
            .then(res => {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    // setreason('');
                }else{
                    setErrors(res.data.errors);
                }
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 422) {
					console.log(error.response.data);
					setErrors(error.response.data.errors);
				}
                setLoading(false);
            });
    };


    return (

        <form onSubmit={SubmitHandler}>
            <div style={{ padding: '109px' }}>
          
                <div className=" sm:border-t sm:border-gray-200 sm:pt-3 pb-3 p-28" style={{
                    display: 'flex', alignItems: 'center',
                    padding: '53px', background: '#00000000', justifyContent: 'center', flexFlow: 'column',
                }}>

                   


                    {/* //Headinf */}
                    <h2 style={{ marginBottom: '30px', color: 'white' }}>Application For Leave</h2>
                    {/* //Users */}
                    {success && (
                        <div style={{ textAlign: 'center', color: 'green', margin: '1vh 0' }}>{success}</div>
                    )}

                    {/* //Start Date/ */}
                    <div style={{  margin: '15px 0',  width: '41%',  }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',

                        }}>
                            <h5 style={{ color: 'white' }} className="text-lg leading-6 font-medium text-gray-900">Start Date</h5>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                        value={start_date}
                                        onChange={(e) => setstart_date(e.$d)}
                                        renderInput={(params) => <TextField {...params}
                                            className={classes.TextField} size='small' sx={{
                                                width: 171,
                                                fontSize: 16,
                                            }} />}

                                    />
                                </LocalizationProvider>

                            </div>


                        </div>
                        <div>
                            { errors.start_date && <div style={{ color:'red', textAlign: 'center' }}> { errors.start_date.toString() }</div>}
                        </div>
                    </div>

                    {/* //End Date/ */}
                    <div style={{  margin: '15px 0',  width: '41%' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        alignItems: 'center',

                        }}>
                            <h5 style={{ color: 'white' }} className="text-lg leading-6 font-medium text-gray-900">End Date</h5>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    value={end_date}
                                    onChange={(e) => setend_date(e.$d)}
                                    renderInput={(params) => <TextField {...params} className={classes.TextField} size='small' sx={{
                                        width: 171,
                                        fontSize: 16,
                                    }} />}

                                />
                            </LocalizationProvider>


                        </div>
                        <div>
                            { errors.end_date && <div style={{ color:'red', textAlign: 'center' }}> { errors.end_date.toString() }</div>}
                        </div>
                    </div>

                    <div style={{  margin: '15px 0',  width: '41%' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        alignItems: 'center',

                        }}>
                            <h5 style={{ color: 'white' }} className="text-lg leading-6 font-medium text-gray-900"> Leave type</h5>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel label={<p style={{ color: 'white' }}>Half Day</p>} control={<Radio style={{ color: 'white' }}/>} value="half_day" checked={leaveType === 'half_day' ? true : ''} onChange={(e) => {setLeaveType('half_day')}} />
                                    <FormControlLabel label={<p style={{ color: 'white' }}>Full Day</p>} control={<Radio style={{ color: 'white' }}/>} value="full_day" checked={leaveType === 'full_day' ? true : ''} onChange={(e) => {setLeaveType('full_day')}} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            { errors.end_date && <div style={{ color:'red', textAlign: 'center' }}> { errors.end_date.toString() }</div>}
                        </div>
                    </div>

                    {/* //Reason/ */}
                    <div style={{  margin: '15px 0', width: '41%', }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        alignItems: 'center',

                        }}>
                            <h5 style={{ color: 'white' }} className="text-lg leading-6 font-medium text-gray-900">Reason</h5>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <TextField select size='small' label='Select Reason' value={reasonState} onChange={ReasonHandler} className={classes.TextField} sx={{
                                    width: 171,
                                    color: 'white'
                                }}>
                                    {/* {Data.length > 0 && Data.map((item) => { */}

                                        {/* return ( */}
                                        <MenuItem value='Health Issues'>Health Issues</MenuItem>
                                        <MenuItem value='Family Issues'>Family Issues</MenuItem>
                                        <MenuItem value='College commitments'>College commitments</MenuItem>
                                        <MenuItem value='Personal emergency'>Personal emergency</MenuItem>
                                        <MenuItem value='Other'>Other</MenuItem>
                                    {/* //     ); */}
                                    {/* // })} */}

                                </TextField>
                                {ReasonTextFlag && 
                            <div className='mt-3' >
                                {/* <textarea
                                id="about"
                                name="about"
                                type='text'
                                rows={7} class="form-control" aria-label="With textarea"
                                style={{    width: '171px'}}
                            /> */}
                                <TextField multiline={true} minRows={3}
                                    value={reasonText}
                                    onChange={(e) => setreasonText(e.target.value)}
                                    className={classes.TextField} sx={{ width: 171, }} />
                            </div>
                            }
                            </div>
                            
                        </div>
                    </div>
                    <div>
                        { errors.reason && <div style={{ color:'red', textAlign: 'center' }}> { errors.reason.toString() }</div>}
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '41%',
                        margin: '15px 0', alignItems: 'center',

                    }}>
                        <button style={{ border: 'none', padding: '1vh 1vw', cursor: 'pointer', marginTop: '1vh' }} >Submit</button>
                    </div>

                </div>

            </div>
            <Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color='inherit' />
            </Backdrop>
        </form>
    )
}
