import { createSlice } from '@reduxjs/toolkit';
import * as axiosBase from 'axios';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});


const dataSlice = createSlice({
  name: 'data',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    fetchPresetMessagesRequest: state => {
      state.loading = true;
    },
    fetchPresetMessagesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchPresetMessagesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchPresetMessagesRequest, fetchPresetMessagesSuccess, fetchPresetMessagesFailure } = dataSlice.actions;

export const fetchPresetMessages = () => async dispatch => {
    dispatch(fetchPresetMessagesRequest());
    const url =
    `${process.env.REACT_APP_LARAVEL_API_URL}/comment/escalation-messages`;
    axios
    .get(url)
    .then(data => {
        if(data.data.success){
            dispatch(fetchPresetMessagesSuccess(data.data.messages));
        }else{
            console.log(data.data.message);
            dispatch(fetchPresetMessagesFailure(data.data.message));
        }
    })
    .catch(e => console.log(e));
};

export default dataSlice.reducer;