import React from 'react'
import CountContainer from './CountContainer'

const Actioncontainer = ({ label, icon, count }) => {
    return (
        <div className='action-container'>
            <div className='action-label'>
                {icon}
                <span>{label}</span>
            </div>
            <CountContainer count={count} />
        </div>
    )
}

export default Actioncontainer