import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Linkify from 'react-linkify';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import './publicComment.css';

const parseOptions = {
    replace: node => {
        if (node.type === 'tag' && node.name === 'img') {
            let parent = node.parent;
            while (parent && parent.type !== 'tag') {
                parent = parent.parent;
            }
            if (
                parent &&
                parent.type === 'tag' &&
                parent.name === 'a' &&
                parent.attribs &&
                parent.attribs.href
            ) {
                node.attribs.src = parent.attribs.href;
                node.attribs.style = 'width: 100%; height: auto; object-fit: contain; max-width: 100%;';
            }
        }
        if (
            node.type === 'tag' &&
            node.attribs &&
            node.attribs.class &&
            node.attribs.class.includes('attachment--content')
        ) {
            node.attribs.style = 'display: inline-block; vertical-align: middle;';
        }
        return node;
    }
};

export const PublicComment = () => {
    let { pathname } = useLocation();
    const [comment, setComment] = useState('');

    const parseText = text => {
        let temp_text = text.replace(
            /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>/gi,
            `<a target="_blank" rel="noopener noreferrer"  href="$2" style="color: white;text-decoration:underline">`
        );

        return (
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a href={decoratedHref} key={key} target='_blank' style={{ color: 'white', textDecoration: "underline" }}>
                        {decoratedText}
                    </a>
                )}
                style={{ color: 'white', textDecoration: "underline" }}
            >
                {parse(temp_text, parseOptions)}
            </Linkify>
        );
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_LARAVEL_API_URL}/comment/${pathname?.split('/')[2]}/public`, {
                headers: {
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => {
                setComment(res.data.data.content);
            })
    }, []);

    return (
        <div className={`body_container worksheet_body`}>
            <div className='comment mx-auto my-5 p-3'>
                {parseText(comment)}
            </div>
        </div>
    )
}