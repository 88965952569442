import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import TeamWork from 'screens/TeamWork/TeamWork';
import styles from '../BigDashboard/BigDashboard.module.css';
import { TopStatistics } from '../BigDashboard/TopStatistics';
import SwitchMode from '../BigDashboard/SwitchMode';
import ProjectsColumn from '../BigDashboard/ProjectsColumn';
import ActivitiesColumn from '../BigDashboard/ActivitiesColumn';
import moment from 'moment';
import * as axiosBase from 'axios';
import { Helmet } from 'react-helmet';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { unstable_createMuiStrictModeTheme } from '@material-ui/core';
import Segments from 'screens/Segments/Segments';
import { Tooltip } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { MdFormatListNumbered } from 'react-icons/md';
import { categoriesForOtherWorks } from '../../initial-data';
import { HandleSave } from 'db/Connect';
import { Navigation } from 'swiper';
import { useRef } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper';
import NewTeamWorkChopping from './NewTeamWorkChopping';

const token = localStorage.getItem('red_wing_token');

const token_expiry_date = localStorage.getItem('red_wing_token_expiry_date');
const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

function ChoppingDashboard({
	globalState,
	setGlobalState,
	selectedProject,
	setSelectedProject,
	timerr,
	showSegments,
	teamWorkFullView
}) {
	const navigate = useNavigate();
	const { workList, otherWorks } = globalState;
	const activities = categoriesForOtherWorks.map(cw => {
		let cnt = 0;
		workList.map(({ id, isSelected }) => {
			const work = otherWorks[id];
			console.log(work);
			if (work.category.text === cw.text && isSelected) {
				cnt++;
			}
		});
		return { category: cw, tasks: cnt };
	});

	useEffect(() => {
		getTeamWorkData();
		setInterval(async () => getTeamWorkData(), 60000);
	}, []);

	const redwingProjectId = 23190856;

	const [totalTickets, setTotalTickets] = useState(0);
	const [completedTask, setCompletedTask] = useState(0);
	const [ProjectTabValue, setProjectTabValue] = useState('');
	const [feedbackCount, setFeedbackCount] = useState(null);

	const [AllTasks, setAllTasks] = useState({});
	const [isFullScreen, setIsFullScreen] = useState('teamWork');
	const [gridValues, setGridValues] = useState('0fr 1fr 0fr 0fr');

	const localStorageData = localStorage.getItem('redwing_data_chopping');
	const [allusers, setAllUsers] = useState(
		localStorage.getItem('redwing_data_chopping') ? JSON.parse(localStorageData) : {}
	);

	const [data, setData] = useState(
		localStorage.getItem('redwing_data_chopping') ? JSON.parse(localStorageData) : {}
	);

	const [projectData, setProjectData] = useState(
		localStorage.getItem('redwing_data_chopping') ? JSON.parse(localStorageData).projects : []
	);

	const scrollTop = () => {
		window.scrollTo({ top: 0, behaviour: 'smooth' });
	};

	const activeProjects = projectData
		? projectData.filter(project => project.open_task_count !== 0)
		: [];
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (allusers.users) {
			const teamMembers = allusers.users; //.filter(user => user.user_id !== 33629907)
			const totalTasks = teamMembers.reduce((acc, user) => {
				return acc + user.tasks_count;
			}, 0);
			if (totalTasks !== totalTickets) {
				setTotalTickets(totalTasks);
				setTopStatisticsCount(prev => {
					return {
						...prev,
						teamLoad: totalTasks
					};
				});
			}
		}
	}, [allusers]);

	useEffect(() => {
		const tasksDataJson = localStorage.getItem('data');
		const tasks = JSON.parse(tasksDataJson)?.tasks;
		setAllTasks(globalState.tasks);
	});

	useEffect(() => {
		if (allusers.users) {
			const teamMembers = allusers.users.filter(
				user =>
					// user.user_id !== 33629907 &&
					(!user.project_ids.includes(redwingProjectId) || user.project_ids.length > 1)
			);
			const totalCompleteTask = teamMembers.reduce((acc, user) => {
				return acc + user.completed_todo;
			}, 0);
			if (totalCompleteTask !== completedTask) {
				setTopStatisticsCount(prev => {
					return {
						...prev,
						taskCompleted: completedTask
					};
				});
			}
		}
	}, [allusers]);

	useEffect(() => {
		let completions = 0;
		if (data.users) {
			data.users.map(user => {
				completions += user.completed_budget;
			});
			setCompletedTask(completions);
		}
		setCompletedTask(completions);
	}, [data]);

	const getTeamWorkData = () => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/team_work_chopping`, {
				// headers: {
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				localStorage.setItem('redwing_data_chopping', JSON.stringify(res.data));
				setData(res.data);
				setAllUsers(res.data);
				setProjectData(res.data.projects);
				localStorage.setItem('redwing_data_chopping', JSON.stringify(res.data));
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		setTopStatisticsCount(() => {
			return {
				...topStatisticsCount,
				tasksToday: data.tickets_created_today
			};
		});
	}, [data]);

	const checkVisibilityColumnOne = () => {
		const yes = activities.map(item => item.tasks === 0);
		const final = yes.every(value => value === true);
		const result = !final;

		const keys = Object.keys(AllTasks).map(each => AllTasks[each].activityList);
		const activityArray = keys.map(project =>
			project.every(element => element.isSelected === false)
		);
		const visiblity = activityArray.every(value => value === true);
		const result2 = !visiblity;

		if (result || result2) {
			return true;
		} else {
			return false;
		}
	};

	const checkVisiblitySegments = () => {
		if (showSegments) {
			return true;
		} else {
			return false;
		}
	};

	const [isColumn, setIsColumn] = React.useState('');
	const defaultGridValues = checkVisibilityColumnOne()
		? '0fr 1fr 0fr 0fr' //0.8fr 2.5fr 0fr 0fr
		: // : checkVisiblitySegments() && !checkVisibilityColumnOne()
		  // ? '0fr 2.5fr 0.5fr 0fr'
		  // : checkVisiblitySegments() && checkVisibilityColumnOne()
		  // ? '0.8fr 2.5fr 0.5fr 0fr'
		  '0fr 1fr 0fr 0fr';

	useEffect(() => {
		if (checkVisibilityColumnOne()) {
			setGridValues('0fr 1fr 0fr 0fr'); //0.8fr 2.5fr 0fr 0fr
			setIsColumn('');
		} else {
			setIsColumn('available');
			setGridValues(gridValues);
		}
	}, [AllTasks]);

	useEffect(() => {
		// if (teamWorkFullView && showSegments) {
		// 	setGridValues('0fr 2.5fr 0.5fr 0fr');
		// 	setIsColumn('');
		// } else if (!teamWorkFullView && showSegments) {
		// 	if (checkVisibilityColumnOne()) {
		// 		setGridValues('0.8fr 2.5fr 0.5fr 0fr');
		// 		setIsColumn('');
		// 	} else {
		// 		setGridValues('0fr 2.5fr 0.5fr 0fr');
		// 		setIsColumn('');
		// 	}
		// } else if (teamWorkFullView && !showSegments) {
		// 	setGridValues('0fr 1fr 0fr 0fr');
		// 	setIsColumn('');
		// } else if (gridValues === '0.8fr 2.5fr 0fr 0fr' && showSegments) {
		// 	setGridValues('0.8fr 2.5fr 0.5fr 0fr');
		// 	setIsColumn('');
		// } else if (gridValues === '0.8fr 2.5fr 0fr 0fr' && !showSegments) {
		// 	setGridValues('0.8fr 2.5fr 0fr 0fr');
		// 	setIsColumn('');
		// } else {
		// 	setIsFullScreen('');
		// 	setGridValues(defaultGridValues);
		// }
	}, [showSegments, teamWorkFullView]);

	useEffect(() => {
		if (teamWorkFullView) {
			setGridValues('0fr 1fr 0fr 0fr');
			setIsColumn('');
		} else {
			setIsFullScreen('');
			setGridValues(defaultGridValues);
		}
	}, [teamWorkFullView]);

	const [topStatisticsCount, setTopStatisticsCount] = useState({
		hoursOfWeek: 0,
		completion: 0,
		worthOrders: '$0',
		tasksToday: data.tickets_created_today,
		teamLoad: totalTickets,
		taskCompleted: completedTask
	});
	//useEffect(() => {}, [ProjectTabValue]);

	const toggleFullScreen = value => {
		if (isFullScreen === value) {
			setIsFullScreen('');
			setGridValues(defaultGridValues);
		} else {
			setIsFullScreen(value);
			switch (value) {
				case 'activity':
					setGridValues('1fr 0fr 0fr 0fr');
					break;
				case 'project':
					setGridValues('0fr 0fr 0fr 1fr');
					break;
				case 'teamWork':
					setGridValues('0fr 1fr 0fr 0fr');
					break;
				case 'subTab':
					setGridValues('0fr 0fr 1fr 0fr');
					break;
				default:
					setGridValues(defaultGridValues);
					break;
			}
		}
	};

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/performance_feedbacks.php`, {
				headers: {
					Authorization: `${token}`,
					'Access-Control-Allow-Origin': '*'
				}
			})
			.then(res => {
				if (res.data.status) {
					setFeedbackCount(res.data.feedbacks.length);
				}
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	//current time
	//const [currentTime, setCurrentTime] = useState(topStatisticsCount?.completion);
	// useEffect(() => {
	// 	if (timerr.day) {
	// 		console.log('updating time 1st time....');
	// 		setCurrentTime(
	// 			moment()
	// 				.add(timerr.day * 60, 'minutes')
	// 				.format('hh:mm')
	// 		);
	// 	}
	// }, [timerr.day]);

	// useEffect(() => {
	// 	setInterval(() => {
	// 		setTopStatisticsCount(prev => {
	// 			return {
	// 				...prev,
	// 				hoursOfWeek: timerr.day,
	// 				completion: moment().add(timerr.day, 'hours').format('hh:mm')
	// 			};
	// 		});
	// 	}, 60000);
	// }, []);

	useEffect(() => {
		setInterval(() => {
			setTopStatisticsCount(prev => {
				return {
					...prev,
					//hoursOfWeek: timerr.day,
					completion: moment().add(prev.hoursOfWeek, 'hours').format('hh:mm')
				};
			});
		}, 60000);
	}, [timerr]);

	//hoursOfWeek
	useEffect(() => {
		if (timerr.day) {
			setTopStatisticsCount(prev => {
				return {
					...prev,
					hoursOfWeek: timerr.day,
					completion: moment().add(timerr.day, 'hours').format('hh:mm')
				};
			});
		}
	}, [timerr]);

	const clearEstimates = () => {
		if (window.confirm('Do you want to clear estimates?')) {
			let newWorkList = globalState.workList;
			newWorkList = newWorkList.map(item => {
				return {
					...item,
					isSelected: false
				};
			});
			//setGlobalState({ ...globalState, workList: newWorkList });

			let newTasks = globalState.tasks;
			Object.keys(newTasks).map(project => {
				newTasks[project].isCompleted = true;
				newTasks[project].activityList = newTasks[project].activityList.map(x => {
					return {
						...x,
						isSelected: false
					};
				});
			});

			setGlobalState(old => {
				old.tasks = newTasks;
				old.workList = newWorkList;

				HandleSave(old);

				return { ...old };
			});
			//setGlobalState({ ...globalState, tasks: newTasks });
			setGridValues('0fr 1fr 0fr 0fr');
		}
	};

	const [swiper, setSwiper] = useState(null);

	return (
		<div className={styles.global}>
			<Helmet>
				<meta name='apple-mobile-web-app-capable' content='yes' />
			</Helmet>
			<div
				className={styles.bigdashboard}
				style={{
					gridTemplateColumns: gridValues
					// animation: 'resize 2s ease',
					// willChange:'gridTemplateColumns'
					//transition: 'gridTemplateColumns 1s linear'
				}}
				id={styles.responsive1}
			>
				{/* {isColumn === 'available' ? ( */}

				{checkVisibilityColumnOne && (!isFullScreen || isFullScreen === 'activity') && (
					<div className={styles.activity}>
						<div className={styles.outertopStatisticsBar}>
							<div className={styles.topStatisticsBar}>
								<TopStatistics text={'Hours of work'} count={topStatisticsCount.hoursOfWeek} />
								<TopStatistics text={'Completion'} count={topStatisticsCount.completion} />
							</div>

							<div style={{ display: 'flex', alignItems: 'center' }}>
								{/* <MdFormatListNumbered
									size={20}
									onClick={() =>
										navigate({
											pathname: '/rapid-estimation',
											search: `${createSearchParams({ tab: 'Projects' })}`
										})
									}
									style={{ cursor: 'pointer', color: 'white', marginRight: '1rem' }}
								/> */}
								{/* <SwitchMode
									toggleFullScreen={toggleFullScreen}
									sectionName='activity'
									isFullScreen={isFullScreen}
								/> */}
							</div>
						</div>
						<div className={styles.alignActivitiesContent}>
							<ActivitiesColumn
								setTopStatisticsCount={setTopStatisticsCount}
								setSelectedProject={setSelectedProject}
								selectedProject={selectedProject}
							/>
						</div>
						<div
							style={{
								cursor: 'pointer',
								color: 'white',
								width: 'fit-content',
								fontSize: '12px',
								position: 'absolute',
								bottom: '0',
								left: '20px'
							}}
							onClick={() => { clearEstimates() }}
						>
							Clear Estimates
						</div>
					</div>
				)}

				{/* column 3 big dashboard */}
				{/* {(!isFullScreen || isFullScreen === 'project') && (
					<div className={styles.project}>
						<div className={styles.outertopStatisticsBar}>
							<div className={styles.topStatisticsBar}>
								<TopStatistics text={'Worth Orders'} count={topStatisticsCount.worthOrders} />
							</div>
							<SwitchMode
								toggleFullScreen={toggleFullScreen}
								sectionName='project'
								isFullScreen={isFullScreen}
							/>
						</div>
						<div className={styles.alignProjectsContent}>
							<ProjectsColumn setTopStatisticsCount={setTopStatisticsCount} />
						</div>
					</div>
				)} */}

				{/* column 1 big dashboard*/}
				{(!isFullScreen || isFullScreen === 'teamWork') && (
					<div className={styles.teamWork}>
						<div className={styles.outertopStatisticsBar}>
							<div className={styles.topStatisticsBar}>
								<div>
									<div
										style={{
											background: '#EF4444',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Hours of Work
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<TopStatistics
											text={'Total'}
											count={data.team_load_hours}
											// chip={data.team_load_hours}
										/>
										<TopStatistics
											text={'Today'}
											count={data.tickets_created_today_budget}
											// chip={data.tickets_created_today_budget}
										/>
										<TopStatistics
											text={'Completed'}
											count={data.completed_hours}
											link={`/completion`}
											// chip={data.completed_hours}
										/>
									</div>
								</div>
								<div>
									<div
										style={{
											background: '#FCBA03',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Stacks
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<TopStatistics
											text={'Sleeping'}
											count={data.sleeping_tasks}
											link={'https://redwing.happygoals.app/pages/sleeping_task.php'}
										/>
										<TopStatistics
											text={'On Hold'}
											count={data.on_hold_tasks}
											link={'https://redwing.happygoals.app/pages/onhold_task.php'}
										/>
										<TopStatistics
											text={'Unestimated'}
											count={data.unplanned_tasks}
											link={'/tasks/unestimated'}
										/>
										<TopStatistics
											text={'Unassigned'}
											count={data.unassigned_tasks}
											link={'/tasks/unassigned'}
										/>
									</div>
								</div>
								<div>
									<div
										style={{
											background: '#5FB924',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Output
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3vw',
													marginBottom: '2px'
												}}
											>
												<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
													<CircularProgressbar
														value={data.completed_hours_percentage}
														strokeWidth={22}
														counterClockwise
														styles={buildStyles({
															pathColor: 'red',
															textColor: 'red',
															trailColor: '#414141',
															strokeLinecap: 'round'
														})}
													/>
												</div>
												<div style={{ fontSize: 25, color: 'white' }}>
													{data.completed_hours_percentage?.toFixed()}%
												</div>
											</div>
											<div style={{ fontSize: 12, color: 'white' }}>Today</div>
										</div>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3vw',
													marginBottom: '2px'
												}}
											>
												<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
													<CircularProgressbar
														value={data.weekly_completed_hours_percentage}
														strokeWidth={22}
														counterClockwise
														styles={buildStyles({
															pathColor: 'red',
															textColor: 'red',
															trailColor: '#414141',
															strokeLinecap: 'round'
														})}
													/>
												</div>
												<div style={{ fontSize: 25, color: 'white' }}>
													{data.weekly_completed_hours_percentage?.toFixed()}%
												</div>
											</div>
											<div style={{ fontSize: 12, color: 'white' }}>Weekly</div>
										</div>
									</div>
								</div>
							</div>

							<div style={{ display: 'flex', alignItems: 'center' }}>
								{/* <MdFormatListNumbered
									size={20}
									onClick={() =>
										navigate({
											pathname: '/rapid-estimation',
											search: `${createSearchParams({ tab: 'Projects' })}`
										})
									}
									style={{ cursor: 'pointer', color: 'white', marginRight: '1rem' }}
								/> */}

								{/* <SwitchMode
									toggleFullScreen={toggleFullScreen}
									sectionName='teamWork'
									isFullScreen={isFullScreen}
								/> */}
							</div>
						</div>
						<div className={styles.alignTeamContent}>
							<NewTeamWorkChopping
								isInverted={false}
								screenIndex={2}
								showTeamTabTop={false}
								showTabComponent={false}
								showActionButtons={false}
								data1={data}
								loading1={loading}
								setLoading1={setLoading}
							/>
						</div>
						<div
							style={{
								color: 'rgba(211, 211, 211, 0.7)',
								margin: '0 auto',
								marginBottom: '1vh',
								fontStyle: 'italic',
								width: 'fit-content',
								fontSize: '14px'
							}}
						>
							Updated {moment(data.updated_at).fromNow()}
						</div>
						{/* <div
							style={{
								color: 'lightgrey',
								fontSize: '1.5vh',
								margin: '1vh 1vw'
							}}
						>
							<Link to='/performance/feedback_report' style={{ color: 'white' }}>
								{' '}
								Performance feedback ({feedbackCount && feedbackCount})
							</Link>
						</div> */}
					</div>
				)}

				{/* column 2 big dashboard */}
				{/* {(!isFullScreen || isFullScreen === 'subTab') && (
					<div className={styles.projects_subtab}>
						<div className={styles.outertopStatisticsBar}>
							<div className={styles.topStatisticsBar}>
								<TopStatistics text={'Active Projects'} count={activeProjects.length} />
							</div>
							<SwitchMode
								toggleFullScreen={toggleFullScreen}
								sectionName='subTab'
								isFullScreen={isFullScreen}
							/>
						</div>
						<div className={styles.alignProjectsContent} style={{marginTop: 26}}>
							<table>
								<thead></thead>
								<tbody>
									<td className={styles.project_tab_heading}>Project Name</td>
									<td className={styles.project_tab_heading}>Total</td>
									<td className={styles.project_tab_heading}>Today</td>
									{projectData ? (
										projectData.map((project, key) => {
											let project_id = project.project_id
											if (project.open_task_count !== 0) {
												return (
													<tr>
														<td>
															<div>
																<div className={styles.project_tab_project_name}>{project.name}</div>
																<div>
																	{
																		allusers.users.map(user => {
																			let arr = []
																			let project_ids = user.project_ids
																			project_ids.forEach(id => {
																				if (id === project_id) {
																					arr.push(user)
																				}
																			})

																			return (
																				<>
																					{
																						arr.map(user => {
																							return (
																								<img src={user.avatar} width="25px" style={{ borderRadius: '100%', marginRight: '5px' }} />
																							)
																						})
																					}
																				</>
																			)

																		})
																	}
																</div>
															</div>
														</td>
														<td className={styles.project_tab_total}>{project.open_task_count}</td>
														<td className={styles.project_tab_today}>{project.todos_created_today_count}</td>
													</tr>
												);
											}
										})
									) : (
										<></>
									)}
								</tbody>
							</table>
						</div>
					</div>
				)} */}

				{(!isFullScreen || isFullScreen === 'subTab') && (
					<div className={styles.projects_subtab}>
						<Segments homeBreakpoint={1} data={data} />
					</div>
				)}

				{/* <Segments/> */}
			</div>

			<Swiper className={styles.bigdashboard} id={styles.responsive2} slidesPerView={1}>
				{/* {checkVisibilityColumnOne() && ( */}
					{/* <SwiperSlide>
						<div className={styles.activity}>
							<div className={styles.outertopStatisticsBar}>
								<div className={styles.topStatisticsBar}>
									<TopStatistics text={'Hours of work'} count={topStatisticsCount.hoursOfWeek} />
									<TopStatistics text={'Completion'} count={topStatisticsCount.completion} />
								</div>
							</div>
							<SwitchMode
								toggleFullScreen={toggleFullScreen}
								sectionName='teamWork'
								isFullScreen={isFullScreen}
							/>
							<div className={styles.alignActivitiesContent}>
								<ActivitiesColumn
									setTopStatisticsCount={setTopStatisticsCount}
									setSelectedProject={setSelectedProject}
									selectedProject={selectedProject}
								/>
							</div>
							<div
								style={{
									cursor: 'pointer',
									color: 'white',
									width: 'fit-content',
									fontSize: '12px',
									position: 'absolute',
									bottom: '0',
									left: '20px'
								}}
								onClick={() => {clearEstimates()}}
							>
								Clear Estimates
							</div>
						</div>
					</SwiperSlide> */}
				{/* )} */}

				<SwiperSlide>
					<div className={styles.teamWork}>
						<div className={styles.outertopStatisticsBar} id={styles.lastColumn}>
							<div className={styles.topStatisticsBar}>
								<IoIosArrowBack
									size={30}
									style={{ color: '#949494', flexShrink: '0' }}
									onClick={() => swiper.slidePrev()}
								/>
								<Swiper style={{ flexShrink: '1' }} onSwiper={s => setSwiper(s)}>
									<SwiperSlide>
										<div style={{ width: 'fit-content', margin: '0 auto' }}>
											<div
												style={{
													background: '#EF4444',
													color: 'white',
													fontSize: '12px',
													fontWeight: '500',
													width: 'fit-content',
													borderRadius: '4px',
													padding: '0 8px',
													marginBottom: '10px'
												}}
											>
												Work Load
											</div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													gap: '25px'
												}}
											>
												<TopStatistics
													text={'Total Tasks'}
													count={data.team_load_tasks}
													chip={data.team_load_hours}
												/>
												<TopStatistics
													text={'Tasks Today'}
													count={data.tickets_created_today}
													chip={data.tickets_created_today_budget}
												/>
												<TopStatistics
													text={'Completed'}
													count={data.completed_tasks}
													chip={data.completed_hours}
												/>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div style={{ width: 'fit-content', margin: '0 auto' }}>
											<div
												style={{
													background: '#FCBA03',
													color: 'white',
													fontSize: '12px',
													fontWeight: '500',
													width: 'fit-content',
													borderRadius: '4px',
													padding: '0 8px',
													marginBottom: '10px'
												}}
											>
												Stacks
											</div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													gap: '25px'
												}}
											>
												<TopStatistics
													text={'Sleeping'}
													count={data.sleeping_tasks}
													link={'https://redwing.happygoals.app/pages/sleeping_task.php'}
												/>
												<TopStatistics
													text={'On Hold'}
													count={data.on_hold_tasks}
													link={'https://redwing.happygoals.app/pages/onhold_task.php'}
												/>
												<TopStatistics
													text={'Unplanned'}
													count={data.unplanned_tasks}
													link={'https://redwing.happygoals.app/pages/unplanned_task.php'}
												/>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div style={{ width: 'fit-content', margin: '0 auto' }}>
											<div
												style={{
													background: '#5FB924',
													color: 'white',
													fontSize: '12px',
													fontWeight: '500',
													width: 'fit-content',
													borderRadius: '4px',
													padding: '0 8px',
													marginBottom: '10px'
												}}
											>
												Output
											</div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													gap: '25px'
												}}
											>
												<div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '0.3vw',
															marginBottom: '2px'
														}}
													>
														<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
															<CircularProgressbar
																value={data.completed_hours_percentage}
																strokeWidth={18}
																counterClockwise
																styles={buildStyles({
																	pathColor: 'red',
																	textColor: 'red',
																	trailColor: '#414141',
																	strokeLinecap: 'round'
																})}
															/>
														</div>
														<div style={{ fontSize: 25, color: 'white' }}>
															{data.completed_hours_percentage?.toFixed()}%
														</div>
													</div>
													<div style={{ fontSize: 12, color: 'white' }}>Today</div>
												</div>
												<div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '0.3vw',
															marginBottom: '2px'
														}}
													>
														<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
															<CircularProgressbar
																value={data.weekly_completed_hours_percentage}
																strokeWidth={18}
																counterClockwise
																styles={buildStyles({
																	pathColor: 'red',
																	textColor: 'red',
																	trailColor: '#414141',
																	strokeLinecap: 'round'
																})}
															/>
														</div>
														<div style={{ fontSize: 25, color: 'white' }}>
															{data.weekly_completed_hours_percentage?.toFixed()}%
														</div>
													</div>
													<div style={{ fontSize: 12, color: 'white' }}>Weekly</div>
												</div>
											</div>
										</div>
									</SwiperSlide>
								</Swiper>
								<IoIosArrowForward
									size={30}
									style={{ color: '#949494', flexShrink: '0' }}
									onClick={() => swiper.slideNext()}
								/>
							</div>
						</div>
						{/* <div className={styles.outertopStatisticsBar} id={styles.lastColumn}>
							<div className={styles.topStatisticsBar}>
								<div>
									<div
										style={{
											background: '#EF4444',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Work Load
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<TopStatistics
											text={'Total Tasks'}
											count={data.team_load_tasks}
											chip={data.team_load_hours}
										/>
										<TopStatistics
											text={'Tasks Today'}
											count={data.tickets_created_today}
											chip={data.tickets_created_today_budget}
										/>
										<TopStatistics
											text={'Completed'}
											count={data.completed_tasks}
											chip={data.completed_hours}
										/>
									</div>
								</div>
								<div>
									<div
										style={{
											background: '#FCBA03',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Stacks
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<TopStatistics
											text={'Sleeping'}
											count={data.sleeping_tasks}
											link={'https://redwing.happygoals.app/pages/sleeping_task.php'}
										/>
										<TopStatistics
											text={'On Hold'}
											count={data.on_hold_tasks}
											link={'https://redwing.happygoals.app/pages/onhold_task.php'}
										/>
										<TopStatistics
											text={'Unplanned'}
											count={data.unplanned_tasks}
											link={'https://redwing.happygoals.app/pages/unplanned_task.php'}
										/>
									</div>
								</div>
								<div>
									<div
										style={{
											background: '#5FB924',
											color: 'white',
											fontSize: '12px',
											fontWeight: '500',
											width: 'fit-content',
											borderRadius: '4px',
											padding: '0 8px',
											marginBottom: '10px'
										}}
									>
										Output
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3vw',
													marginBottom: '2px'
												}}
											>
												<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
													<CircularProgressbar
														value={data.completed_hours_percentage}
														strokeWidth={18}
														counterClockwise
														styles={buildStyles({
															pathColor: 'red',
															textColor: 'red',
															trailColor: '#414141',
															strokeLinecap: 'round'
														})}
													/>
												</div>
												<div style={{ fontSize: 25, color: 'white' }}>
													{data.completed_hours_percentage?.toFixed()}%
												</div>
											</div>
											<div style={{ fontSize: 12, color: 'white' }}>Today</div>
										</div>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.3vw',
													marginBottom: '2px'
												}}
											>
												<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
													<CircularProgressbar
														value={data.weekly_completed_hours_percentage}
														strokeWidth={18}
														counterClockwise
														styles={buildStyles({
															pathColor: 'red',
															textColor: 'red',
															trailColor: '#414141',
															strokeLinecap: 'round'
														})}
													/>
												</div>
												<div style={{ fontSize: 25, color: 'white' }}>
													{data.weekly_completed_hours_percentage?.toFixed()}%
												</div>
											</div>
											<div style={{ fontSize: 12, color: 'white' }}>Weekly</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						{/* <SwitchMode
							toggleFullScreen={toggleFullScreen}
							sectionName='teamWork'
							isFullScreen={isFullScreen}
						/> */}
						<div>
							<Swiper
								direction={'horizontal'}
								slidesPerView={'auto'}
								freeMode={true}
								scrollbar={true}
								mousewheel={true}
								modules={[FreeMode, Scrollbar, Mousewheel]}
								style={{ height: '65vh' }}
							>
								<SwiperSlide style={{ overflowY: 'scroll', width: 'fit-content' }}>
									<NewTeamWorkChopping
										isInverted={false}
										screenIndex={2}
										showTeamTabTop={false}
										showTabComponent={false}
										showActionButtons={false}
										data1={data}
									/>
								</SwiperSlide>
								<SwiperSlide style={{ width: '0' }}></SwiperSlide>
							</Swiper>
						</div>
						<div
							style={{
								color: 'rgba(211, 211, 211, 0.7)',
								margin: '0 auto',
								marginBottom: '1vh',
								fontStyle: 'italic',
								width: 'fit-content',
								fontSize: '14px',
								marginTop: '10px'
							}}
						>
							Updated {moment(data.updated_at).fromNow()}
						</div>
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className={styles.project}>
						<div className={styles.outertopStatisticsBar}>
							<div className={styles.topStatisticsBar}>
								<TopStatistics text={'Worth Orders'} count={topStatisticsCount.worthOrders} />
							</div>
						</div>
						{/* <SwitchMode
							toggleFullScreen={toggleFullScreen}
							sectionName='teamWork'
							isFullScreen={isFullScreen}
						/> */}
						<div className={styles.alignProjectsContent}>
							<ProjectsColumn setTopStatisticsCount={setTopStatisticsCount} />
						</div>
					</div>
				</SwiperSlide>
			</Swiper>

			{/* <div>
				<Link to='/homepage' onClick={scrollTop}>
					Go to Homepage
				</Link>

				{token && token !== 'undefined' && new Date(token_expiry_date) > new Date() && (
					<a onClick={handleRefreshUserList} style={{ marginLeft: '2rem' }}>
						Refresh User List
					</a>
				)}
				{(!token || token === 'undefined' || new Date(token_expiry_date) <= new Date()) && (
					<a
						style={{ marginLeft: '2rem' }}
						href='https://launchpad.37signals.com/authorization/new?type=web_server&client_id=7d03697adc886996a673634b89d51d8febb29979&redirect_uri=https://redwing.pages.dev/auth/callback'
					>
						Login to Basecamp
					</a>
				)}
			</div> */}
		</div>
	);
}

export default ChoppingDashboard;
