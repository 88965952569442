import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteModal(props) {
	const { openDeleteModal, setOpenDeleteModal, setTeam, user_id } = props;

	const handleClose = () => {
		setOpenDeleteModal(false);
	};
	const handleDeleteModalSubmit = e => {
		e.preventDefault();

		console.log(user_id);
		setTeam(preValues => {
			return preValues.filter(member => {
				return member.user_id != user_id;
			});
		});
	};
	return (
		<div>
			<Dialog
				open={openDeleteModal}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<form onSubmit={handleDeleteModalSubmit}>
					<DialogTitle id='alert-dialog-title'>
						Are you sure you want to delete this member ?
					</DialogTitle>

					<DialogActions>
						<Button onClick={handleClose}>Disagree</Button>
						<Button onClick={handleClose} type='submit'>
							Agree
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}
