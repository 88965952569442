import React, { useRef, useState } from 'react';
import styles from './index.module.css';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const PLAYBACK_SPEED_OPTIONS = [
	{
		label: '0.5x',
		value: 0.5
	},
	{
		label: 'Normal',
		value: 1
	},
	{
		label: '1.5x',
		value: 1.5
	},
	{
		label: '2x',
		value: 2
	}
];

const VideoPlayer = ({ src, videoProps, ...rest }) => {
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [videoPlayBackRate, setVideoPlayBackRate] = useState(PLAYBACK_SPEED_OPTIONS[1]);

	const handlePlayBtnClick = () => {
		setIsPlaying(true);
		videoRef.current.play();
	};

	const handlePauseBtnClick = e => {
		e.preventDefault();
		setIsPlaying(false);
		videoRef.current.pause();
	};

	const handlePlaybackRateBtnClick = newSpeed => {
		if (videoRef.current) {
			videoRef.current.playbackRate = newSpeed.value;
			setVideoPlayBackRate(newSpeed);
		}
	};

	let controlsNode;
	let progressNode;

	if (isPlaying) {
		progressNode = <>{}</>;
	} else if (!isPlaying) {
		controlsNode = (
			<>
				<div className={styles.playBtnContainer}>
					<PlayCircleIcon
						onClick={handlePlayBtnClick}
						style={{
							width: '60px',
							height: '60px',
							cursor: 'pointer'
						}}
					/>
				</div>
			</>
		);
	}

	return (
		<div {...rest} className={styles.videoPlayerContainer}>
			<video
				ref={videoRef}
				src={src}
				width='100%'
				height='auto'
				controls
				disablePictureInPicture
				controlslist='nodownload noplaybackrate'
				style={{
					cursor: 'pointer'
				}}
				onClick={handlePauseBtnClick}
				onPause={handlePauseBtnClick}
				onPlay={handlePlayBtnClick}
				{...videoProps}
			>
				Your browser does not support the video tag.
			</video>
			{progressNode}
			{controlsNode}
			<div className={styles.controlsBtnContainer}>
				{PLAYBACK_SPEED_OPTIONS.map(speed => (
					<button
						onClick={() => handlePlaybackRateBtnClick(speed)}
						className={videoPlayBackRate === speed ? styles.selectedRate : ''}
					>
						{speed.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default VideoPlayer;

