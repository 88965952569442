import React from 'react'
import './ActivityComponent.css'
import GradientBorder from 'components/GradientBorder/GradientBorder'
import { AvatarGroup } from '@material-ui/lab'
import AvatarWithAction from 'components/AvtarWithAction/AvatarWithAction'
import MarkReadButton from 'components/NotificationActionButtons/MarkReadButton'
import SaveLaterButton from 'components/NotificationActionButtons/SaveLaterButton'

const WorksheetActivityCard = ({ actionType, name, title, user, lineage, time, content,avatar ,isUnread ,markNotificationRead,saveForLater ,handleOpenTaskDetailsModal,comment}) => {
    return (
        <div  onClick={()=>{handleOpenTaskDetailsModal(comment)}} className={`${isUnread ? 'w-bg-regular':'w-bg-black'}`}>
          
            <div className={`main-activity-conatiner ${isUnread ? 'w-bg-regular':'w-bg-black'}`}>
                <div className='activity-info'>
                    <div className='activity-info-main'>
                        <div className='activity-user-data'>
                            <div>
                            <AvatarWithAction
                                actionType={actionType}
                                avatar={avatar}
                                name={name}
                            />
                            </div>

                        
                        <div>

                            <span className='w-activity-title'
                            onClick={()=>{handleOpenTaskDetailsModal(comment)}}
                            >
                                {title}
                            </span>
                            <div className='activity-meta-data'>
                                <span>{name}</span>
                                <span className='meta-seperator'>•</span>
                                <span>{lineage}</span>
                                <span className='meta-seperator'>•</span>
                                <span>{time}</span>
                            </div>
                        </div>
</div>

                        <div className='activity-notification-btn'>
                            {isUnread && <MarkReadButton onClick={markNotificationRead}/>}
                            <SaveLaterButton onClick={saveForLater}/>
                        </div>
                    </div>


                </div>
                <div className='activity-main-container'>
                   {content}
                </div>

            </div>

            <GradientBorder />

        </div>
    )
}

export default WorksheetActivityCard