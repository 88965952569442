import CommentIcon from 'components/CommentIcon/CommentIcon';
const styleObj = { height: '28px', width: '28px' };
const buttonList = [
	{
		id: 1,
		label: 'Escalate',
		key: 'escalation',
		icon: <CommentIcon type={'escalation'} style={{ ...styleObj, rotate: 'x 180deg' }} />,
		for_leader: false,
		shortcut: '⌘ E'
	},
	{
		id: 2,
		label: 'De-escalation',
		key: 'de-escalation',
		icon: <CommentIcon type={'de-escalation'} style={styleObj} />,
		for_leader: true,
		shortcut: '⌘ '
	},
	{
		id: 3,
		label: 'Follow up',
		key: 'followup',
		icon: <CommentIcon type={'followup'} style={styleObj} />,
		for_leader: true,
		shortcut: '⌘ U'
	},
	{
		id: 4,
		label: 'Approve',
		key: 'approve',
		icon: <CommentIcon type={'approve'} style={styleObj} />,
		for_leader: true,
		shortcut: '⌘ A'
	},
	{
		id: 5,
		label: 'Client Conversation',
		key: 'client_conversation',
		icon: <CommentIcon type={'client_conversation'} style={styleObj} />,
		for_leader: false,
		shortcut: '⌘ V'
	},
	{
		id: 6,
		label: 'Submission',
		key: 'submission',
		icon: <CommentIcon type={'submission'} style={styleObj} />,
		for_leader: false,
		shortcut: '⌘ B'
	},
	{
		id: 7,
		label: 'Comment',
		key: 'comment',
		icon: <CommentIcon type={'comment'} style={styleObj} />,
		for_leader: false,
		shortcut: '⌘ C'
	},
	{
		id: 8,
		label: 'Reopen',
		key: 'reopen',
		icon: <CommentIcon type={'reopen'} style={styleObj} />,
		for_leader: true,
		shortcut: '⌘ C',
		is_completed: true
	},
	{
		id: 9,
		label: 'Discussion',
		key: 'discussion',
		icon: <CommentIcon type={'discussion'} style={styleObj} />,
		for_leader: false,
		shortcut: '⌘ C',
		is_completed: false
	},
	{
		id: 10,
		label: "Do's and Dont's",
		key: 'dos_and_donts',
		icon: <CommentIcon type={'dos_and_donts'} style={styleObj} />,
		for_leader: false,
		shortcut: '⌘ C',
		is_completed: false
	},
	{
		id: 11,
		label: 'Involve',
		key: 'involve',
		icon: <CommentIcon type={'involve'} style={styleObj} />,
		for_leader: true,
		shortcut: '⌘ I',
		is_completed: false
	}
];

function useCommentButtons() {
	return [buttonList];
}

export default useCommentButtons;
