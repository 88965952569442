import CommentIcon from 'components/CommentIcon/CommentIcon'
import React from 'react'
import './NotificationAction.css'
import markRead from '../../assets/icons/readAction.svg'

const MarkReadButton = ({onClick}) => {
  return (
    <button onClick={onClick} className='notification-action-button'>
        <img src={markRead}/>
    </button>
  )
}

export default MarkReadButton