// import * as React from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const useAuth = () => {
	const token = localStorage.getItem('access_token');
	const navigate = useNavigate();
	const isUserLoggedIn = token && token !== 'undefined' ? true : false;

	const serverUrl = process.env.REACT_APP_LARAVEL_API_URL;
	useEffect(() => {
		if (token) {
			axios
				.get(`${serverUrl}/user`, {
					headers: {
						authorization: `Bearer ${token}`,
						Accept: 'application/json'
					}
				})
				.then(response => {
					if (response.status !== 200) {
						//* response status needs to be 200 else user wil be logged out
						localStorage.removeItem('access_token');
						navigate('/login', { replace: true });
					}
				})
				.catch(error => {
					//* if any error, then log out the user
					localStorage.removeItem('access_token');
					navigate('/login', { replace: true });
				});
		}
	}, [token]);

	return {
		isUserLoggedIn
	};
};

export default useAuth;
