const databaseName = "redwing", storeName = "globalSearch", key = "searchData";

export const getSearchData = () => {
    return new Promise((resolve) => {
        const request = window.indexedDB.open(databaseName);

        request.onerror = function (event) {
            console.error("Error opening IndexedDB database");
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            db.createObjectStore(storeName);
        };

        request.onsuccess = function (event) {
            const db = event.target.result;
            const transaction = db.transaction(storeName, "readonly");
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.get(key);
            request.onerror = function (event) {
                console.error("Error getting value from IndexedDB");
                resolve(false);
            };
            request.onsuccess = function (event) {
                resolve(event.target.result);
            };
        };
    })
}

export const setValueToIndexedDB = (newValue) => {
    const request = window.indexedDB.open(databaseName);
    request.onerror = function(event) {
      console.error("Error opening IndexedDB database");
    };

    request.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.put(newValue, key);
      request.onerror = function(event) {
        console.error("Error saving value to IndexedDB");
      };
      request.onsuccess = function(event) {
        //
      };
    };
  }