import { useState } from 'react';
import { useEffect } from 'react';
import { TextField } from '@mui/material';
import './Todo.css';

const people = [
	{
		name: 'Shashank Yadav',
		Reason:
			'  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae temporibus ipsam omnis voluptates laborum. Aliquam nulla distinctio accusantium voluptates vitae quae ut consequuntur veritatis accusamus soluta est excepturi perferendis voluptatum numquam explicabo placeat ad, nihil officia sint sed facilis reprehenderit non animi facere! Maxime voluptas aspernatur, vero totam rerum nobis ipsum quis expedita neque voluptatibus numquam.',
		department: 'Optimization',
		From: '2022-10-20',
		To: '2022-10-23',
		Applied: '10-10-2022',
		email: 'jane.cooper@example.com',
		image:
			'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
	},
	{
		name: 'Anand Bhagat',
		Reason: 'DIwali Holidays',
		department: 'Optimization',
		From: '2022-10-20',
		To: '2022-10-23',
		email: 'jane.cooper@example.com',
		image:
			'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
	},
	{
		name: 'Mohd Anas',
		Reason: 'DIwali Holidays',
		department: 'Optimization',
		From: '2022-10-20',
		To: '2022-10-23',
		email: 'jane.cooper@example.com',
		image:
			'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
	},
	{
		name: 'Puneet',
		Reason: 'DIwali Holidays',
		department: 'Optimization',
		From: '2022-10-20',
		To: '2022-10-23',
		email: 'jane.cooper@example.com',
		image:
			'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
	}
	// More people...
];

export default function TodoPage() {
	const [todo, setTodo] = useState('');
	const [todoItem, setTodoItem] = useState([]);
	const [subTask, setSubTask] = useState(false);
	const [ItemShift, setItemShift] = useState(0);

	useEffect(() => {
		const keyDownHandler = event => {
			if (event.key === 'Enter') {
				event.preventDefault();
				const newobj = { itemname: todo, shift: ItemShift };
				todoItem.push(newobj);
				// setTodoItem({...todoItem, newobj });
				setTodo('');
				console.log('final list ', todoItem);
			} else if (event.key === 'Tab' && !event.shiftKey) {
				event.preventDefault();
				setSubTask(true);
				setItemShift(ItemShift + 24);
			}
			// else if (event.key === 'ArrowLeft')
			else if (event.shiftKey && event.key === 'Tab') {
				event.preventDefault();
				setItemShift(ItemShift > 0 ? ItemShift - 24 : 0);
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [todo]);

	const ChangeHandler = e => {
		setTodo(e.target.value);
	};
	// useEffect(()=>{

	// },[todo , ItemShift])

	const EditHandler = (e, i) => {
		console.log(e.target.value, i);
		const EditedItem = e.target.value;
		const UpdatedList = todoItem.map((element, index) => {
			if (i === index) {
				return (element = EditedItem);
			} else {
				return element;
			}
		});
		setTodoItem(UpdatedList);
		console.log('UpdatedList', UpdatedList);
	};
	const checkboxHandler = e => {
		console.log(e.target.value);
	};

	const SubmitHandler = e => {
		e.preventDefault();
		console.log('Submited', todoItem);
	};

	return (
		<div className='parentTodoList' style={{ display: 'flex', justifyContent: 'center' }}>
			<div
				className='Todo-cantainer'
				style={{
					display: 'flex',
					flexFlow: 'column',
					justifyContent: 'center',
					alignItems: ' center',
					marginTop: '43px',
					color: 'white',
					width: '39%'
				}}
			>
				<h1 style={{ color: 'white', marginBottom: '20px' }}>Todo List</h1>
				{/* <div className="tod-textarea">
                <form onSubmit={SubmitHandler}>

                    <textarea row={5} placeholder='enter text' value={todo} onChange={ChangeHandler} style={{ width: '556px', height: '26px', padding: '3px 20px', marginBottom: '20px' }} />
                </form>
            </div> */}

				<div className='preview-area'>
					{todoItem.length > 0 &&
						todoItem.map((item, i) => {
							return (
								<div
									className='listItems'
									style={{
										marginLeft: `${item.shift}px`,
										marginTop: '3px'
									}}
								>
									<input
										type='checkbox'
										value={item.itemname}
										onChange={checkboxHandler}
										id={i}
										name={i}
										style={{ marginRight: '11px' }}
									/>
									<input
										type='text'
										className='title'
										value={item.itemname}
										onChange={e => {
											EditHandler(e, i);
										}}
										htmlFor={i}
									/>
								</div>
							);
						})}
					<div
						style={{
							marginLeft: `${ItemShift}px`,
							marginTop: '3px'
						}}
					>
						<input type='checkbox' style={{ marginRight: '11px' }} />
						{/* <TextField id="standard-basic" label="enter text" variant="standard" value={todo} onChange={ChangeHandler} style={{ width: '157px', height: '21px', padding: '1px 5px',marginBottom: '-5px', color:'white',
                            marginTop: '3px' }}/> */}

						<input
							value={todo}
							onChange={ChangeHandler}
							style={{
								width: '157px',
								height: '21px',
								padding: '1px 5px',
								marginBottom: '-5px',
								marginTop: '3px',
								background: 'transparent',
								color: 'white',
								border: 0
							}}
						/>
					</div>
				</div>

				{/* <button onClick={SubmitHandler}>submit</button> */}
			</div>
		</div>
	);
}
