
import moment from 'moment';

export const mobileDateFormatter = (rawdate) => {
  const date = moment(rawdate);
  return date.format('MMM Do YYYY');
};

export const mobileTimeFormatter = (rawdate) => {
  const date = moment(rawdate);
  let forMobile = date.fromNow();
  forMobile = forMobile.replace(" hours ago", "h");
  forMobile = forMobile.replace("an hour ago", "1h");
  forMobile = forMobile.replace(" minutes ago", "m");
  forMobile = forMobile.replace("a minute ago", "1m");
  forMobile = forMobile.replace(" days ago", "d");
  forMobile = forMobile.replace("a day ago", "1d");
  return forMobile;
};
