import React, { useEffect, useState } from 'react';
import styles from './NavigationBar.module.css';
import {
	MdSpaceDashboard,
	MdNotifications,
	MdOutlineBarChart,
	MdOutlinePowerSettingsNew,
	MdChatBubble,
	MdViewList
} from 'react-icons/md';
import { BsListTask, BsTruck } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import teamwork from '../../assets/icons/teamwork.svg';
import agenda from '../../assets/icons/agenda.svg';
import performance from '../../assets/icons/performance.svg';
import dashboard from '../../assets/icons/dashboard-icon.svg';
import alerts from '../../assets/icons/alerts.svg';
import feedback from '../../assets/icons/feedback.svg';
import logout from '../../assets/icons/logout.svg';
import logo from '../../assets/icons/Logo.svg';
import estimates from '../../assets/icons/estimates.svg';
import * as axiosBase from 'axios';
import searchIcon from '../../assets/icons/search.svg';
import worksheetIcon from '../../assets/icons/worksheet-icon.svg';
import { useContext } from 'react';
import { AllDataContext } from '../../context/AllDataContext';
const token = localStorage.getItem('red_wing_token');
const token_expiry_date = localStorage.getItem('red_wing_token_expiry_date');

const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

function NavigationBar({
	showSegments,
	setShowSegments,
	alertCount,
	setAlertCount,
	setTeamWorkFullView,
	teamWorkFullView,
	notificationCount,
	setNotificationCount,
	isBottomNavVisible,
	setBottomNavVisible
}) {
	const location = useLocation();

	const localStorageData = localStorage.getItem('redwing_data');
	const [data, setData] = useState(
		localStorage.getItem('redwing_data') ? JSON.parse(localStorageData) : {}
	);

	// const [defaulters, setDefaulters] = useState(null);

	// useEffect(() => {
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_URL}/pages/performance_feedbacks.php`, {
	// 			headers: {
	// 				Authorization: `${token}`,
	// 				'Access-Control-Allow-Origin': '*'
	// 			}
	// 		})
	// 		.then(res => {
	// 			if (res.data.status) {
	// 				console.log(res);
	// 				//setFeedbacks(res.data.feedbacks);
	// 				setDefaulters(res.data.user_list_without_feedbacks);
	// 			}
	// 			//setLoading(false);
	// 		})
	// 		.catch(error => {
	// 			console.error(error);
	// 			//setLoading(false);
	// 		});
	// }, []);

	useEffect(() => {
		getNotificationCount();
	}, []);

	const getNotificationCount = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/count/unread`;
		axios
			.get(url)
			.then(data => {
				setNotificationCount(data.data.data.count);
			})
			.catch(e => console.log(e));
	};
	const responsiveStyles = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '100%',
		paddingTop: '14px',
		paddingBottom: '7px'
	};

	return (
		<>
			<div className={styles.webBar}>
				<div className={styles.mobileHidden}>
					<img src={logo} width='65px' />
				</div>

				<div className={styles.icons1}>
					<Link
						to='/chopping-dashboard'
						// onClick={() => setTeamWorkFullView(false)}
						style={
							location.pathname === '/chopping-dashboard' ||
							(location.pathname === '/' && !teamWorkFullView)
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.5', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<img src={dashboard} width='23px' />
						<div style={{ color: 'white', fontSize: '10px' }}>Dashboard</div>
					</Link>

					<Link
						to='/chopping-interface'
						// onClick={() => setTeamWorkFullView(true)}
						style={
							location.pathname === '/chopping-interface' ||
							location.pathname.includes('/tasks/assigned')
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.5', textDecoration: 'none', textAlign: 'center' }
						}
					>
						{/* <img src={teamwork} width='23px' /> */}
						<BsListTask className={styles.bsicon} />
						<div style={{ color: 'white', fontSize: '10px' }}>Worksheets</div>
					</Link>
					{/* <Link
						to='#'
						onClick={() => setShowSegments(!showSegments)}
						style={
							showSegments
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.7', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<div style={{ position: 'relative' }}>
							<img src={alerts} width='23px' />
							<div
							style={{
								width: '15px',
								height: '15px',
								background: 'red',
								borderRadius: '100%',
								position: 'absolute',
								top: '-5px',
								right: '-2px',
								color: 'white',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '12px'
							}}
						>
							{alertCount}
						</div>
						</div>
						<div style={{ color: 'white', fontSize: '10px' }}>Alerts</div>
					</Link> */}
					<Link
						to='/notifications'
						style={
							showSegments || location.pathname === '/notifications'
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.5', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<div style={{ position: 'relative' }}>
							<img src={alerts} width='23px' />
							<div
								style={{
									width: '15px',
									height: '15px',
									background: 'red',
									borderRadius: '100%',
									position: 'absolute',
									top: '-5px',
									right: '0px',
									color: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: '10px',
									fontWeight: 'bold'
								}}
							>
								{notificationCount}
							</div>
						</div>
						<div style={{ color: 'white', fontSize: '10px' }}>Notifications</div>
					</Link>

					<Link
						to='/delivery-list'
						style={
							location.pathname === '/delivery-list'
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.5', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<div style={{ position: 'relative' }}>
							{/* <img src={alerts} width='23px' style={{margin:'-4px'}} /> */}
							<BsTruck className={styles.bsiconmobile} />
						</div>
						<div style={{ color: 'white', fontSize: '10px' }}>Deliveries</div>
					</Link>

					{/* <Link
						to='/rapid-estimation'
						style={
							location.pathname === '/rapid-estimation'
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.7', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<img src={agenda} width='23px' />
						<div style={{ color: 'white', fontSize: '10px' }}>Agenda</div>
					</Link> */}
					{/* <Link
						to='/performance/feedback_report'
						style={
							location.pathname === '/performance/feedback_report'
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.7', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<div style={{ position: 'relative' }}>
							<img src={feedback} width='23px' />
							{data && (
								<div
									style={{
										width: '15px',
										height: '15px',
										background: 'red',
										borderRadius: '100%',
										position: 'absolute',
										top: '-5px',
										right: '5px',
										color: 'white',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										fontSize: '12px'
									}}
								>
									{data?.performance_feedback_count}
								</div>
							)}
						</div>
						<div style={{ color: 'white', fontSize: '10px' }}>Feedback</div>
					</Link> */}
					<Link
						to='/performance'
						style={
							location.pathname === '/performance'
								? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
								: { opacity: '0.5', textDecoration: 'none', textAlign: 'center' }
						}
					>
						<img src={performance} width='23px' />
						<div style={{ color: 'white', fontSize: '10px' }}>Performance</div>
					</Link>
				</div>

				<div className={styles.icons2}>
					{/* {!token || token === 'undefined' || new Date(token_expiry_date) <= new Date() ? (
						<a
							style={{ opacity: '0.7', textDecoration: 'none', textAlign: 'center' }}
							href='https://launchpad.37signals.com/authorization/new?type=web_server&client_id=7d03697adc886996a673634b89d51d8febb29979&redirect_uri=https://redwing.pages.dev/auth/callback'
						>
							<img src={logout} width='23px' />
							<div style={{ color: 'white', fontSize: '10px' }}>Login</div>
						</a>
					) : (
						<Link to='#' style={{ opacity: '0.7', textDecoration: 'none', textAlign: 'center' }}>
							<img src={logout} width='23px' />
							<div style={{ color: 'white', fontSize: '10px' }}>Logout</div>
						</Link>
					)} */}
				</div>
			</div>

			<div className={styles.mobileBar} style={{ display: isBottomNavVisible ? '' : 'none' }}>
				<Link
					to='/'
					onClick={() => setTeamWorkFullView(false)}
					style={
						location.pathname === '/' && !teamWorkFullView
							? { opacity: '1', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
							: { opacity: '0.5', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
					}
				>
					<img src={dashboard} width='23px' />
					<div style={{ color: 'white', fontSize: '10px' }}>Dashboard</div>
				</Link>

				<Link
					to='/chopping-interface'
					onClick={() => setTeamWorkFullView(true)}
					style={
						location.pathname === '/chopping-interface'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
							: { opacity: '0.5', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
					}
				>
					{/* <img src={teamwork} width='23px' /> */}
					<BsListTask className={styles.bsiconmobile} />
					{/* <img src={worksheetIcon} width='23px'/> */}
					<div style={{ color: 'white', fontSize: '10px' }}>Worksheets</div>
				</Link>

				{/* <Link
					to='#'
					onClick={() => setShowSegments(!showSegments)}
					style={
						showSegments
							? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
							: { opacity: '0.6', textDecoration: 'none', textAlign: 'center' }
					}
				>
					<div style={{ position: 'relative' }}>
						<img src={alerts} width='23px' />
						<div
							style={{
								width: '15px',
								height: '15px',
								background: 'red',
								borderRadius: '100%',
								position: 'absolute',
								top: '-5px',
								right: '-2px',
								color: 'white',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '12px'
							}}
						>
							{alertCount}
						</div>
					</div>
					<div style={{ color: 'white', fontSize: '10px' }}>Alerts</div>
				</Link> */}

				<Link
					to='/notifications'
					style={
						showSegments || location.pathname === '/notifications'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
							: { opacity: '0.5', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
					}
				>
					<div style={{ position: 'relative' }}>
						<img src={alerts} width='23px' style={{ margin: '-4px' }} />
						<div
							style={{
								width: '15px',
								height: '15px',
								background: 'red',
								borderRadius: '100%',
								position: 'absolute',
								top: '-5px',
								right: '-2px',
								color: 'white',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '12px'
							}}
						>
							{notificationCount}
						</div>
					</div>
					<div style={{ color: 'white', fontSize: '10px' }}>Notifications</div>
				</Link>
				<Link
					to='/delivery-list'
					style={
						location.pathname === '/delivery-list'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
							: { opacity: '0.5', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
					}
				>
					<div style={{ position: 'relative' }}>
						{/* <img src={alerts} width='23px' style={{margin:'-4px'}} /> */}
						<BsTruck className={styles.bsiconmobile} />
					</div>
					<div style={{ color: 'white', fontSize: '10px' }}>Deliveries</div>
				</Link>

				{/* <Link
					to='/rapid-estimation'
					style={
						location.pathname === '/rapid-estimation'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
							: { opacity: '0.6', textDecoration: 'none', textAlign: 'center' }
					}
				>
					<img src={estimates} width='23px' />
					<div style={{ color: 'white', fontSize: '10px' }}>Estimates</div>
				</Link> */}
				{/* <Link
					to='/rapid-estimation'
					style={
						location.pathname === '/rapid-estimation'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center',...responsiveStyles }
							: { opacity: '0.7', textDecoration: 'none', textAlign: 'center',...responsiveStyles }
					}
				>
					<img src={agenda} width='23px' />
					<div style={{ color: 'white', fontSize: '10px' }}>Agenda</div>
				</Link> */}
				{/* <Link
					to='/performance/feedback_report'
					style={
						location.pathname === '/performance/feedback_report'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center' }
							: { opacity: '0.6', textDecoration: 'none', textAlign: 'center' }
					}
				>
					<div style={{ position: 'relative' }}>
						<img src={feedback} width='23px' />
						{data && (
							<div
								style={{
									width: '15px',
									height: '15px',
									background: 'red',
									borderRadius: '100%',
									position: 'absolute',
									top: '-5px',
									right: '5px',
									color: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: '12px'
								}}
							>
								{data?.performance_feedback_count}
							</div>
						)}
					</div>
					<div style={{ color: 'white', fontSize: '10px' }}>Feedback</div>
				</Link> */}
				<Link
					to='/search'
					style={
						location.pathname === '/search'
							? { opacity: '1', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
							: { opacity: '0.5', textDecoration: 'none', textAlign: 'center', ...responsiveStyles }
					}
				>
					<img src={searchIcon} width='23px' />
					<div style={{ color: 'white', fontSize: '10px' }}>Search</div>
				</Link>
			</div>
		</>
	);
}

export default NavigationBar;

