import React from 'react'
import { ClientTreeIcon, DownArrowOrange, GreenCheck, TreeCompleted, TreeDeEscalation, TreeDosandDonts, TreeInvolveIcon, TreeOpenIcon, TreeSubmittedIcon, UpArrowOrange } from 'assets/icons'
import CommentIcon from 'components/CommentIcon/CommentIcon'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { AiFillWechat, AiOutlineWarning } from 'react-icons/ai'

const TaskTreeIcon = ({item, ...otherProps}) => {
  const TaskIconExtra =({type})=>{
    switch (type) {
      case 'escalation':
          return <TreeDeEscalation style={{transform:'rotate(180deg)'}} {...otherProps }/>;
      case 'de-escalation':
          return <TreeDeEscalation {...otherProps }/>;
      case 'followup':
          return <TreeDeEscalation {...otherProps }/>;
      case 'approve':
          return <GreenCheck {...otherProps }/>;
      case 'client_conversation':
          return <ClientTreeIcon/>;
      case 'submission':
          return <TreeSubmittedIcon {...otherProps }/>;
      case 'comment':
          return  <ChatBubbleOutlineIcon {...otherProps }/>;
      case 'discussion':
          return  <AiFillWechat {...otherProps }/>;
      case 'dos_and_donts':
          return <TreeDosandDonts {...otherProps }/>;
      case 'involve':
              return <TreeInvolveIcon {...otherProps }/>;
      default:
        return (
          // <div className={`fa_uncheck_icon ${otherProps?.className}`} style={otherProps?.styles} {...Object.fromEntries(
          //     Object.entries(otherProps).filter(([key]) => key !== 'className')
          // )}>
             
          // </div>
          <TreeOpenIcon {...otherProps }/>
        );
    }
  }
  return (
    <div style={{display:'flex'}}>
        <>
            {item.completed && (
                <TreeCompleted/>
            )}
            {!item.completed && item.last_comment_type !== 'comment' && item.last_comment_type !== 'approve' &&(
               <TaskIconExtra type={item.last_comment_type}/>
            )}
            {!item.completed && (item.last_comment_type === 'comment' || (item.last_comment_type === 'approve' && !item.completed) ) &&(
                <TaskIconExtra type={false} {...otherProps}/>
            )}
        </>
    </div>
  )
}

export default TaskTreeIcon