import React from 'react';
import './avataraction.css';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import comApprove from '../../assets/icons/com-approve.svg';
import comReopen from '../../assets/icons/com-reopen.svg';
import comInvolve from '../../assets/icons/com-involve.svg';
import comComment from '../../assets/icons/com-comment.svg';
import comDiscussion from '../../assets/icons/com-discussion.svg';
import dos from '../../assets/icons/dosAndDont.svg';
import {
	BlueFileTick,
	DownArrowOrange,
	MentionIcon,
	UpArrowOrange,
	BlueTick,
	UserIcon
} from 'assets/icons';

const AvatarWithAction = ({ actionType, avatar, name }) => {
	const getCommentIcon = () => {
		switch (actionType) {
			case 'approve':
				return <img src={comApprove} alt='approve' />;
			case 'submission':
				return <BlueTick />;
			case 'escalation':
				return <UpArrowOrange />;
			case 'de-escalation':
				return <DownArrowOrange />;
			case 'followup':
				return <DownArrowOrange />;
			case 'dos_and_donts':
				return <img src={dos} alt='do-dont' />;
			case 'discussion':
				return <img src={comDiscussion} alt='discussion' />;
			case 'reopen':
				return <img src={comReopen} alt='reopen' />;
			case 'involve':
				return <img src={comInvolve} alt='involve' />;
			case 'comment':
				return <img src={comComment} alt='comment' />;
			case 'client_conversation':
				return <UserIcon />;
			case 'task-assigned':
				return <BlueFileTick />;
			case 'mention':
				return <MentionIcon />;
			default:
				return 'something went wrong';
		}
	};
	const getBorderColor = () => {
		if (
			actionType === 'submission' ||
			actionType === 'client_conversation' ||
			actionType === 'task-assigned'
		) {
			return 'border-blue';
		}
		if (actionType === 'approve') {
			return 'border-green';
		}
		if (
			actionType === 'escalation' ||
			actionType === 'de-escalation' ||
			actionType === 'followup'
		) {
			return 'border-yellow';
		}
		if (actionType === 'reopen') {
			return 'border-red';
		} else {
			return 'border-white';
		}
	};
	return (
		<div className={`av-container ${getBorderColor()}`}>
			<CustomAvatar
				avatar={avatar}
				alt='profile'
				name={name}
				style={{
					height: '32px',
					width: '32px',
					borderRadius: '100%',
					fontWeight: '700',
					letterSpacing: '1px'
				}}
			/>

			<div className='av-icon-container'>{getCommentIcon()}</div>
		</div>
	);
};

export default AvatarWithAction;
