import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import './DisplayBreadcrumbs.css';
import { FaSitemap } from 'react-icons/fa';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toastr from 'toastr';
import GradientBorder from 'components/GradientBorder/GradientBorder';

const DisplayBreadcrumbs = ({
	item,
	tasks,
	worksheets,
	style,
	showLineageIcon,
	users,
	hideAssignee
}) => {
	const [breadcrumb, setBreadcrumb] = useState([]);
	const [show, setShow] = useState(true);

	const getParentIds = item => {
		let parentids = [];

		let parent_id = item?._id;
		if (!item?.assginee) {
			parentids.push(parent_id);
		}

		while (parent_id != '') {
			parent_id = getParentId(parent_id);
			if (parent_id !== '' && parent_id !== 0 && parent_id !== null) {
				parentids.push(parent_id);
			}
		}

		return parentids;
	};

	const getParentId = itemid => {
		var item = [...tasks].filter(value => {
			return value._id === itemid;
		})[0];
		return item?.parent_id || '';
	};

	useEffect(() => {
		let parents_ids = getParentIds(item);

		let parents = [...tasks]
			.filter(value => {
				return parents_ids.includes(value._id);
			})
			.sort((a, b) => a.seq - b.seq);

		setBreadcrumb(generateBreadcrumb(parents));
		if (window.innerWidth < 500) {
			setShow(false);
		}
	}, [item, worksheets]);

	const generateBreadcrumb = parents => {
		let bread = [];
		let worksheet = [...worksheets].find(function (sheet) {
			return sheet?._id === item?.worksheet_id;
		});
		if (worksheet && worksheet.title) {
			bread.push(
				<BreadcrumbItem
					worksheet={worksheet}
					showLineageIcon={showLineageIcon}
					users={users}
					hideAssignee={hideAssignee}
				/>
			);
		}

		bread = bread.concat(
			parents
				.sort((a, b) => a.seq - b.seq)
				.map((item, index) => {
					return (
						<BreadcrumbItem
							item={item}
							key={index}
							showLineageIcon={showLineageIcon}
							users={users}
							hideAssignee={hideAssignee}
						/>
					);
				})
		);
		return bread;
	};

	return (
		<div
			style={{
				display: 'flex'
				// marginTop: style?.marginTop ? style.marginTop : '20px',
				// marginBottom: '12px'
			}}
		>
			<div
				style={{
					display: 'flex',
					marginTop: style?.marginTop ? style.marginTop : '20px',
					marginBottom: style?.marginBottom ? style.marginBottom : '12px',
					borderBottom: style?.borderBottom,
					padding: style?.padding,
					zIndex: 10,
					color: style?.color,
					fontFamily: style?.fontFamily,
					fontSize: style?.fontSize,
					fontStyle: 'normal',
					borderBottomWidth: style?.borderBottomWidth,
					borderBottomStyle: style?.borderBottomStyle,
					borderImageSource: style?.borderImageSource,
					borderImageSlice: 1,
					zIndex: 10,
					color: '#FFF',
					fontFamily: 'Poppins',
					fontSize: 11,
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '16.5px',
					

				}}
			>
				{showLineageIcon && (
					<div
						className={`breadcrumb_text d-flex align-items-center`}
						style={{ width: 'unset', marginRight: '10px', cursor: 'pointer', height: '25px' }}
						onClick={e => {
							e.preventDefault();
							setShow(prev => !prev);
						}}
					>
						<FaSitemap />
					</div>
				)}
				{((show && showLineageIcon) || !showLineageIcon) && (
					<Breadcrumbs
						style={{ display: 'flex', alignItems: 'center' }}
						separator={
							<IconContext.Provider value={{ color: 'white' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<BsChevronRight />
								</div>
							</IconContext.Provider>
						}
						aria-label='breadcrumb'
					>
						{breadcrumb}
					</Breadcrumbs>
				)}
				
			</div>
		</div>
	);
};

const BreadcrumbItem = ({ item, worksheet, showLineageIcon, users, hideAssignee }) => {
	const [showCopyIcon, setShowCopyIcon] = useState(false);

	const handleShowCopyIcon = () => {
		setShowCopyIcon(true);
	};

	const handleHideCopyIcon = () => {
		setShowCopyIcon(false);
	};

	const handleCopyText = title => {
		let cleanTitle = title;
		if (title.includes('@')) {
			users.forEach(user => {
				if (title.includes(user.name)) {
					cleanTitle = title.replaceAll(user.name, '').replaceAll('@', '');
				}
			});
		}

		navigator.clipboard
			.writeText(cleanTitle.trim())
			.then(() => {
				setTimeout(() => toastr.success(`Text copied to clipboard!.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));
	};

	return (
		<div
			onMouseEnter={handleShowCopyIcon}
			onMouseLeave={handleHideCopyIcon}
			style={{ display: 'flex', alignItems: 'center' }}
		>
			{worksheet && worksheet.title && (
				<Link
					underline='hover'
					key='1'
					color='inherit'
					to={`/chopping-interface/${worksheet._id}`}
					className='breadcrumb_text'
				>
					{worksheet.title}
				</Link>
			)}
			{item && (
				<Link
					underline='hover'
					key='1'
					color='inherit'
					to={`/chopping-interface/${item.worksheet_id}?item_id=${item._id}`}
					className='breadcrumb_text'
				>
					{!hideAssignee ? item.title : item?.core_title}
				</Link>
			)}
			{showLineageIcon && showCopyIcon ? (
				<ContentCopyIcon
					style={{
						marginLeft: '10px',
						cursor: 'pointer',
						color: 'white',
						height: '13px',
						width: '13px'
					}}
					onClick={() => {
						handleCopyText(worksheet?.title || item?.title);
					}}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default DisplayBreadcrumbs;

