import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { React, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import styles from './UserTaskList.module.css';
import { UsersListSidebar } from 'components/UsersListSidebar/UsersListSidebar';
import { UserTasks } from 'components/UserTasks/UserTasks';

const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');

const UserTaskList = () => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [activeUser, setActiveUser] = useState({});
	const [tasks, setTasks] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [hideChilds, setHideChilds] = useState([]);
	const [totalEstimate, setTotalEstimate] = useState(0);
	const { user_id } = useParams();
	const [selectedTask, setSelectedTask] = useState(null);
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
	const [slideIndex, setSlideIndex] = useState(0);

	const getWindowSize = () => {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [isMobile, setIsMobile] = useState(false);

	const swiperRef = useRef(null);

	// ---------------------------------------------------------

	const handleOpenTaskDetailsModal = task => {
		setSelectedTask(task);
		setOpenTaskDetailsModal(true);
	};
	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
	};

	const goToTasksSlide = () => {
		if (isMobile) {
			swiperRef.current.swiper.slideTo(1); // Go to slide 2
		}
	};

	// ---------------------------------------------------------

	useEffect(() => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/users`, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then(res => {
				setLoading(false);
				//console.log('dots', res.data.users);
				setUsers(res.data.users);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (user_id) {
			let user = [...users].filter(value => {
				return value._id === user_id;
			})[0];

			setTasks([]);
			setTotalEstimate(0);
			setActiveUser(user);
		}
	}, [user_id, users]);

	useEffect(() => {
		if (windowSize.innerWidth <= 600) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [windowSize.innerWidth]);

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const getTodoList = () => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/task/assigned?user_id=${activeUser._id}`;
		fetch(url, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then(data => data.json())
			.then(data => {
				setLoading(false);
				setTasks(data.tasks);
				setWorksheets(data.worksheets);
				setTotalEstimate(data.estimate);
			})
			.catch(e => console.log(e));
	};

	const handleUserChange = (e, user_id) => {
		e.preventDefault();
		if (user_id !== activeUser._id) {
			let user = [...users].filter(value => {
				return value._id === user_id;
			})[0];
			setTasks([]);
			setActiveUser(user);
			window.history.pushState('', '', `/tasks/assigned/${user_id}`);
		}
	};

	useEffect(() => {
		if (activeUser?._id) {
			getTodoList();
		}
	}, [activeUser]);
	return (
		<div>
			<Backdrop className={styles.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{isMobile ? (
				<Box className={styles.swiperContainer}>
					<Swiper
						ref={swiperRef}
						onSlideChange={() => setSlideIndex(swiperRef.current.swiper.ActiveIndex)}
						preventClicks={false}
						preventClicksPropagation={false}
						observer={true}
						observeParents={true}
					>
						<SwiperSlide>
							<div style={{ height: '100%', width: '100%', zIndex: '9999', overflowY: 'auto' }}>
								<div className={styles.leftSidebar}>
									<UsersListSidebar users={users} user_id={user_id} handleUserChange={handleUserChange} goToTasksSlide={goToTasksSlide} />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div style={{ height: '100%', width: '100%', zIndex: '9999', overflowY: 'auto' }}>
								<div className={`main-content`} style={{ height: 'calc(90vh + 10px)' }}>
									<UserTasks tasks={tasks} activeUser={activeUser} totalEstimate={totalEstimate} hideChilds={hideChilds} setHideChilds={setHideChilds} worksheets={worksheets} handleOpenTaskDetailsModal={handleOpenTaskDetailsModal} selectedTask={selectedTask} handleCloseTaskDetailsModal={handleCloseTaskDetailsModal} openTaskDetailsModal={openTaskDetailsModal} />
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</Box>
			) : (
				<div className={`body_container ${styles.main}`}>
					<div className={styles.leftSidebar}>
						<UsersListSidebar users={users} user_id={user_id} handleUserChange={handleUserChange} goToTasksSlide={goToTasksSlide} />
					</div>
					<div className={`main-content`}>
						<UserTasks tasks={tasks} activeUser={activeUser} totalEstimate={totalEstimate} hideChilds={hideChilds} setHideChilds={setHideChilds} worksheets={worksheets} handleOpenTaskDetailsModal={handleOpenTaskDetailsModal} selectedTask={selectedTask} handleCloseTaskDetailsModal={handleCloseTaskDetailsModal} openTaskDetailsModal={openTaskDetailsModal} />
					</div>
				</div>
			)}
		</div>
	);
};

export default UserTaskList;