import React from 'react'
import './NotificationComponentsStyles.css'
const CountContainer = ({ count }) => {
    return (
        <div className='count-container'>
            {count}
        </div>
    )
}

export default CountContainer