import { Backdrop, Box, CircularProgress, Modal } from '@material-ui/core'
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPresetTasks } from "reducers/presetTasks";
import * as axiosBase from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import parse from "html-react-parser";
import Wysiwyg from 'components/wysiwyg';
import { LoadingButton } from '@mui/lab';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	}
});

toastr.options = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 5000
};

const PresetTasks = () => {
    const [loading, setLoading] = useState(false);
    const presetTasks = useSelector(state => state.reducerTasks.data);
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [action, setAction] = useState("");

    useEffect(() => {
		dispatch(fetchPresetTasks());
    }, [dispatch]);

    const handleCloseModal = () => {
        setSelectedTask(null);
        setOpenModal(false);
    }

    const handleSubmit = () => {
        setBtnLoading(true);
        var payload = {
            title: title,
            description: description,
        }
		if(action === 'add'){   
            var url = `${process.env.REACT_APP_LARAVEL_API_URL}/preset-tasks`;

            axios.post(url, payload)
            .then(res => {
                if (res.data.success) {
                    setDescription("");
                    setTitle("");
                    setSelectedTask({});
                    handleCloseModal();
                
                    dispatch(fetchPresetTasks());
                
                    toastr.clear();
                    setTimeout(
                        () =>
                            toastr.success(
                                `Task ${action === 'add' ? 'added' : 'updated'} successfully. `
                            ),
                        300
                    );
                }else{
                    toastr.clear();
                    setTimeout(
                        () =>
                            toastr.error(
                                `Something went wrong while commenting. Please try again `
                            ),
                        300
                    );
                }
            }).finally(e => {
                setBtnLoading(false);
            });
        }else{
            var url = `${process.env.REACT_APP_LARAVEL_API_URL}/preset-tasks/${selectedTask._id}`;
            axios.put(url, payload)
            .then(res => {
                if (res.data.success) {
                    setDescription("");
                    setTitle("");
                    setSelectedTask({});
                    handleCloseModal();
                
                    dispatch(fetchPresetTasks());
                
                    toastr.clear();
                    setTimeout(
                        () =>
                            toastr.success(
                                `Task ${action === 'add' ? 'added' : 'updated'} successfully. `
                            ),
                        300
                    );
                }else{
                    toastr.clear();
                    setTimeout(
                        () =>
                            toastr.error(
                                `Something went wrong while commenting. Please try again `
                            ),
                        300
                    );
                }
            }).finally(e => {
                setBtnLoading(false);
            });
        }
	}

    const deleteTask = task_id => {
		if (!window.confirm('Are you sure you want to delete this task?')) {
			return 0;
		}
        setLoading(true);
        var url = `${process.env.REACT_APP_LARAVEL_API_URL}/preset-tasks/${task_id}`;
        axios.delete(url)
        .then(res => {
            if (res.data.success) {
                dispatch(fetchPresetTasks());

                setTimeout(
                    () =>
                        toastr.success(
                            `Task deleted successfully. `
                        ),
                    300
                );
            }else{
                toastr.clear();
                setTimeout(
                    () =>
                        toastr.error(
                            `Something went wrong while commenting. Please try again `
                        ),
                    300
                );
            }
        }).finally(e => {
            setLoading(false);
        });
    }

    return (
        <div className='main-content'>
            <Backdrop className="backdrop" open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <div className='d-flex'>
                <div>
                    <h1 className='page-title m-0'>Preset Tasks</h1>
                </div>
                <div>
                    <button className="bg-transparent text-normal border py-1 px-3 mb-0 mx-2" style={{ border: '1px solid white', cursor: 'pointer' }} onClick={(e) => {e.preventDefault(); setOpenModal(true); setAction("add")}}>Add</button>
                </div>
            </div>

            <div>
                <table className='teamTable'>
                    <thead>
                        <tr>
                            <th className='text-normal mb-0' style={{ width: '90%' }}>Title</th>
                            <th className='text-normal mb-0' style={{ width: '10%' }}>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {presetTasks?.map((task, index) => {
                            return (
                                <tr key={index} style={{ borderBottom: '1px solid #ffffff73' }}>
                                    <td className='mb-0 py-3'>
                                        <p className='text-normal'>{task.title}</p>
                                    </td>
                                    <td className='mb-0 py-3 d-flex'>
                                        <button className="bg-transparent text-normal border py-1 px-3 mb-0 mx-2" style={{ border: '1px solid white', cursor: 'pointer' }} onClick={(e) => {e.preventDefault(); setOpenModal(true); setAction("edit"); setSelectedTask(task); setTitle(task.title); setDescription(task.description)}}>Edit</button>
                                        <button className="bg-transparent text-normal border py-1 px-3 mb-0 mx-2" style={{ border: '1px solid white', cursor: 'pointer' }} onClick={(e) => {e.preventDefault(); deleteTask(task._id);}}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box className={`modal_container`}>
					<Box className={`modal_header`}>
                        {action}
					</Box>
					<Box className={`modal_body`} >
                        <div className='py-3 pr-3'>
                            <label className='pr-3'>Title</label>
                            <input
                                value={title}
                                style={{ 
                                    padding: '5px 10px',
                                    width: '100%'
                                }}
                                placeholder="Enter Title"
                                onChange={(e) => {setTitle(e.target.value)}}
                            />
                        </div>
                        <div>
                            <div className='mb-3'>
                                <label>Description</label>
                            </div>
                            <div style={{ backgroundColor: 'white', color: 'black' }}>
                                <Wysiwyg value={selectedTask?.description} onChange={html => setDescription(html)} onSubmit={handleSubmit} setLoading={setBtnLoading} />
                            </div>
                        </div>
						<LoadingButton loading={btnLoading} loadingIndicator="Uploading…" variant="contained" className='mt-3' onClick={handleSubmit}>
							Save
						</LoadingButton>
					</Box>
				</Box>
			</Modal>
        </div>
    )
}

export default PresetTasks