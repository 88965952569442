import React from 'react'
import './ActivityComponent.css'
import GradientBorder from 'components/GradientBorder/GradientBorder'
const ActivityDateContainer = ({ date }) => {
    return (
        <div>
               
            <div className='activity-date'>
                <span>{date}</span>
            </div>
            <GradientBorder/>
               
        </div>
    )
}

export default ActivityDateContainer