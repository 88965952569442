import * as axiosBase from 'axios';
import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";


const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

export default function LeaveApplication() {
  const [currentMonth,setcurrentMonth]=useState('');
  const [LastMonth,setLastMonth]=useState('');
  const [NextMonth,setNextMonth]=useState('');
 
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_LARAVEL_API_URL}/leave/report`)
    .then((response)=>{console.log('response',response);
    setcurrentMonth(response.data.current_month );
    setLastMonth(response.data.last_month);
    setNextMonth(response.data.next_month);
  })
    .catch((e)=>{console.log('e',e);})
  },[])

  return (
    <div className="Table-leave" style={{
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: ' center',
      marginTop: '43px'
    }}>

      {/* //LstMonth */}
      {LastMonth.length>0 && <>
      <h1 style={{ color: 'white' }}> Last Month </h1>
      <div className="mt-3" style={{ width: ' 88%' }}>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" style={{
            padding: '61px',
            color: 'white'
          }}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Reason
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Till
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Days
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Date
                    </th>
                    {/* <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Status</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {LastMonth.length>0 && LastMonth.map((person,i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {/* <div className="flex-shrink-0 h-10 w-10">
                            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                          </div> */}
                          <div>
                            <div className="text-sm font-medium text-gray-900">{person.user.name}</div>
                            {/* <div className="text-sm text-gray-500">{person.email}</div> */}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: ' 44%' }}>
                        <div className="text-sm text-gray-900">{person.reason}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {moment(person.start_date).format('DD MMM YYYY')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> {moment(person.end_date).format('DD MMM YYYY')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> { moment(person.end_date).diff(moment(person.start_date), 'days') + 1} {person.half_day ? "(Half Day)" : ""}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(person.apply_date).format('DD MMM YYYY')}</td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Approve
                        </a>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </>
}
      {/* //Current */}
      {currentMonth.length>0 && <>
      <h1 style={{ color: 'white' }}> Current Month </h1>
      <div className="mt-3" style={{ width: ' 88%' }}>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" style={{
            padding: '61px',
            color: 'white'
          }}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Reason
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Till
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Days
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Date
                    </th>
                    {/* <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Status</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentMonth.length>0 && currentMonth.map((person,i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {/* <div className="flex-shrink-0 h-10 w-10">
                            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                          </div> */}
                          <div>
                            <div className="text-sm font-medium text-gray-900">{person.user.name}</div>
                            {/* <div className="text-sm text-gray-500">{person.email}</div> */}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: ' 44%' }}>
                        <div className="text-sm text-gray-900">{person.reason}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {moment(person.start_date).format('DD MMM YYYY')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> {moment(person.end_date).format('DD MMM YYYY')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> { moment(person.end_date).diff(moment(person.start_date), 'days') + 1} {person.half_day ? "(Half Day)" : ""}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(person.apply_date).format('DD MMM YYYY')}</td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Approve
                        </a>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </>
}
      {/* //Next */}
      {NextMonth.length>0 && <>
      <h1 style={{ color: 'white' }}> Next Month </h1>
      <div className="mt-3" style={{ width: ' 88%' }}>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" style={{
            padding: '61px',
            color: 'white'
          }}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Reason
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Till
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Days
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Applied Date
                    </th>
                    {/* <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Status</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {NextMonth.length>0 && NextMonth.map((person,i) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {/* <div className="flex-shrink-0 h-10 w-10">
                            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                          </div> */}
                          <div>
                            <div className="text-sm font-medium text-gray-900">{person.user.name}</div>
                            {/* <div className="text-sm text-gray-500">{person.email}</div> */}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ width: ' 44%' }}>
                        <div className="text-sm text-gray-900">{person.reason}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {moment(person.start_date).format('DD MMM YYYY')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> {moment(person.end_date).format('DD MMM YYYY')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> { moment(person.end_date).diff(moment(person.start_date), 'days') + 1} {person.half_day ? "(Half Day)" : ""}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(person.apply_date).format('DD MMM YYYY')}</td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Approve
                        </a>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </>
}

    </div>
  )
}
