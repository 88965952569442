

import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import * as axiosBase from 'axios';
import { Alert } from '@material-ui/lab';
import { ClickAwayListener, Tooltip, Snackbar } from '@material-ui/core';
import '../../screens/TeamWork/TeamWork.css';
import styles from './User.module.css';
import moment from 'moment';
import { useSearchParams, Link, useParams } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import PerformanceCharts from './PerformanceCharts';
import { GoPrimitiveDot } from 'react-icons/go';
import PulseLoader from 'react-spinners/PulseLoader';
import OwnerPerformance from './OwnerPerformance';
import { AiOutlineRight } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';

import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';

const token = localStorage.getItem('red_wing_token');
 // Auth to access api data
const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});



const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: 'fixed',
		width: '100%',
		height: '100vh',
		background: 'rgba(0,0,0,0.5)'
	},
	hide_heading: {
		display: 'none'
	},
	tabs: {
		color: "#FFFFFF",
		background: "#5FB924"
	}
}));

function User() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [ref, setRef] = useState(null);
	const [active, setActive] = useState('Monthly');
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);
	const [currentId, setCurrentId] = useState('');

	const classes = useStyles();
	const [loading, setLoading] = useState(true);

	const [users, setUsers] = useState(null);
	const [activeUser, setActiveUser] = useState(undefined)
	const [dots, setDots] = useState(null);

	const [weeklyIndex, setWeeklyIndex] = useState(null);
	const [monthlyIndex, setMonthlyIndex] = useState(null);
	const [weeklyData, setWeeklyData] = useState(null);
	const [weeklyChartData, setWeeklyChartData] = useState(null);

	const [weeklyDates, setWeeklyDates] = useState(null);
	const [monthlyData, setMonthlyData] = useState(null);
	const [monthlyChartData, setMonthlyChartData] = useState(null);
	const [monthlyDates, setMonthlyDates] = useState(null);

	// assignment  component
	const [tasks, setTasks] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [hideChilds, setHideChilds] = useState([]);
	const [totalEstimate, setTotalEstimate] = useState(0);

	const id = searchParams.get('userId');
	useEffect(() => {
		if (users) {
			if (!id) {
				setSearchParams({ userId: users[0]._id });
				//	setUserName(users[0].name)
			}
		}
	}, [users]);

	let designations = [
		'owner',
		'team_lead',
		'senior_developer',
		'contractor',
		'full_time_intern',
		'part_time_intern' ,
	];


	useEffect(() => {
		if (!id) return;
		// setLoading(true);
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/past_performance/${id}`)
			.then(res => {
				console.log({ res });
				setWeeklyData(res.data.weeks_data);
				setMonthlyData(res.data.months_data);
				//setUsers(res.data.users);

				let users = res.data.users;
				let newUsersArr = [];
				for (let i = 0; i <= designations.length - 1; i++) {
					users?.map(user => {
						if (user.role === designations[i]) {
							newUsersArr.push(user);
						}
					});
					if (i !== designations.length - 1) {
						newUsersArr.push('');
					}
				}
				setUsers(newUsersArr);


				// setting the user as active user 
				
				

				let weeklyArr = [];
				let weeklyDate = [];
				let weeklyEstimates = [];
				res.data.weeks_data.map(item => weeklyArr.push(item.weekly_completed_budget));
				res.data.weeks_data.map(item =>
					weeklyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				res.data.weeks_data.map(item => {
					if (item.estimates_completed) {
						weeklyEstimates.push(item.estimates_completed);
					}
				});
				if (weeklyEstimates.length === 0) {
					setWeeklyChartData({ weeklyArr, weeklyDate });
				} else {
					setWeeklyChartData({ weeklyArr, weeklyDate, weeklyEstimates });
				}
				//setWeeklyDates(weeklyDate);

				let monthlyArr = [];
				let monthlyDate = [];
				let monthlyEstimates = [];
				res.data.months_data.map(item => monthlyArr.push(item.monthly_completed_budget));
				res.data.months_data.map(item =>
					monthlyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				res.data.months_data.map(item => {
					if (item.estimates_completed) {
						monthlyEstimates.push(item.estimates_completed);
					}
				});
				if (monthlyEstimates.length === 0) {
					setMonthlyChartData({ monthlyArr, monthlyDate });
				} else {
					setMonthlyChartData({ monthlyArr, monthlyDate, monthlyEstimates });
				}
				// setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});


	}, [id]);

	// fetching dots values
	// useEffect(() => {
	// 	axios
	// 		.get(`${process.env.REACT_APP_API_URL}/pages/user_list_with_performance.php`, {
	// 			headers: {
	// 				Authorization: `Bearer ${token}`,
	// 				'Access-Control-Allow-Origin': '*'
	// 			}
	// 		})
	// 		.then(res => {
	// 			//console.log('dots', res.data.users);
	// 			setDots(res.data.users);
	// 		})
	// 		.catch(error => {
	// 			console.error(error);
	// 		});

	// }, []);

	// fetching users  and performance dot

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/users`)
			.then(res => {
				let users = res.data.users;
				let newUsersArr = [];
				for (let i = 0; i <= designations.length - 1; i++) {
					users?.map(user => {
						if (user.role === designations[i]) {
							newUsersArr.push(user);
						}
					});
					if (i !== designations.length - 1) {
						newUsersArr.push('');
					}
				}
				setUsers(newUsersArr);
				let userDetails = res.data.users.find((user) => {
					if (user._id == id) {
						return user
					}
				})
				setActiveUser(userDetails)
				getTodoList(userDetails._id)
			})
			.catch(error => {
				console.error(error);
			});
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/user_list_with_performance`)
			.then(res => {
				setDots(res.data.users);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);


	const upperEachLetter = sentense => {
		const desig =
			sentense.replaceAll('_', ' ').charAt(0).toUpperCase() +
			sentense.replaceAll('_', ' ').slice(1);
		const words = desig.split(' ');
		for (let i = 0; i < words.length; i++) {
			words[i] = words[i][0].toUpperCase() + words[i].substr(1);
		}

		return words.join(' ');
	};

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	const [tabValue, setTabValue] = useState("Assignment");

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
		// console.log('onchnag', tabValue);
		//	getTodoList(activeUser._id)

		// console.log(newValue)
	};
	const tabStyle = {
		// height:"30px",
		color: "#ffffff",
		padding: "0px 32px",
		margin: "0px",
		fontWeight: "500",
		// height:"30px",
		// maxHeight :"30px",
		"&.Mui-selected": {
			color: 'white', background: "#5FB924", fontWeight: "500",
			maxHeight: "30px", margin: "0px", height: "30px",
			"&.Mui-btton": {
				background: "blue",
				borderRadius: "3px"
			}
		}
	}


	function getTodoList(paramId) {
		setLoading(true);
		//	setTasks();
		//	setWorksheets();
		console.log('a user in todo', activeUser);

		const TASK_COMPLETION_URL =
			`${process.env.REACT_APP_LARAVEL_API_URL}/task/completed/today/${paramId ? paramId : id}`;
		const TASK_ASSIGNMENT_URL =
			`${process.env.REACT_APP_LARAVEL_API_URL}/task/assigned?user_id=${paramId ? paramId : id}`;

		const url = tabValue === "Assignment" ? TASK_ASSIGNMENT_URL : TASK_COMPLETION_URL
		fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`,
			}
		})
			.then(data => data.json())
			.then(data => {
				setTasks(data.tasks);
				setWorksheets(data.worksheets);
				setLoading(false);

			})
			.catch(e => console.log(e));
	};



	// useEffect(() => {
	// 	if (activeUser && tabValue === "Assignment" || tabValue === "Completion") {
	// 		getTodoList(activeUser._id);
	// 	}
	// 	console.log('un');
	// }, [tabValue]);


	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<div
				//className={styles.responsive1}
				style={{
					display: 'grid',
					gridTemplateColumns: '0.4fr 1.6fr',
					gap: '15px',
					width: '100%',
					height: '100vh',
					color: 'white',
					padding: '20px 30px'
				}}
			>
				<div style={{ width: '100%', color: 'white', height: '100%', overflowY: 'scroll' }}>
					{users &&
						designations.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div style={{ marginBottom: '10px' }}>{upperEachLetter(item)}</div>
									{users?.map((user, i) => {
										if (user.role === item) {
											return (
												<div
													style={{ display: 'flex', gap: '5px', marginBottom: '1vh' }}
													onClick={() => {
														setSearchParams({ userId: user._id });
														setTasks([])
														setWorksheets([])
													}}
													key={i}
												>
													<img
														src={user.avatar}
														style={{ width: '24px', height: '24px', borderRadius: '100%' }}
													/>
													<div>
														{/* {user.role && (
														<div style={{ fontSize: '9px', color: 'white' }}>
															{upperEachLetter(user.role)}
														</div>
													)} */}
														<div
															style={
																user._id === id
																	? {
																		//marginBottom: '1vh',
																		cursor: 'pointer',
																		color: 'white'
																		//display: 'flex',
																		//gap: '5px'
																	}
																	: {
																		//marginBottom: '1vh',
																		cursor: 'pointer',
																		color: 'grey'
																		//display: 'flex',
																		//gap: '5px'
																	}
															}
														>
															{user.name}
														</div>
														<div style={{ display: 'flex' }}>
															{dots ? (
																dots.map((x, i) => {
																	if (x.user_id === user.user_id) {
																		return (
																			<React.Fragment key={i}>
																				{/* // <div style={{ cursor: 'pointer' }} className='leavesCount'>
																			// 	<GoPrimitiveDot color={getColor(x.weekly_hours_percentage)} />
																			// 	<div className='LeavesCounttip'>
																			// 		<div>{moment(x.start_date).format('D MMM YYYY')}</div>
																			// 		<div>{moment(x.end_date).format('D MMM YYYY')}</div>
																			// 		<div>{x.weekly_hours_percentage}%</div>
																			// 	</div>
																			// </div> */}
																				{x.weekly_report.map((y, i) => {
																					return (
																						<div
																							style={{ cursor: 'pointer' }}
																							className='leavesCount'
																							key={i}
																						>
																							<GoPrimitiveDot
																								color={getColor(y.weekly_hours_percentage)}
																							/>
																							<div className='LeavesCounttip'>
																								<div>{moment(y.start_date).format('D MMM YYYY')}</div>
																								<div>{moment(y.end_date).format('D MMM YYYY')}</div>
																								<div>{y.weekly_hours_percentage}%</div>
																							</div>
																						</div>
																					);
																				})
																				}
																			</React.Fragment>
																		);
																	}
																})
															) : (
																<PulseLoader
																	color='#4FFFB0'
																	loading
																	size={6}
																	style={{ marginBottom: '5px' }}
																/>
															)}
														</div>
													</div>
												</div>
											);
										}
									})}
									{designations.length - 1 !== index && (
										<div style={{ height: '1px', background: 'grey', marginBottom: '10px' }}></div>
									)}
								</React.Fragment>
							);
						})}
				</div>



				<div style={{ height: '100%', overflowY: 'scroll', }}>
					<h1 style={{
						fontSize: "24px", color: "white", margin: "5px 0"
					}}>{activeUser?.name}</h1>
					<div style={{
						// height:"30px",
						position: "absolut", padding: "0px",
						//  background:'red',
						// maxHeight: "30px",
					}} 		>
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							variant="scrollable"
							scrollButtons={false}
							aria-label="scrollable prevent tabs example"
							sx={{
								// border :"4px solid red",	
								display: "flex", alignItems: "center",
								justifyContent: "center",
								// height:"30px",
								padding: "0px", margin: "0px",
								maxHeight: "30px",
								"&.MuiTabs-root": {
									maxHeight: "30px",
									padding: 1,
									"&.MuiTabs-scrollableX": {
										padding: 1,

									},
									"&.MuiTabs-hideScrollbar": {
										padding: 1,
									}
								}
							}}
							TabIndicatorProps={{ style: { background: '#5FB924' } }}
						>
							<Tab label="Assignment" value="Assignment" sx={tabStyle} />
							<Tab label="Completion" value="Completion" sx={tabStyle} />
							<Tab label="Weekly Performance" value="Weekly Performance" sx={tabStyle} />
							<Tab label="Monthly Performance" value="Monthly Performance" sx={tabStyle} />

						</Tabs>
					</div>
					{!loading && (
						// id === "33629907" ? <OwnerPerformance weeklyData={weeklyData} monthlyData={monthlyData} /> :
							<>
								<PerformanceCharts
									weeklyData={weeklyData}
									monthlyData={monthlyData}
									weeklyDates={weeklyDates}
									monthlyDates={monthlyDates}
									weeklyChartData={weeklyChartData}
									monthlyChartData={monthlyChartData}
									users={users}
									showWeeklyPerformance={tabValue === "Weekly Performance"}
									showMonthlyPerformance={tabValue === "Monthly Performance"}

								/>

								{(tabValue === "Completion" || tabValue === "Assignment" && activeUser && tasks.length > 0) ? (

									<div className={`main-content`}>
										{/* <h1 className={`page-title`}>{activeUser?.name && (`${activeUser.name}'s todays completion`)}</h1> */}
										<div style={{ zIndex: '10', width: '99%' }} className={styles.trans}>
											{tasks.length > 0 ?  (
												tasks
													.filter(function (item) {
														return item.parent_id === 0 || item.parent_id === '';
													})
													.sort((a, b) => a.seq - b.seq)
													.map((item, index) => {
														return (
															<ListItem
																key={index}
																item={item}
																index={index}
																tasks={tasks}
																hideChilds={hideChilds}
																setHideChilds={setHideChilds}
																activeUser={activeUser}
																worksheets={worksheets}
															/>
														);
													})) : (<h2>no data</h2>)}
										</div>
									</div>
								) : <></>}
							</>
					)}




				</div>
			</div>
		</>
	);
}

export default User;

const ListItem = ({
	index,
	item,
	tasks,
	hideChilds,
	setHideChilds,
	activeUser,
	worksheets,
	depth = 0
}) => {
 
	console.log("li");
	const filteredTodo = tasks
		.filter(function (childItem) {
			return childItem.parent_id === item._id;
		})
		.sort((a, b) => a.seq - b.seq);
	const parent = [...tasks].filter(function (value) {

		return value._id !== item.parent_id;

	})[0];
	return (
		<div className={styles.rowContainer} key={index}>
			{
				activeUser._id !== item.assginee && (
					<div>
						{filteredTodo.length > 0 && filteredTodo[0].assginee === activeUser._id &&
							<div>
								<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
							</div>
						}

						{!hideChilds.includes(item._id) &&
							filteredTodo.map((childItem, childIndex) => {
								var childPrevItem = tasks.filter(function (childItem) {
									return childItem.parent_id === item._id;
								})[childIndex === 0 ? 0 : childIndex - 1];
								return (
									<ListItem
										key={'child-' + childIndex}
										item={childItem}
										index={childIndex}
										tasks={tasks}
										depth={depth + 1}
										hideChilds={hideChilds}
										setHideChilds={setHideChilds}
										activeUser={activeUser}
										worksheets={worksheets}
									/>
								);
							})}
					</div>)
			}
			{
				(item.parent_id === 0 || item.parent_id === "") && activeUser._id === item.assginee &&
				<div>
					{filteredTodo.length > 0 && filteredTodo[0].assginee === activeUser._id &&
						<div>
							<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
						</div>
					}
				</div>
			}
			{activeUser._id === item.assginee &&
				<div
					className='d-flex'
					style={{
						width: '100%',
						marginLeft: item.parent_id === '' ? '0px' : '15px'
					}}
				>
					{filteredTodo.length > 0 && hideChilds.includes(item._id) && (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ width: '20px', cursor: 'pointer', marginLeft: '-20px', marginBottom: '5px' }}
						>
							<AiOutlineRight onClick={(e) => {
								setHideChilds((prev) => {
									return prev.filter(function (value) {
										return value !== item._id;
									});
								})
							}} />
						</div>
					)}

					{/* checkbox */}
					<div style={{ width: '20px' }}>
						<div className='d-flex justify-content-center'>
							{item.completed ? (
								<div
									style={{
										height: '18px',
										width: '18px',
										borderRadius: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										background: '#5FB924',
										cursor: 'pointer'
									}}
								>
									<FaCheck color='white' size={10} />
								</div>
							) : (
								<div
									style={{
										height: '18px',
										width: '18px',
										border: '1px solid #878787',
										borderRadius: '100%',
										cursor: 'pointer'
									}}
								></div>
							)}
						</div>
						{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
							<div
								className={`d-flex justify-content-center align-items-center ${styles.verticalLinesContainer}`}
								onClick={e => {
									setHideChilds(prev => [...prev, item._id]);
								}}
							>
								{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
									<div className={styles.verticalLines}></div>
								)}
							</div>
						)}
					</div>

					<div style={{ width: 'calc(100% - 30px)' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
								marginLeft: `10px`,
								marginBottom: '5px'
							}}
						>
							{/* estimate */}
							{(
								<div className='d-flex align-items-end' style={{}}>
									{item.estimate !== 0 ? (
										<div
											className='d-flex align-items-end'
											style={{
												// border: `1px solid ${
												// 	item.estimate <= 2 || filteredTodo.length > 0
												// 		? '#5FB924'
												// 		: item.estimate > 2 && item.estimate < 5
												// 		? '#FCBA03'
												// 		: '#FF0000'
												// }`,

												// textAlign: 'center',
												borderRadius: '12px',
												color: `${item.estimate <= 2 || filteredTodo.length > 0
													? '#5FB924'
													: item.estimate > 2 && item.estimate < 5
														? '#FCBA03'
														: '#FF0000'
													}`,
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : item.estimate !== 0 && item.completed ? (
										<div
											className='d-flex align-items-end'
											style={{
												// background: '#5FB924',

												// textAlign: 'center',
												// borderRadius: '12px',
												fontSize: '12px',
												lineHeight: '13px',
												// border: '1px solid #5FB924',
												padding: '1px 0',
												color: '#5FB924',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : (
										<div
											className='d-flex align-items-end'
											style={{
												// textAlign: 'center',
												borderRadius: '12px',
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												opacity: '60%',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>0.00</p>
										</div>
									)}
								</div>
							)}

							{/* input value */}
							<div style={{ position: 'relative', width: '100%' }}>
								<Link
									style={{
										background: 'transparent',
										color: 'white',
										border: 0,
										fontFamily: 'Poppins',
										fontSize: '13px',
										width: '100%',
										opacity: item.estimate === 0 && (filteredTodo.length === 0 || filteredTodo.map(value => value.estimate)
											.reduce((prev, next) => prev + next) === 0) ? '60%' : '100%'
									}}
									to={`/chopping-interface/${item.worksheet_id}?item_id=${item._id}`}
								>
									{item.title !== '' ? item.title : ''}
								</Link>

							</div>
							{/* {item.assginee !== 0 &&
													users &&
													users.map(user => {
															if (user.user_id === Number(item.assginee)) {
																	return <div style={{ color: '#7A8AED' }}>{`@${user.name}`}</div>;
															}
													})} */}
						</div>

						{!hideChilds.includes(item._id) &&
							filteredTodo.map((childItem, childIndex) => {
								var childPrevItem = tasks.filter(function (childItem) {
									return childItem.parent_id === item._id;
								})[childIndex === 0 ? 0 : childIndex - 1];
								return (
									<ListItem
										key={'child-' + childIndex}
										item={childItem}
										index={childIndex}
										tasks={tasks}
										depth={depth + 1}
										hideChilds={hideChilds}
										setHideChilds={setHideChilds}
										activeUser={activeUser}
										worksheets={worksheets}
									/>
								);
							})}
					</div>
				</div>
			}

		</div>
	)
}

// const WeeklyTableRow = ({
// 	start_date,
// 	end_date,
// 	performance_message,
// 	weekly_hours_percentage,
// 	absent_count,
// 	absent,
// 	low_output_days_count,
// 	low_output_days,
// 	extra_effort_days_count,
// 	extra_effort_days
// }) => {
// 	const [ref, setRef] = useState(null);
// 	const [openTooltip, setOpenTooltip] = useState(false);
// 	const [copySuccess, setCopySuccess] = useState(false);

// 	function copyToClip(str) {
// 		function listener(e) {
// 			e.clipboardData.setData('text/html', str);
// 			e.clipboardData.setData('text/plain', str);
// 			e.preventDefault();
// 		}
// 		document.addEventListener('copy', listener);
// 		document.execCommand('copy');
// 		document.removeEventListener('copy', listener);
// 	}

// 	const getColor = value => {
// 		if (value > 70) {
// 			return '#5FB924';
// 		} else if (value < 50) {
// 			return '#FF0000';
// 		} else if (value >= 50 && value <= 70) {
// 			return '#FCBA03';
// 		}
// 	};

// 	return (
// 		<>
// 			<Snackbar
// 				open={copySuccess}
// 				onClose={() => {
// 					setCopySuccess(false);
// 				}}
// 				autoHideDuration={2000}
// 			>
// 				<Alert severity='success'>Copied!</Alert>
// 			</Snackbar>
// 			<tr>
// 				<td>{moment(start_date).format('D MMM YYYY')}</td>
// 				<td>{moment(end_date).format('D MMM YYYY')}</td>
// 				<td>
// 					<Tooltip
// 						role={'button'}
// 						PopperProps={{
// 							disablePortal: true
// 						}}
// 						open={openTooltip}
// 						title={
// 							<ClickAwayListener
// 								onClickAway={() => {
// 									setTimeout(() => {
// 										setOpenTooltip(false);
// 									}, 100);
// 								}}
// 							>
// 								<div
// 									dangerouslySetInnerHTML={{
// 										__html: performance_message
// 									}}
// 									id={'_msg'}
// 									onClick={e => {
// 										copyToClip(performance_message);
// 										setCopySuccess(true);
// 										setOpenTooltip(false);
// 									}}
// 									ref={ref}
// 									style={{
// 										fontSize: 7,
// 										lineHeight: 'normal',
// 										textAlign: 'left',
// 										pointerEvents: 'all'
// 									}}
// 								></div>
// 							</ClickAwayListener>
// 						}
// 					>
// 						<div
// 							style={{
// 								color: getColor(weekly_hours_percentage),
// 								marginLeft: '6px'
// 							}}
// 							className='weekPerformance'
// 							onClick={() => {
// 								setOpenTooltip(true);
// 							}}
// 						>
// 							{weekly_hours_percentage !== 0 ? weekly_hours_percentage + '%' : '-'}
// 						</div>
// 					</Tooltip>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{absent_count}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{absent.map(each => {
// 							return <div>{each}</div>;
// 						})}
// 					</div>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{low_output_days_count}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{low_output_days.map(each => {
// 							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
// 						})}
// 					</div>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{extra_effort_days_count}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{extra_effort_days.map(each => {
// 							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
// 						})}
// 					</div>
// 				</td>
// 			</tr>
// 		</>
// 	);
// };

// const MonthlyTableRow = ({
// 	start_date,
// 	end_date,
// 	performance_message,
// 	monthly_hours_percentage,
// 	absent_count,
// 	absent,
// 	low_output_days_count,
// 	low_output_days,
// 	extra_effort_days_count,
// 	extra_effort_days
// }) => {
// 	const [ref, setRef] = useState(null);
// 	const [openTooltip, setOpenTooltip] = useState(false);
// 	const [copySuccess, setCopySuccess] = useState(false);

// 	function copyToClip(str) {
// 		function listener(e) {
// 			e.clipboardData.setData('text/html', str);
// 			e.clipboardData.setData('text/plain', str);
// 			e.preventDefault();
// 		}
// 		document.addEventListener('copy', listener);
// 		document.execCommand('copy');
// 		document.removeEventListener('copy', listener);
// 	}

// 	const getColor = value => {
// 		if (value > 70) {
// 			return '#5FB924';
// 		} else if (value < 50) {
// 			return '#FF0000';
// 		} else if (value >= 50 && value <= 70) {
// 			return '#FCBA03';
// 		}
// 	};

// 	return (
// 		<>
// 			<Snackbar
// 				open={copySuccess}
// 				onClose={() => {
// 					setCopySuccess(false);
// 				}}
// 				autoHideDuration={2000}
// 			>
// 				<Alert severity='success'>Copied!</Alert>
// 			</Snackbar>
// 			<tr>
// 				<td>{moment(start_date).format('D MMM YYYY')}</td>
// 				<td>{moment(end_date).format('D MMM YYYY')}</td>
// 				<td>
// 					<Tooltip
// 						role={'button'}
// 						PopperProps={{
// 							disablePortal: true
// 						}}
// 						open={openTooltip}
// 						title={
// 							<ClickAwayListener
// 								onClickAway={() => {
// 									setTimeout(() => {
// 										setOpenTooltip(false);
// 									}, 100);
// 								}}
// 							>
// 								<div
// 									dangerouslySetInnerHTML={{
// 										__html: performance_message
// 									}}
// 									id={'_msg'}
// 									onClick={e => {
// 										copyToClip(performance_message);
// 										setCopySuccess(true);
// 										setOpenTooltip(false);
// 									}}
// 									ref={ref}
// 									style={{
// 										fontSize: 7,
// 										lineHeight: 'normal',
// 										textAlign: 'left',
// 										pointerEvents: 'all'
// 									}}
// 								></div>
// 							</ClickAwayListener>
// 						}
// 					>
// 						<div
// 							style={{
// 								color: getColor(monthly_hours_percentage),
// 								marginLeft: '6px'
// 							}}
// 							className='weekPerformance'
// 							onClick={() => {
// 								setOpenTooltip(true);
// 							}}
// 						>
// 							{monthly_hours_percentage !== 0 ? monthly_hours_percentage + '%' : '-'}
// 						</div>
// 					</Tooltip>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{absent_count}{' '}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{absent.map(each => {
// 							return <div>{each}</div>;
// 						})}
// 					</div>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{low_output_days_count}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{low_output_days.map(each => {
// 							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
// 						})}
// 					</div>
// 				</td>
// 				<td style={{ cursor: 'pointer' }} className='absentCount'>
// 					{extra_effort_days_count}
// 					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
// 						{extra_effort_days.map(each => {
// 							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
// 						})}
// 					</div>
// 				</td>
// 			</tr>
// 		</>
// 	);
// };

