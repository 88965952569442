import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Box, Modal } from '@material-ui/core';
import { LoadingButton } from "@mui/lab";
import * as axiosBase from 'axios';
import Linkify from 'react-linkify';
import parse from 'html-react-parser';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import CommentIcon from "components/CommentIcon/CommentIcon";
import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';
import './deliverySheet.css';
import Wysiwyg from 'components/wysiwyg';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*'
    }
});
const parseOptions = {
    replace: node => {
        if (node.type === 'tag' && node.name === 'img') {
            let parent = node.parent;
            while (parent && parent.type !== 'tag') {
                parent = parent.parent;
            }
            if (
                parent &&
                parent.type === 'tag' &&
                parent.name === 'a' &&
                parent.attribs &&
                parent.attribs.href
            ) {
                node.attribs.src = parent.attribs.href;
                node.attribs.style = 'width: 100%; height: auto; object-fit: contain; max-width: 100%;';
            }
        }
        if (
            node.type === 'tag' &&
            node.attribs &&
            node.attribs.class &&
            node.attribs.class.includes('attachment--content')
        ) {
            node.attribs.style = 'display: inline-block; vertical-align: middle;';
        }
        return node;
    }
};

export const DeliverySheet = () => {
    let { pathname } = useLocation();
    const clientName = pathname.replace('/deliveries/', '');
    const tabValues = ['Pending', 'Approved'];
    const [deliveries, setDeliveries] = useState(null);
    const [tab, setTab] = useState(tabValues[0]);

    const [selectedWorksheetIndex, setSelectedWorksheetIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);
    const [taskList, setTaskList] = useState([]);
    const [expandedDelivery,setExpandedDelivery]=useState(null)


    const swiperRef = useRef(null);
    const expandRef=useRef();

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        if (windowSize.innerWidth <= 600) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowSize.innerWidth]);

    useEffect(() => {
        getAllDeliveries();
    }, []);

    const getAllDeliveries = () => {
        axios.get(`${process.env.REACT_APP_LARAVEL_API_URL}/client-message/${clientName}`)
            .then((res) => {
                
                const deliveryEntries = Object.entries(res.data.data);
                setDeliveries(deliveryEntries);
                console.log(res.data.data)
              
          


                let tasks = [];
                deliveryEntries[0][1]?.map((delivery) => {
                    tasks?.push(delivery?.task);
                });

                setTaskList(tasks);
            });
    }

    const formatClientName = () => {
        return clientName.replace(clientName.substring(0, 1), clientName.substring(0, 1).toUpperCase());
    }

    const goToDeliveriesSlide = () => {
        if (isMobile) {
            swiperRef.current.swiper.slideTo(1);
        }
    };

    const handleCopyReviewReminder = () => {
        let text = `Hey, can check the new deliveries at https://redwing.pages.dev/deliveries/${clientName} ? There are`

        deliveries?.map((deliveryArray, index) => {
            text += ` ${deliveryArray[1]?.length} new deliveries for ${deliveryArray[1][0]?.worksheet?.title}`;
            text += index !== deliveries.length - 1 ? `,` : `.`;
        });

        navigator.clipboard
            .writeText(text)
            .then(() => {
                setTimeout(() => toastr.success(`Message copied to clipboard.`), 300);
            })
            .catch(error => console.error('Error copying text: ', error));
    }

    return (
        <div className={`body_container worksheet_body`}>
            {isMobile ?
                (
                    <Swiper
                        ref={swiperRef}
                        onSlideChange={() => setSlideIndex(swiperRef.current.swiper.ActiveIndex)}
                        preventClicks={false}
                        preventClicksPropagation={false}
                        observer={true}
                        observeParents={true}
                    >
                        <SwiperSlide>
                            <SideBarDeliverySheet
                                deliveries={deliveries}
                                selectedWorksheetIndex={selectedWorksheetIndex}
                                setSelectedWorksheetIndex={setSelectedWorksheetIndex}
                                isMobile={isMobile}
                                goToDeliveriesSlide={goToDeliveriesSlide}
                                setExpandedDelivery={setExpandedDelivery}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="mx-auto deliveries_container">
                                <div className="page-header">
                                    <h1 className="page-title cursor-pointer" onClick={handleCopyReviewReminder}>{formatClientName()} Status Updates</h1>
                                    <div className="d-flex mt-4 text-muted">
                                        <span className="py-2 pr-2 cursor-pointer" style={{ color: tab === tabValues[0] ? 'white' : '' }} onClick={() => setTab(tabValues[0])}>Pending</span>
                                        <span className="p-2 cursor-pointer" style={{ color: tab === tabValues[1] ? 'white' : '' }} onClick={() => setTab(tabValues[1])}>Approved</span>
                                    </div>
                                </div>
                                <div className="overflow-auto pb-4" style={{ position: 'relative', top: '95px' }}>
                                    <TaskList deliveries={deliveries ? deliveries[selectedWorksheetIndex][1] : []} tab={tab} setDeliveries={setDeliveries} deliveryData={deliveries} isMobile={isMobile} expandedDelivery={expandedDelivery} expandRef={expandRef} setTab={setTab}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                ) :
                (
                    <>
                        <SideBarDeliverySheet
                            deliveries={deliveries}
                            selectedWorksheetIndex={selectedWorksheetIndex}
                            setSelectedWorksheetIndex={setSelectedWorksheetIndex}
                            isMobile={isMobile}
                            goToDeliveriesSlide={goToDeliveriesSlide}
                            setExpandedDelivery={setExpandedDelivery}
                        />
                        <div className="mx-auto deliveries_container">
                            <div className="page-header">
                                <h1 className="page-title cursor-pointer" onClick={handleCopyReviewReminder}>{formatClientName()} Status Updates</h1>
                                <div className="d-flex mt-4 text-muted">
                                    <span className="py-2 pr-2 cursor-pointer" style={{ color: tab === tabValues[0] ? 'white' : '' }} onClick={() => setTab(tabValues[0])}>Pending</span>
                                    <span className="p-2 cursor-pointer" style={{ color: tab === tabValues[1] ? 'white' : '' }} onClick={() => setTab(tabValues[1])}>Approved</span>
                                </div>
                            </div>
                            <div className="overflow-auto pb-4" style={{ position: 'relative', top: '95px' }}>
                                <TaskList deliveries={deliveries ? deliveries[selectedWorksheetIndex][1] : []} tab={tab} setDeliveries={setDeliveries} deliveryData={deliveries} isMobile={isMobile} expandedDelivery={expandedDelivery} expandRef={expandRef} setTab={setTab} />
                            </div>
                        </div>
                    </>
                )}
        </div>
    )
}

const SideBarDeliverySheet = ({ isMobile, deliveries, selectedWorksheetIndex, setSelectedWorksheetIndex, goToDeliveriesSlide, setExpandedDelivery }) => {
    const handleWorksheetSelection = (index) => {
        setSelectedWorksheetIndex(index);
        goToDeliveriesSlide();
    }
    const [recentDeliveries,setRecentDeliveries]=useState([])
    useEffect(()=>{
        let array=[];

        deliveries?.forEach((mainArray)=>{
            mainArray[1].forEach((item)=>{

                if(item.type==='delivery'){
                   
                    array.push(item)
                }
            })
        })
        array=array.sort((a,b)=>{
            const dateA=new Date(a.created_at)
            const dateB=new Date(b.created_at)
            return dateB-dateA;
        })
        
        setRecentDeliveries(array)
        
        
    
    },[deliveries])
   
    const handleAsyncState= async(index)=>{
       return  setSelectedWorksheetIndex(index)
    }
    const handleOpenDeliveryExpand=async(delivery)=>{
       
        const findIndex = deliveries.findIndex(
            (item) => item[1][0]?.worksheet._id === delivery?.worksheet._id
          );
        await  handleAsyncState(findIndex)
        setExpandedDelivery(delivery)

    }

    return (
        <div className={`${isMobile ? '' : 'worksheet_left_sidebar'}`} style={{ padding: '30px 15px', borderBottom: 'none' }}>
            <div className={`${isMobile ? '' : 'overlay_sidebar_notification'}`}></div>
            {deliveries?.map((deliveryArray, index) => {
                return (
                    <div key={index} className={`mb-4 text-normal ${index === selectedWorksheetIndex ? '' : 'text-muted'} cursor-pointer`} onClick={() => handleWorksheetSelection(index)}>
                        {deliveryArray[1][0]?.worksheet?.title} ({deliveryArray[1]?.length})
                    </div>
                );
            })}

<h1 className="page-title cursor-pointer">Recents</h1>
            <div className="recents">
                 {recentDeliveries.map((deliveryMessage)=>{
                    return <div className="recent-btn"
                    onClick={()=>{
                        handleOpenDeliveryExpand(deliveryMessage)
        
                    }}
                    >
                        <div style={{display:'flex',alignItems:'center'}} >
                        <CommentIcon type='approve'/>
                        </div>
                        <div>

                        <h5 className="recent-delivery-title">{deliveryMessage?.task?.core_title}</h5>
                        </div>

                    </div>
                 })}
                
            </div>
        </div>
    )
}

const TaskContainer = ({ i, j,setSelectionId,setApprovalModal, handleToggleCollapse, handleApprove, setRejectionId, setIsOpen, removeAssignee, parseText, collapseState, delivery, isMobile , mainTasks,expandRef}) => {
    return <div key={j} className={isMobile ? '' : 'px-2'}>
        <div className={`expandHover d-flex align-items-center delivery-head ${isMobile ? '' : 'mx-4'}`}>
            <div className="d-flex align-items-center" style={{ marginBottom: '0px' }}>
                <CommentIcon type={'approve'} />
            </div>
            <div className="d-flex text-normal align-items-center justify-content-between cursor-pointer" onClick={() => handleToggleCollapse(i, j)} style={{ margin: '0px 0px 0px 10px' }}>
                <div className="delivery-title">{delivery?.task?.core_title}</div>
                <div className="d-flex align-items-center ml-1">
                <div className="d-flex align-items-center">
                                                <ChatBubbleOutlineIcon
											style={{ cursor: 'pointer' }}
											onClick={e => {
												
											}}
										/>
									{' '}
									<p
										className='text-small my-0 mx-1 p-0'
										style={{
											fontSize: '10px',
											color:'#ffffff'
										}}
									>
										{mainTasks[delivery.task_id]?.length}
									</p>
                                    </div>
               
                    {(collapseState && collapseState[i][j] === true) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                </div>
            </div>
        </div>
        {(collapseState && collapseState[i][j] === true) && (
            <div ref={expandRef} className={`${isMobile ? '' : 'mx-4'} pt-2 delivery`}>
               {mainTasks[delivery.task_id].map((item)=><div  className="px-3 comment-box">{parseText(item.message)}</div>)}
                <div className="d-flex py-2 pt-2 px-3" style={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <div className="cursor-pointer approvalBtn"
                        onClick={() => {
                            // handleApprove(delivery._id)
                            setApprovalModal(true)
                            setSelectionId(delivery._id)

                        }}
                    >✅ Looks good</div>
                    <div className="ml-3 cursor-pointer approvalBtn"
                        onClick={() => {
                            setRejectionId(delivery._id)
                            setIsOpen(true)
                        }}>❌ Something's wrong</div>
                </div>
            </div>
        )}
    </div>
//     {(collapseState && collapseState[i][j] === true) && (
//         <div className={`${isMobile ? '' : 'mx-4'} pt-2 delivery`}>
//             {mainTasks[delivery.task_id].map((item)=><div className="px-3">{item.type==='feedback'&&'❌ '} {parseText(item.message)}</div>)}
//             {/* <div className="px-3">{parseText(delivery?.message)}</div> */}
//             <div className="d-flex py-2 mt-2 px-3" style={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
//                 <div className="cursor-pointer approvalBtn"
//                     onClick={() => {
//                         // handleApprove(delivery._id)
//                         setApprovalModal(true)
//                         setSelectionId(delivery._id)

//                     }}
//                 >✅ Looks good</div>
//                 <div className="ml-3 cursor-pointer approvalBtn"
//                     onClick={() => {
//                         setRejectionId(delivery._id)
//                         setIsOpen(true)
//                     }}>❌ Something's wrong</div>
//             </div>
//         </div>
//     )}
// </div>
}

const TaskList = ({ deliveries, tab, setDeliveries, deliveryData, isMobile,expandedDelivery,setTab,expandRef }) => {
    const [deliveryList, setDeliveryList] = useState(null);
    const [collapseState, setCollapsedState] = useState(null);
    const [fixed, setFixed] = useState(false);
    const [mainTasks,setMainTasks]=useState({});

    useEffect(() => {
        let deliveryByParent = {};

        deliveries?.map((delivery) => {
            if (!deliveryByParent[delivery?.task?.parent_id]) deliveryByParent[delivery?.task?.parent_id] = [];
            deliveryByParent[delivery?.task?.parent_id]?.push(delivery);
        });

        const deliveryEntries = Object.entries(deliveryByParent);
       

        setDeliveryList(deliveryEntries);
       
        let list={}

        deliveryEntries.map((item)=>{
            let mainArr=item[1]
             mainArr.forEach((content)=>{
                let taskId=content.task_id;
                list[taskId]=[];
                mainArr.forEach((del)=>{
                    if(del.task_id===taskId){
                        let arr=list[taskId]
                        arr=arr.concat(del);
                        list[taskId]=arr
                    }
                }) 

            })
        })
       
        setMainTasks(list)
    

        let collapseData = [];
        deliveryEntries?.map((deliveryArray) => {
            let temp = [];
            deliveryArray[1].map((delivery) => {
                temp.push(false);
            });
            collapseData.push(temp);
        });
        setCollapsedState(collapseData);
    }, [deliveries]);

    const handleExpand = (i, j) => {
        let collapseData = [...collapseState];
        collapseData[i][j] = true;
        setCollapsedState(collapseData);
    }

    useEffect(()=>{
        if(expandedDelivery !== null){
            const handleSearch=()=>{
                for(let i=0; i<deliveryList?.length; i++){
                    for(let j=0; j< deliveryList[i][1]?.length; j++){
                        if(deliveryList[i][1][j]?.task_id===expandedDelivery?.task_id){
                            if(deliveryList[i][1][j]?.is_approved){
                                setTab('Approved')
                            }else{
                                setTab('Pending')
                            }
                            handleExpand(i,j);
                            setTimeout(()=>{
                                    expandRef.current.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'end',
                                        inline: 'start'
                                    });
                               
                            },500)
                           
                            
                            break;
                        }
                    }
                }

            }
            handleSearch()
        }
      
    },[expandedDelivery])


    // const removeAssignee = (title) => {
    //     try {
    //         let lastIndex = title?.lastIndexOf('@');
    //         let end = lastIndex + 1, space = 0;
    //         if (lastIndex !== -1 && title?.substring(end, end + 1) === title?.substring(end, end + 1)?.toUpperCase()) {
    //             while (end < title?.length && space < 3 && !(title?.charAt(end - 1) === ' ' && title?.substring(end, end + 1) !== title?.substring(end, end + 1)?.toUpperCase())) {
    //                 end++;
    //                 if (title?.charAt(end - 1) === ' ') space++;
    //             }
    //         }
    //         return title?.substring(0, lastIndex !== -1 ? lastIndex : title?.length) + (end < title?.length && lastIndex !== -1 ? title?.substring(end, title?.length) : "");
    //     } catch {
    //         return title || '';
    //     }
    // }

    const parseText = text => {
        let temp_text = text?.replace(
            /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>/gi,
            `<a target="_blank" rel="noopener noreferrer"  href="$2" style="color: white;text-decoration:underline">`
        );

        return (
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a href={decoratedHref} key={key} target='_blank' style={{ color: 'white', textDecoration: "underline" }}>
                        {decoratedText}
                    </a>
                )}
                style={{ color: 'white', textDecoration: "underline" }}
            >
                {parse(temp_text, parseOptions)}
            </Linkify>
        );
    };

    const handleToggleCollapse = (i, j) => {
        let collapseData = [...collapseState];
        collapseData[i][j] = !collapseData[i][j];
        setCollapsedState(collapseData);
    }
    const [isOpen, setIsOpen] = useState(false);
    const [rejectionMessage, setRejectionMessage] = useState('');
    const [rejectionId, setRejectionId] = useState('');
    const [approvalModal,setApprovalModal]=useState(false)
    const [approvalMessage,setApprovalMessage]=useState('Looks Good');
    const [selectionId,setSelectionId]=useState('')

    const handleRejection = () => {
        if (rejectionMessage === '') {
            alert('Feedback is required ')
            return
        }
        const url = `/client-message/reject`;
        axios.post(`${process.env.REACT_APP_LARAVEL_API_URL}${url}`, {
            client_message_id: rejectionId,
            message: rejectionMessage
        }).then((res) => {
            if (res.data.success === true) {
                let newData = deliveryData[0][1].map((item) => {
                    if (item._id === rejectionId) {
                        let message = `${item.message} <br/> <div className='feedBackFlex'>❌ ${rejectionMessage}</div>`
                        return { ...item, isRejected: true, message: message }
                    } else {
                        return item
                    }
                });

                const newArr = [deliveryData[0][0], newData];
                setDeliveries([newArr])
                toastr.success('Rejected sucessfully');
                setIsOpen(false)
                setRejectionMessage("")
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    const setBtnLoading = () => {
    }

    const handleApprove = (id) => {
        
        const url = `/client-message/approve`;
        axios.post(`${process.env.REACT_APP_LARAVEL_API_URL}${url}`, {
            client_message_id: selectionId,
            message:approvalMessage
        }).then((res) => {
            if (res.data.success === true) {
                let newData = deliveryData[0][1].map((item) => {
                    if (item._id === id) {
                        return { ...item, is_approved: true }
                    } else {
                        return item
                    }
                });
                
                const newArr = [deliveryData[0][0], newData]
                setDeliveries([newArr])
                setApprovalMessage('Looks Good')
                setApprovalModal(false)
                toastr.success('Approved sucessfully')
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    const breadCrumbVisibility = (deliveries) => {
        return deliveries?.filter((delivery) =>
            (delivery.is_approved && tab === 'Approved') ||
            (!delivery.is_approved && tab === 'Pending' )
        )?.length > 0 ? true : false;
    }
    // useEffect(() => {
    //     if (deliveryData && !fixed) {


    //         for (let i = 0; i < deliveryData[0][1].length; i++) {
    //             let item = deliveryData[0][1][i]
    //             for (let j = 0; j < deliveryData[0][1].length; j++) {
    //                 let feedBack = deliveryData[0][1][j]
    //                 if (item.task_comment_id === feedBack.task_comment_id && feedBack.type === 'feedback' && feedBack.type !== 'approve' && item.type !== 'approve' ) {
    //                     let message = `${item.message} <br/> <div className='feedBackFlex'><div>❌</div> <div>${feedBack.message} </div> </div>`
    //                     deliveryData[0][1][i].message = message
    //                     deliveryData[0][1][i].isRejected = true;


    //                 }

    //             }
    //         }
    //         setFixed(true)

    //         // const newArr = [deliveryData[0][0], newData];
    //         // setDeliveries([newArr])
    //     }

    // }, [deliveryData])
    
    // useEffect(()=>{
    //     let list={}
    //     deliveryList?.map((d)=>{
    //         d[1].map((i)=>{
    //             list[i.task.parent_id]=i.task
                
    //         })
    //     })
    //     console.log(list)

    // },[deliveryList])

 

    return (
        <>
            {deliveryList?.map((deliveryByParent, i) => {
                let showBreadCrumb = breadCrumbVisibility(deliveryByParent[1]);
                const uniqueKeys=new Set()
                return (
                    <div>
                        {showBreadCrumb &&
                            (
                                <div className="pe-none">
                                    <DisplayBreadcrumbs item={deliveryByParent[1][0]?.task?.parent || deliveryByParent[1][0]?.task} tasks={deliveryByParent[1][0]?.parents}
                                        worksheets={[deliveryByParent[1][0]?.worksheet]} hideAssignee={true} />
                                </div>
                            )
                        }
                        {deliveryByParent[1]?.map((delivery, j) => {
                          

                            let lastCommentType=mainTasks[delivery.task_id]?.[mainTasks[delivery.task_id].length-1].type
                            if (!delivery.is_approved && tab === 'Pending' || lastCommentType==='feedback') {
                              let shouldShow= lastCommentType==='feedback' || lastCommentType==='delivery'
                                if(!uniqueKeys.has(delivery.task_id) && shouldShow){
                                    uniqueKeys.add(delivery.task_id)

                                return (
                                    <div key={j} className={isMobile ? '' : 'px-2'}>
                                        <div className={`expandHover d-flex align-items-center delivery-head ${isMobile ? '' : 'mx-4'}`}>
                                            <div className="d-flex align-items-center">
                                                {tab==='Pending'&& mainTasks[delivery.task_id]?.[mainTasks[delivery.task_id].length-1].type==='feedback'? '❌' : <CommentIcon type={'approve'} />}
                                            </div>
                                            <div className="d-flex text-normal align-items-center justify-content-between cursor-pointer" onClick={() => handleToggleCollapse(i, j)} style={{ margin: '0px 0px 0px 10px' }}>
                                                <div className="delivery-title">{delivery?.task?.core_title}</div>
                                                <div className="d-flex align-items-center ml-1">
                                                    <div className="d-flex align-items-center">
                                                <ChatBubbleOutlineIcon
											style={{ cursor: 'pointer' }}
											onClick={e => {
												
											}}
										/>
									{' '}
									<p
										className='text-small my-0 mx-1 p-0'
										style={{
											fontSize: '10px',
											color:'#ffffff'
										}}
									>
										{mainTasks[delivery.task_id]?.length}
									</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                                    {(collapseState && collapseState[i][j] === true) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {(collapseState && collapseState[i][j] === true) && (
                                            <div ref={expandRef} className={`${isMobile ? '' : 'mx-4'} pt-2 delivery`}>
                                                {mainTasks[delivery.task_id].map((item)=><div  className="px-3 comment-box">{parseText(item.message)}</div>)}
                                                {/* <div className="px-3">{parseText(delivery?.message)}</div> */}
                                                <div className="d-flex py-2 pt-2 px-3" style={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
                                                    <div className="cursor-pointer approvalBtn"
                                                        onClick={() => {
                                                            // handleApprove(delivery._id)
                                                            setApprovalModal(true)
                                                            setSelectionId(delivery._id)

                                                        }}
                                                    >✅ Looks good</div>
                                                    <div className="ml-3 cursor-pointer approvalBtn"
                                                        onClick={() => {
                                                            setRejectionId(delivery._id)
                                                            setIsOpen(true)
                                                        }}>❌ Something's wrong</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                                                    }
                            }

                            if (delivery.is_approved && tab === 'Approved') {
                                if(!uniqueKeys.has(delivery.task_id)){
                                    uniqueKeys.add(delivery.task_id)
                                    return (<>
                                        <TaskContainer
                                            i={i}
                                            j={j}
                                            handleToggleCollapse={handleToggleCollapse}
                                            handleApprove={handleApprove}
                                            setRejectionId={setRejectionId}
                                            setIsOpen={setIsOpen}
                                          //  removeAssignee={removeAssignee}
                                            parseText={parseText}
                                            delivery={delivery}
                                            collapseState={collapseState}
                                            isMobile={isMobile}
                                            mainTasks={mainTasks}
                                            setApprovalModal={setApprovalModal}
                                            setSelectionId={setSelectionId}
                                            expandRef={expandRef}
                                        />
                                    </>)
                                
                                }
                                
                           
                                // return (<>
                                //     <TaskContainer
                                //         i={i}
                                //         j={j}
                                //         handleToggleCollapse={handleToggleCollapse}
                                //         handleApprove={handleApprove}
                                //         setRejectionId={setRejectionId}
                                //         setIsOpen={setIsOpen}
                                //         // removeAssignee={removeAssignee}
                                //         parseText={parseText}
                                //         delivery={delivery}
                                //         collapseState={collapseState}
                                //         isMobile={isMobile}
                                //     />
                                // </>)
                            }
                        })}
                    </div>
                )
            })}
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(!isOpen)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className={`modal_container`}>
                    <Box className={`modal_header`}></Box>
                    <Box className={`modal_body`}>
                        <Wysiwyg
                            value={rejectionMessage}
                            onChange={html => setRejectionMessage(html)}
                            onSubmit={handleRejection}
                            setLoading={setBtnLoading}
                            btnLoading={true}
                            delivery={'delivery'}

                        />
                        <LoadingButton
                            onClick={() => {
                                handleRejection()
                            }}
                            loading={false}
                            loadingIndicator='Uploading…'
                            variant='contained'
                        >
                            Add Feedback
                        </LoadingButton>
                        <LoadingButton
                            variant='contained'
                            color='error'
                            className='ml-3'
                            onClick={() => {
                                setRejectionMessage('')
                                setIsOpen(false)
                            }}
                        >
                            Cancel
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
            {/* approval modal */}
            <Modal
                open={approvalModal}
                onClose={() => setApprovalModal(!approvalModal)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className={`modal_container`}>
                    <Box className={`modal_header`}></Box>
                    <Box className={`modal_body`}>
                        <Wysiwyg
                            value={approvalMessage}
                            onChange={html => setApprovalMessage(html)}
                            onSubmit={handleApprove}
                            setLoading={setBtnLoading}
                            btnLoading={true}
                            delivery={'delivery'}

                        />
                        <LoadingButton
                            onClick={() => {
                                handleApprove()
                            }}
                            loading={false}
                            loadingIndicator='Uploading…'
                            variant='contained'
                        >
                           Approve
                        </LoadingButton>
                        <LoadingButton
                            variant='contained'
                            color='error'
                            className='ml-3'
                            onClick={() => {
                                setApprovalMessage('Looks Good')
                                setApprovalModal(false)
                                
                            }}
                        >
                            Cancel
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}