import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import * as axiosBase from 'axios';
import { Alert } from '@material-ui/lab';
import { ClickAwayListener, Tooltip, Snackbar } from '@material-ui/core';
import '../../screens/TeamWork/TeamWork.css';
import styles from './Performance.module.css';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PerformanceCharts from './PerformanceCharts';
import { GoPrimitiveDot } from 'react-icons/go';
import PulseLoader from 'react-spinners/PulseLoader';
import OwnerPerformance from './OwnerPerformance';

const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: 'fixed',
		width: '100%',
		height: '100vh',
		background: 'rgba(0,0,0,0.5)'
	},
	hide_heading: {
		display: 'none'
	}
}));

function Performance() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [ref, setRef] = useState(null);
	const [active, setActive] = useState('Monthly');
	const [weeklyData, setWeeklyData] = useState(null);
	const [monthlyData, setMonthlyData] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);
	const [currentId, setCurrentId] = useState('');

	const classes = useStyles();
	const [loading, setLoading] = useState(true);

	const [users, setUsers] = useState(null);
	const [weeklyIndex, setWeeklyIndex] = useState(null);
	const [monthlyIndex, setMonthlyIndex] = useState(null);
	//const [userId, setUserId] = useState('');

	const [weeklyChartData, setWeeklyChartData] = useState(null);
	const [weeklyDates, setWeeklyDates] = useState(null);

	const [monthlyChartData, setMonthlyChartData] = useState(null);
	const [monthlyDates, setMonthlyDates] = useState(null);

	const [dots, setDots] = useState(null);

	const id = searchParams.get('userId');
	useEffect(() => {
		if (users) {
			if (!id) {
				setSearchParams({ userId: users[0]._id });
			}
		}
	}, [users]);

	let designations = [
		'owner',
		'team_lead',
		'senior_developer',
		'contractor',
		'full_time_intern',
		'part_time_intern'
	];

	useEffect(() => {
		if (!id) return;
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/past_performance/${id}`)
			.then(res => {
				console.log({ res });
				setWeeklyData(res.data.weeks_data);
				setMonthlyData(res.data.months_data);
				//setUsers(res.data.users);

				let users = res.data.users;
				let newUsersArr = [];
				// let designations = [
				// 	'owner',
				// 	'team_lead',
				// 	'senior_developer',
				// 	'contractor',
				// 	'senior_intern',
				// 	'intern'
				// ];
				for (let i = 0; i <= designations.length - 1; i++) {
					users?.map(user => {
						if (user.role === designations[i]) {
							newUsersArr.push(user);
						}
					});
					if (i !== designations.length - 1) {
						newUsersArr.push('');
					}
				}
				setUsers(newUsersArr);

				let weeklyArr = [];
				let weeklyDate = [];
				let weeklyEstimates = [];
				res.data.weeks_data.map(item => weeklyArr.push(item.weekly_completed_budget));
				res.data.weeks_data.map(item =>
					weeklyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				res.data.weeks_data.map(item => {
					if (item.estimates_completed) {
						weeklyEstimates.push(item.estimates_completed);
					}
				});
				if (weeklyEstimates.length === 0) {
					setWeeklyChartData({ weeklyArr, weeklyDate });
				} else {
					setWeeklyChartData({ weeklyArr, weeklyDate, weeklyEstimates });
				}
				//setWeeklyDates(weeklyDate);

				let monthlyArr = [];
				let monthlyDate = [];
				let monthlyEstimates = [];
				res.data.months_data.map(item => monthlyArr.push(item.monthly_completed_budget));
				res.data.months_data.map(item =>
					monthlyDate.push(
						`${moment(item.start_date).format('MMM D')} - ${moment(item.end_date).format('MMM D')}`
					)
				);
				res.data.months_data.map(item => {
					if (item.estimates_completed) {
						monthlyEstimates.push(item.estimates_completed);
					}
				});
				if (monthlyEstimates.length === 0) {
					setMonthlyChartData({ monthlyArr, monthlyDate });
				} else {
					setMonthlyChartData({ monthlyArr, monthlyDate, monthlyEstimates });
				}
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}, [id]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/users`)
			.then(res => {
				let users = res.data.users;
				let newUsersArr = [];
				for (let i = 0; i <= designations.length - 1; i++) {
					users?.map(user => {
						if (user.role === designations[i]) {
							newUsersArr.push(user);
						}
					});
					if (i !== designations.length - 1) {
						newUsersArr.push('');
					}
				}
				setUsers(newUsersArr);
			})
			.catch(error => {
				console.error(error);
			});
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/user_list_with_performance`)
			.then(res => {
				//console.log('dots', res.data.users);
				setDots(res.data.users);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	const upperEachLetter = sentense => {
		const desig =
			sentense.replaceAll('_', ' ').charAt(0).toUpperCase() +
			sentense.replaceAll('_', ' ').slice(1);
		const words = desig.split(' ');
		for (let i = 0; i < words.length; i++) {
			words[i] = words[i][0].toUpperCase() + words[i].substr(1);
		}

		return words.join(' ');
	};

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<div
				//className={styles.responsive1}
				style={{
					display: 'grid',
					gridTemplateColumns: '0.4fr 1.6fr',
					gap: '15px',
					width: '100%',
					height: '100vh',
					color: 'white',
					padding: '20px 30px'
				}}
			>
				<div style={{ width: '100%', color: 'white', height: '100%', overflowY: 'scroll' }}>
					{users &&
						designations.map((item, index) => {
							return (
								<>
									<div style={{ marginBottom: '10px' }}>{upperEachLetter(item)}</div>
									{users?.map(user => {
										if (user.role === item) {
											return (
												<div
													style={{ display: 'flex', gap: '5px', marginBottom: '1vh' }}
													onClick={() => {
														setSearchParams({ userId: user._id });
													}}
												>
													<img
														src={user.avatar}
														style={{ width: '24px', height: '24px', borderRadius: '100%' }}
													/>
													<div>
														{/* {user.role && (
														<div style={{ fontSize: '9px', color: 'white' }}>
															{upperEachLetter(user.role)}
														</div>
													)} */}
														<div
															style={
																user._id === id
																	? {
																			//marginBottom: '1vh',
																			cursor: 'pointer',
																			color: 'white'
																			//display: 'flex',
																			//gap: '5px'
																	  }
																	: {
																			//marginBottom: '1vh',
																			cursor: 'pointer',
																			color: 'grey'
																			//display: 'flex',
																			//gap: '5px'
																	  }
															}
														>
															{user.name}
														</div>
														<div style={{ display: 'flex' }}>
															{dots ? (
																dots.map(x => {
																	if (x._id === user._id) {
																		return (
																			// <div style={{ cursor: 'pointer' }} className='leavesCount'>
																			// 	<GoPrimitiveDot color={getColor(x.weekly_hours_percentage)} />
																			// 	<div className='LeavesCounttip'>
																			// 		<div>{moment(x.start_date).format('D MMM YYYY')}</div>
																			// 		<div>{moment(x.end_date).format('D MMM YYYY')}</div>
																			// 		<div>{x.weekly_hours_percentage}%</div>
																			// 	</div>
																			// </div>
																			x.weekly_report.map(y => {
																				return (
																					<div
																						style={{ cursor: 'pointer' }}
																						className='leavesCount'
																					>
																						<GoPrimitiveDot
																							color={getColor(y.weekly_hours_percentage)}
																						/>
																						<div className='LeavesCounttip'>
																							<div>{moment(y.start_date).format('D MMM YYYY')}</div>
																							<div>{moment(y.end_date).format('D MMM YYYY')}</div>
																							<div>{y.weekly_hours_percentage}%</div>
																						</div>
																					</div>
																				);
																			})
																		);
																	}
																})
															) : (
																<PulseLoader
																	color='#4FFFB0'
																	loading
																	size={6}
																	style={{ marginBottom: '5px' }}
																/>
															)}
														</div>
													</div>
												</div>
											);
										}
									})}
									{designations.length - 1 !== index && (
										<div style={{ height: '1px', background: 'grey', marginBottom: '10px' }}></div>
									)}
								</>
							);
						})}
				</div>
				<div style={{ height: '100%', overflowY: 'scroll' }}>
					{!loading && (
						id === "33629907" ? <OwnerPerformance weeklyData={weeklyData} monthlyData={monthlyData} /> :
						<PerformanceCharts
							weeklyData={weeklyData}
							monthlyData={monthlyData}
							weeklyDates={weeklyDates}
							monthlyDates={monthlyDates}
							weeklyChartData={weeklyChartData}
							monthlyChartData={monthlyChartData}
							users={users}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default Performance;

const WeeklyTableRow = ({
	start_date,
	end_date,
	performance_message,
	weekly_hours_percentage,
	absent_count,
	absent,
	low_output_days_count,
	low_output_days,
	extra_effort_days_count,
	extra_effort_days
}) => {
	const [ref, setRef] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);

	function copyToClip(str) {
		function listener(e) {
			e.clipboardData.setData('text/html', str);
			e.clipboardData.setData('text/plain', str);
			e.preventDefault();
		}
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	return (
		<>
			<Snackbar
				open={copySuccess}
				onClose={() => {
					setCopySuccess(false);
				}}
				autoHideDuration={2000}
			>
				<Alert severity='success'>Copied!</Alert>
			</Snackbar>
			<tr>
				<td>{moment(start_date).format('D MMM YYYY')}</td>
				<td>{moment(end_date).format('D MMM YYYY')}</td>
				<td>
					<Tooltip
						role={'button'}
						PopperProps={{
							disablePortal: true
						}}
						open={openTooltip}
						title={
							<ClickAwayListener
								onClickAway={() => {
									setTimeout(() => {
										setOpenTooltip(false);
									}, 100);
								}}
							>
								<div
									dangerouslySetInnerHTML={{
										__html: performance_message
									}}
									id={'_msg'}
									onClick={e => {
										copyToClip(performance_message);
										setCopySuccess(true);
										setOpenTooltip(false);
									}}
									ref={ref}
									style={{
										fontSize: 7,
										lineHeight: 'normal',
										textAlign: 'left',
										pointerEvents: 'all'
									}}
								></div>
							</ClickAwayListener>
						}
					>
						<div
							style={{
								color: getColor(weekly_hours_percentage),
								marginLeft: '6px'
							}}
							className='weekPerformance'
							onClick={() => {
								setOpenTooltip(true);
							}}
						>
							{weekly_hours_percentage !== 0 ? weekly_hours_percentage + '%' : '-'}
						</div>
					</Tooltip>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{absent_count}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{absent.map(each => {
							return <div>{each}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{low_output_days_count}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{low_output_days.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{extra_effort_days_count}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{extra_effort_days.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
			</tr>
		</>
	);
};

const MonthlyTableRow = ({
	start_date,
	end_date,
	performance_message,
	monthly_hours_percentage,
	absent_count,
	absent,
	low_output_days_count,
	low_output_days,
	extra_effort_days_count,
	extra_effort_days
}) => {
	const [ref, setRef] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);

	function copyToClip(str) {
		function listener(e) {
			e.clipboardData.setData('text/html', str);
			e.clipboardData.setData('text/plain', str);
			e.preventDefault();
		}
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	return (
		<>
			<Snackbar
				open={copySuccess}
				onClose={() => {
					setCopySuccess(false);
				}}
				autoHideDuration={2000}
			>
				<Alert severity='success'>Copied!</Alert>
			</Snackbar>
			<tr>
				<td>{moment(start_date).format('D MMM YYYY')}</td>
				<td>{moment(end_date).format('D MMM YYYY')}</td>
				<td>
					<Tooltip
						role={'button'}
						PopperProps={{
							disablePortal: true
						}}
						open={openTooltip}
						title={
							<ClickAwayListener
								onClickAway={() => {
									setTimeout(() => {
										setOpenTooltip(false);
									}, 100);
								}}
							>
								<div
									dangerouslySetInnerHTML={{
										__html: performance_message
									}}
									id={'_msg'}
									onClick={e => {
										copyToClip(performance_message);
										setCopySuccess(true);
										setOpenTooltip(false);
									}}
									ref={ref}
									style={{
										fontSize: 7,
										lineHeight: 'normal',
										textAlign: 'left',
										pointerEvents: 'all'
									}}
								></div>
							</ClickAwayListener>
						}
					>
						<div
							style={{
								color: getColor(monthly_hours_percentage),
								marginLeft: '6px'
							}}
							className='weekPerformance'
							onClick={() => {
								setOpenTooltip(true);
							}}
						>
							{monthly_hours_percentage !== 0 ? monthly_hours_percentage + '%' : '-'}
						</div>
					</Tooltip>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{absent_count}{' '}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{absent.map(each => {
							return <div>{each}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{low_output_days_count}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{low_output_days.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{extra_effort_days_count}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{extra_effort_days.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
			</tr>
		</>
	);
};
