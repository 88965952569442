import { Backdrop, Box, CircularProgress, Divider, SwipeableDrawer } from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import styles from './Notification.module.css';
import * as axiosBase from 'axios';
import { BlueFileTick, GreenCheck, RedBellIcon, MentionIcon } from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import { NavLink } from 'react-router-dom';
import TaskDetails from 'screens/ChoppingInterface/TaskDetails';
import moment from 'moment';
import CommentIcon from 'components/CommentIcon/CommentIcon';
import { FaCheck, FaClock, FaCross, FaTimes } from 'react-icons/fa';
import useCommentButtons from 'hooks/useCommentButtons';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { AiOutlineLink } from 'react-icons/ai';

// Core modules imports are same as usual
// Direct React component imports
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { GoClock } from 'react-icons/go';
import { ImCross } from 'react-icons/im';
import { mobileDateFormatter, mobileTimeFormatter } from 'utils/mobileTimeFormat';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';
import { fetchTaskDetailsInBulk } from 'api';
import TabSwitch from './components/TabSwitch';
import SegmentDivider from './components/SegmentDivider';
import Actioncontainer from './components/Actioncontainer';
import WorksheetLabelConatiner from './components/WorksheetLabelConatiner';
import AuthorContainer from './components/AuthorContainer';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import MarkReadButton from 'components/NotificationActionButtons/MarkReadButton';
import SaveLaterButton from 'components/NotificationActionButtons/SaveLaterButton';
import GradientBorder from 'components/GradientBorder/GradientBorder';
import AvatarWithAction from 'components/AvtarWithAction/AvatarWithAction';
import { Collapse, Grow } from '@mui/material';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

toastr.options = {
	positionClass: 'toast-top-center',
	zIndex: 66666,
	hideDuration: 300,
	timeOut: 5000
};
const useStyles = makeStyles(() => ({
	swiperContainer: {
		height: '100%',
		width: '100%',
		overflowY: 'scroll',
		position: 'relative'
	},
	backDropSpinner: {
		color: '#fff',
		backgroundColor: 'transparent',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 1000,
		opacity: 0.5,
		height: '100%',
		width: '100%'
	}
}));

const parseOptions = {
	replace: node => {
		if (node.type === 'tag' && node.name === 'img') {
			let parent = node.parent;
			while (parent && parent.type !== 'tag') {
				parent = parent.parent;
			}
			if (
				parent &&
				parent.type === 'tag' &&
				parent.name === 'a' &&
				parent.attribs &&
				parent.attribs.href
			) {
				node.attribs.src = parent.attribs.href;
				node.attribs.style =
					'width: 200px; height: auto; object-fit: contain; justify-self: center';
			}
		}
		if (
			node.type === 'tag' &&
			node.attribs &&
			node.attribs.class &&
			node.attribs.class.includes('attachment--content')
		) {
			node.attribs.style = 'display: inline-block; vertical-align: middle;';
		}
		return node;
	}
};

const Notifications = ({ setNotificationsCount }) => {
	const classes = useStyles();

	const [slideIndex, setSlideIndex] = useState(0);

	const swiperRef = useRef(null);

	const goToNotificationSlide = () => {
		if (isMobile) {
			swiperRef?.current?.swiper?.slideTo(1); // Go to slide 2
		}
	};
	const goToSideBaar = () => {
		if (isMobile) {
			swiperRef?.current?.swiper?.slideTo(0);
		}
	};

	const [tab, setTab] = useState('unread_notifications');
	const [loading, setLoading] = useState(false);
	const [allNotifications, setAllNotifications] = useState([]);
	const [notificationId, setNotificationId] = useState('');

	const [unreadNotifications, setUnreadNotifications] = useState([]);
	const [unreadMentionNotifications, setUnreadMentionNotifications] = useState([]);
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
	const [selectedTask, setSelectedTask] = useState(null);
	const [worksheetList, setWorksheetList] = useState([]);
	const [commentType, setCommentType] = useState('');
	const [worksheet, setWorksheet] = useState({});
	const [workSheetTitle, setWorkSheetTitle] = useState('');
	const [currentNotification, setCurrentNotification] = useState('');
	const [drawerWorksheetState, setDrawerWorksheetState] = useState(false);
	const [drawerStateActions, setDrawerStateActions] = useState(false);
	const [activeWorksheetInDrawer, setActiveWorksheetInDrawer] = useState('All worksheets');
	const [activeActionsInDrawer, setActiveActionsInDrawer] = useState('Notifications');
	const [unreadNotificationFetched, setUnreadNotificationFetched] = useState(false);
	const [savedNotifications, setSavedNotifications] = useState([]);
	const [hasNotifications, setHasNotifications] = useState(true);
	const [allCount, setAllCount] = useState(-1);
	const [commentButtons] = useCommentButtons();
	const [commentCounts, setCommentCounts] = useState({});
	const [tdpHeaderScroll, setTdpHeaderOnScroll] = useState(0);
	const [headerVisible, setHeaderVisible] = useState('0px');
	const [authors, setAuthors] = useState([]);
	const [authorsNotifications, setAuthorsNotifications] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [showNotificationCount, setShowNotificationCount] = useState(0);
	const [filteredNotificationCount, setfilteredNotificationCount] = useState(0);
	const [selectedActions, setSelectedActions] = useState([]);
	const [selectedWorksheets, setSelectedWorksheets] = useState([]);
	const [selectedAuthors, setSelectedAuthors] = useState([]);
	const [prefetchedTasks, setPrefetchedTasks] = useState({
		taskIds: [],
		taskById: []
	});
	const [currentFilterTab, setCurrentFilterTab] = useState('worksheet');
	const [fadingNotification, setFadingNotification] = useState('');
	const [hasMarkedAll, setMarkedAll] = useState(false);
	const [hasAnimationFinishedForMarkingAll, setAnimationFinishedForMarkingAll] = useState(false);

	const modalRef = useRef(null);
	const boxRef = useRef(null);

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	const [windowSize, setWindowSize] = useState(getWindowSize());

	const [isMobile, setIsMobile] = useState(false);
	const [worksheetListByClient, setWorksheetListByClient] = useState([]);

	const groupBy = (list, key) => {
		const map = {};
		list.forEach(item => {
			if (map[item[key]]) {
				map[item[key]].push(item);
			} else {
				map[item[key]] = [item];
			}
		});
		return map;
	};

	useEffect(() => {
		let groupedWorksheets = groupBy(worksheetList, 'client_name');
		setWorksheetListByClient(Object.entries(groupedWorksheets));
	}, [worksheetList]);

	useEffect(() => {
		if (windowSize.innerWidth <= 600) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [windowSize.innerWidth]);

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const handleOpenTaskDetailsModal = notification => {
		setSelectedTask(notification.data.task);
		setCurrentNotification(notification);
		setOpenTaskDetailsModal(true);
	};

	useEffect(() => {
		getWorksheetList();
		getUnreadMentions();
		getNotificationCount();
	}, []);

	useEffect(() => {
		getAllNotifications();
		getUnreadNotifications();
		getNotificationCount();
	}, [commentType, worksheet]);

	const getAllNotifications = () => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?${
			commentType ? 'comment_type=' + commentType : ''
		}${selectedUser._id ? '&author=' + selectedUser._id : ''}${
			worksheet._id ? '&worksheet=' + worksheet._id : ''
		}`;
		axios
			.get(url)
			.then(data => {
				setAllNotifications(data.data.data);
				setfilteredNotificationCount(data.data.data.length);
				// setShowNotificationCount(data.data.data.length);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};
	const getSavedForLaterNotifications = () => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=saved`;
		axios
			.get(url)
			.then(data => {
				console.log(data);
				setSavedNotifications(data.data.data);
				// setShowNotificationCount(data.data.data.length);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};
	useEffect(() => {
		getSavedForLaterNotifications();
	}, []);
	useEffect(() => {
		//* Run this effect as soon as the last notification finishes its animation when marking all
		if (hasAnimationFinishedForMarkingAll) {
			setAnimationFinishedForMarkingAll(false);
			let notification_ids = (
				tab === 'unread_mentions'
					? unreadMentionNotifications
					: tab === 'authors'
					? authorsNotifications
					: unreadNotifications
			)?.map(r => r._id);
			setAllNotifications(prev => {
				return prev.map(r => {
					if (notification_ids.includes(r._id)) {
						r.read_at = true;
					}
					return r;
				});
			});
			if (tab === 'authors') {
				setTab('unread_notifications');
			}

			if (tab === 'unread_mentions') {
				setUnreadMentionNotifications([]);
				setUnreadNotifications(prev => prev?.filter(r => !notification_ids.includes(r._id)));
				// setNotificationsCount(prev =>
				// 	unreadNotifications?.filter(r => !notification_ids.includes(r._id).length)
				// );
			} else {
				setUnreadNotifications([]);
				setUnreadMentionNotifications([]);
				setNotificationsCount(0);
			}
			setCommentCounts(prev => {
				return { ...prev, [commentType]: 0 };
			});

			const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/read-many`;
			axios
				.post(url, {
					notifications: notification_ids
				})
				.then(data => {
					if (data.data.success) {
						setTimeout(() => {
							toastr.success(`${notification_ids.length} Notifications marked as Read.`);
						}, 300);
						getNotificationCount();
						setWorkSheetTitle('All Worksheets');
						setWorksheet({});
					}
				})
				.catch(e => console.log(e))
				.finally(e => {
					setLoading(false);
					setMarkedAll(false);
					goToSideBaar();
				});
		}
	}, [hasAnimationFinishedForMarkingAll]);

	const getUnreadNotifications = () => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=unread${
			commentType ? '&comment_type=' + commentType : ''
		}${worksheet._id ? '&worksheet=' + worksheet._id : ''}`;
		axios
			.get(url)
			.then(data => {
				const notifications = data.data.data;
				setUnreadNotificationFetched(true);
				setUnreadNotifications(notifications);
				setPrefetchedTasks(prev => ({
					...prev,
					taskIds: notifications?.map(notif => notif?.data.task?._id)
				}));
				console.log(notifications);
				// setShowNotificationCount(notifications.length);
				setfilteredNotificationCount(notifications?.length);
				if (workSheetTitle === 'All Worksheets' && commentType === '')
					setNotificationsCount(notifications?.length);
				if (notifications.length > 0) {
					setHasNotifications(true);
				}
				if (notifications.length <= 0) {
					setHasNotifications(false);
				}
				const appearedUsers = {};
				const users = notifications.reduce((filteredUsers, unread) => {
					if (!appearedUsers[unread.data.sent_by?._id]) {
						appearedUsers[unread.data.sent_by?._id] = true;
						filteredUsers.push(unread.data?.sent_by);
					}
					return filteredUsers;
				}, []);
				if (authors.length === 0) {
					setAuthors(users);
				}
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};

	const getUnreadMentions = () => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/notification/mentions`, {
				params: { type: 'unread' }
			})
			.then(data => {
				const mentionNotifications = data.data.data;
				setUnreadMentionNotifications(mentionNotifications);
				setPrefetchedTasks(prev => ({
					...prev,
					taskIds: mentionNotifications?.map(notif => notif?.data.task?._id)
				}));
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};

	const prefetchTasks = async () => {
		try {
			const bulkResponse = await fetchTaskDetailsInBulk(prefetchedTasks?.taskIds);
			const { data: taskDetailsWithNewComments } = bulkResponse?.data;
			const idToTaskMap = {};
			taskDetailsWithNewComments.forEach(task => {
				idToTaskMap[task._id] = task;
			});
			setPrefetchedTasks(prev => ({
				...prev,
				taskById: idToTaskMap
			}));
		} catch (error) {}
	};

	const getNotificationCount = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/count-comments/unread`;
		axios
			.get(url)
			.then(data => {
				setAllCount(data.data.data.all);
				setCommentCounts(data.data.data);
			})
			.catch(e => console.log(e));
	};

	const markNotificationRead = notification_id => {
		// setLoading(true);
		setFadingNotification(notification_id);

		setTimeout(() => {
			setNotificationsCount(prev => {
				return prev - 1;
			});

			setUnreadNotifications(prev => {
				return prev.filter(r => r._id !== notification_id);
			});

			setUnreadMentionNotifications(prev => prev.filter(r => r._id !== notification_id));
			setAuthorsNotifications(prev => prev.filter(r => r._id !== notification_id));

			setAllNotifications(prev => {
				return prev.map(r => {
					if (r._id === notification_id) {
						r.read_at = true;
					}
					return r;
				});
			});
			setFadingNotification('');
		}, 500);

		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notification_id}/read`;
		axios
			.post(url)
			.then(data => {
				getNotificationCount();
			})
			.catch(e => console.log(e))
			.finally(e => {
				// setLoading(false);
				if (unreadNotifications.length === 1) {
					goToSideBaar();
				}
				if (tab === 'unread_mentions' && unreadMentionNotifications?.length === 1) {
					goToSideBaar();
				}
				if (tab === 'saved_notifications' && savedNotifications?.length === 1) {
					goToSideBaar();
				}
			});
	};

	const markNotificationUnread = notification_id => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notification_id}/unread`;
		axios
			.post(url)
			.then(data => {})
			.catch(e => console.log(e));
	};

	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
		markNotificationRead(notificationId);
		setNotificationId('');
	};

	const handleCloseAsUnread = () => {
		setOpenTaskDetailsModal(false);
		markNotificationUnread(currentNotification._id);
		setNotificationId('');
	};

	const markAllNotifications = () => {
		//* if theres no notification left this method will not be invoked
		if (!unreadMentionNotifications.length && !unreadNotifications.length) return;
		// setLoading(true);
		//* this state will start the animation
		setMarkedAll(true);
	};

	const getAuthorNotifications = id => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=unread&author=${id} ${
			commentType ? `&comment_type=${commentType}` : ''
		}${worksheet?._id ? '&worksheet=' + worksheet._id : ''}`;
		axios
			.get(url)
			.then(data => {
				setAuthorsNotifications(data.data.data);
				setfilteredNotificationCount(data.data.data.length);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};

	const filterAuthors = type => {
		if (authorsNotifications.length > 0 || tab === 'authors') {
			goToNotificationSlide();
			setLoading(true);
			const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=unread&author=${
				selectedUser._id
			}&comment_type=${type}${worksheet._id ? '&worksheet=' + worksheet._id : ''}`;
			axios
				.get(url)
				.then(data => {
					setAuthorsNotifications(data.data.data);
					setfilteredNotificationCount(data.data.data.length);
					console.log(filteredNotificationCount);
				})
				.catch(e => console.log(e))
				.finally(e => {
					setLoading(false);
				});
		}
	};

	const filterAuthorsBasedOnWorksheet = worksheetId => {
		if (authorsNotifications.length > 0 || tab === 'authors') {
			goToNotificationSlide();
			setLoading(true);
			const url = `${
				process.env.REACT_APP_LARAVEL_API_URL
			}/notification?type=unread&worksheet=${worksheetId}&author=${selectedUser._id}${
				commentType ? '&comment_type=' + commentType : ''
			}`;
			axios
				.get(url)
				.then(data => {
					setAuthorsNotifications(data.data.data);
					setfilteredNotificationCount(data.data.data.length);
					console.log(data.data.data);
				})
				.catch(e => console.log(e))
				.finally(e => {
					setLoading(false);
				});
		}
	};

	let timeout;
	const handleHeaderScroll = e => {
		const container = e.target;
		console.log('i am running');
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			const scrollTop = container.scrollTop;

			if (scrollTop === 0) {
				setHeaderVisible('0px');
			} else if (scrollTop < tdpHeaderScroll) {
				setHeaderVisible('0px');
			} else {
				setHeaderVisible('-50px');
			}

			setTdpHeaderOnScroll(scrollTop);
		}, 100);
	};

	const getWorksheetList = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
		axios
			.get(url)
			.then(data => {
				// console.log('work sheet',data.worksheet);
				setWorksheetList(data.data.worksheets);
			})
			.catch(e => console.log(e));
	};

	useEffect(() => {
		const handleKeyDown = event => {
			console.log('key pressed');
			if (event.ctrlKey && event.key === 'ArrowRight' && event.target === modalRef.current) {
				console.log('event change');
				event.preventDefault();

				openNextNotification();
			}
		};
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [unreadNotifications, allNotifications, currentNotification, modalRef]);

	const openNextNotification = () => {
		markNotificationRead(currentNotification?._id);

		if (tab === 'unread_notifications') {
			var curr_notification_index = unreadNotifications.findIndex(
				r => r.id === currentNotification?.id
			);
			var notification = unreadNotifications[curr_notification_index + 1];
		} else {
			var curr_notification_index = allNotifications.findIndex(
				r => r.id === currentNotification?.id
			);
			var notification = allNotifications[curr_notification_index + 1];
		}

		if (typeof notification === undefined) {
			setSelectedTask(null);
			setCurrentNotification(null);
			setNotificationId(null);
			setOpenTaskDetailsModal(false);
		} else {
			setSelectedTask(notification?.data?.task);
			setCurrentNotification(notification);
			setNotificationId(notification?._id);
		}

		//console.log('next',prefetchedTasks.taskById[notification?.data?.task?._id])

		// if(!notification.read_at){
		//     markNotificationRead(notification._id);
		// }
	};

	useEffect(() => {
		setDrawerStateActions(false);
	}, [activeActionsInDrawer]);

	useEffect(() => {
		setDrawerWorksheetState(false);
	}, [activeWorksheetInDrawer]);

	useEffect(() => {
		getNotificationCount();
	}, [notificationId]);

	useEffect(() => {
		if (prefetchedTasks.taskIds.length) {
			prefetchTasks();
		}
	}, [prefetchedTasks.taskIds.length]);

	const saveForLater = (notificationId, type) => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notificationId}/save-for-later`;
		axios
			.post(url, {
				status: true
			})
			.then(data => {
				toastr.success('saved for later');
				if (type === 'unread_notifications') {
					getUnreadNotifications();
				}
				if (type === 'unread_mentions') {
					getUnreadMentions();
				}
				getSavedForLaterNotifications();
				setLoading(false);
			})
			.catch(e => console.log(e))
			.finally(e => {
				// setLoading(false);
			});
	};
	const removeFromSave = (notificationId, type) => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notificationId}/save-for-later`;
		axios
			.post(url, {
				status: false
			})
			.then(data => {
				toastr.success('Removed Sucessfully');
				if (type === 'unread_notifications') {
					getUnreadNotifications();
				}
				if (type === 'unread_mentions') {
					getUnreadMentions();
				}
				getSavedForLaterNotifications();
				setLoading(false);
			})
			.catch(e => console.log(e))
			.finally(e => {
				// setLoading(false);
				if (tab === 'saved_notifications' && savedNotifications?.length === 1) {
					goToSideBaar();
				}
			});
	};

	const handleUpdatePrefetchedTask = (taskId, data) => {
		setPrefetchedTasks(prev => {
			prev.taskById = {
				...prev.taskById,
				[taskId]: {
					...prev.taskById[taskId],
					...data
				}
			};
			return prev;
		});
	};

	if (unreadNotificationFetched) {
		if (
			allCount === 0 &&
			unreadNotifications.length === 0 &&
			commentCounts['mentions'] === 0 &&
			commentCounts['followup'] === 0 &&
			commentCounts['approve'] === 0 &&
			commentCounts['submission'] === 0 &&
			commentCounts['escalation'] === 0 &&
			commentCounts['mentions'] === 0 &&
			savedNotifications.length === 0
		) {
			return (
				<div className={styles['all-caught-up-section']}>
					<h3 style={{ color: 'white', fontSize: '30px' }}>All Caught up</h3>
					<p>Take a break, go drink coffee or walk around.</p>
				</div>
			);
		}
	}

	return (
		<div className={`body_container worksheet_body`}>
			{/*Slide view for mobile */}
			{isMobile ? (
				<Box className={classes.swiperContainer}>
					<Swiper
						ref={swiperRef}
						// onSwiper={swiper => console.log(swiper)}
						onSlideChange={() => setSlideIndex(swiperRef.current.swiper.ActiveIndex)}
						preventClicks={false}
						preventClicksPropagation={false}
						observer={true}
						observeParents={true}
					>
						<SwiperSlide>
							{/* Content for the first slide */}
							<div style={{ height: '100%', width: '100%', zIndex: '9999', overflowY: 'auto' }}>
								{SideBarNotification({
									unreadNotifications,
									commentCounts,
									commentType,
									setCommentType,
									setTab,
									tab,
									commentButtons,
									worksheet,
									setWorksheet,
									worksheetList,
									workSheetTitle,
									setWorkSheetTitle,
									isMobile,
									goToNotificationSlide,
									savedNotifications,
									setWorksheetListByClient,
									worksheetListByClient,
									setActiveActionsInDrawer,
									removeFromSave,
									authors,
									selectedUser,
									setSelectedUser,
									getAuthorNotifications,
									authorsNotifications,
									setAuthorsNotifications,
									filterAuthors,
									filterAuthorsBasedOnWorksheet,
									showNotificationCount,
									selectedActions,
									setSelectedActions,
									selectedWorksheets,
									setSelectedWorksheets,
									selectedAuthors,
									setSelectedAuthors,
									currentFilterTab,
									setCurrentFilterTab
								})}
							</div>
						</SwiperSlide>
						<SwiperSlide>
							{/* Content for the second slide */}
							<div style={{ height: '100%', width: '100%', zIndex: '9999', overflowY: 'auto' }}>
								{MainContentNotification({
									filteredNotificationCount,
									loading,
									commentCounts,
									setDrawerWorksheetState,
									activeWorksheetInDrawer,
									setDrawerStateActions,
									activeActionsInDrawer,
									tab,
									setTab,
									markAllNotifications,
									getNotificationCount,
									setNotificationId,
									allNotifications,
									markNotificationRead,
									handleOpenTaskDetailsModal,
									unreadMentionNotifications,
									openTaskDetailsModal,
									handleCloseTaskDetailsModal,
									modalRef,
									handleCloseAsUnread,
									currentNotification,
									selectedTask,
									openNextNotification,
									workSheetTitle,
									setUnreadNotifications,
									unreadNotifications,
									getUnreadNotifications,
									saveForLater,
									savedNotifications,
									removeFromSave,
									isMobile,
									tdpHeaderScroll,
									setTdpHeaderOnScroll,
									headerVisible,
									setHeaderVisible,
									boxRef,
									handleHeaderScroll,
									selectedUser,
									authorsNotifications,
									setAuthorsNotifications,
									commentType,
									worksheet,
									prefetchedTasks,
									handleUpdatePrefetchedTask,
									classes,
									fadingNotification,
									hasMarkedAll,
									setAnimationFinishedForMarkingAll
								})}
							</div>
						</SwiperSlide>
					</Swiper>
				</Box>
			) : (
				<>
					{SideBarNotification({
						unreadNotifications,
						commentCounts,
						commentType,
						setCommentType,
						setTab,
						tab,
						commentButtons,
						worksheet,
						setWorksheet,
						worksheetList,
						workSheetTitle,
						setWorkSheetTitle,
						isMobile,
						goToNotificationSlide,
						savedNotifications,
						setWorksheetListByClient,
						worksheetListByClient,
						setActiveActionsInDrawer,
						removeFromSave,
						authors,
						selectedUser,
						setSelectedUser,
						getAuthorNotifications,
						authorsNotifications,
						setAuthorsNotifications,
						filterAuthors,
						filterAuthorsBasedOnWorksheet,
						showNotificationCount,
						selectedActions,
						setSelectedActions,
						selectedWorksheets,
						setSelectedWorksheets,
						selectedAuthors,
						setSelectedAuthors,
						currentFilterTab,
						setCurrentFilterTab
					})}

					{MainContentNotification({
						filteredNotificationCount,
						loading,
						commentCounts,
						setDrawerWorksheetState,
						activeWorksheetInDrawer,
						setDrawerStateActions,
						activeActionsInDrawer,
						tab,
						setTab,
						markAllNotifications,
						unreadNotifications,
						getNotificationCount,
						setNotificationId,
						allNotifications,
						markNotificationRead,
						handleOpenTaskDetailsModal,
						unreadMentionNotifications,
						openTaskDetailsModal,
						handleCloseTaskDetailsModal,
						modalRef,
						handleCloseAsUnread,
						currentNotification,
						selectedTask,
						openNextNotification,
						workSheetTitle,
						setUnreadMentionNotifications,
						getUnreadNotifications,
						saveForLater,
						savedNotifications,
						removeFromSave,
						isMobile,
						tdpHeaderScroll,
						setTdpHeaderOnScroll,
						headerVisible,
						setHeaderVisible,
						boxRef,
						handleHeaderScroll,
						selectedUser,
						authorsNotifications,
						setAuthorsNotifications,
						commentType,
						worksheet,
						prefetchedTasks,
						handleUpdatePrefetchedTask,
						classes,
						fadingNotification,
						hasMarkedAll,
						setAnimationFinishedForMarkingAll
					})}
				</>
			)}

			{/* <div className={'worksheet_left_sidebar'}> */}
			<SwipeableDrawer
				open={drawerStateActions}
				onClose={() => setDrawerStateActions(false)}
				color='inherit'
				anchor='bottom'
			>
				<div className={`my-4 text-normal d-flex align-items-center`}>
					<div style={{ marginLeft: '10px' }}>
						<div
							className={styles.drawerButtons}
							style={{ cursor: 'pointer' }}
							onClick={e => {
								markAllNotifications();
							}}
						>
							Mark All
						</div>
					</div>
				</div>

				{commentCounts['all'] > 0 && (
					<div className={`my-4 text-normal d-flex align-items-center`}>
						<div className='flex' style={{ width: '30px', marginRight: '10px' }}>
							<RedBellIcon className='me-2' style={{ width: '22px', height: '22px' }} />
						</div>{' '}
						<div>
							<NavLink
								className={`${commentType === '' ? 'text-normal' : 'text-muted'} `}
								to={`/notifications`}
								onClick={e => {
									e.preventDefault();
									setCommentType('');
									setTab('unread_notifications');
									setActiveActionsInDrawer('Notifications');
									// setWorkSheetTitle(`${unreadNotifications.length} Notifications`)
								}}
							>
								{commentCounts['all']} Notifications{' '}
							</NavLink>
						</div>
					</div>
				)}

				{commentCounts['mentions'] > 0 && (
					<div className={`my-5 text-normal d-flex align-items-center`}>
						<div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
							<MentionIcon className='me-2' style={{ width: '22px', height: '22px' }} />
						</div>
						<div>
							<NavLink
								className={`${tab === 'unread_mentions' ? 'text-normal' : 'text-muted'}`}
								to={`/notifications`}
								onClick={e => {
									e.preventDefault();
									setCommentType('');
									setTab('unread_mentions');
									setActiveActionsInDrawer('Mentions');
								}}
							>
								{commentCounts['mentions']} Mentions{' '}
							</NavLink>
						</div>
					</div>
				)}

				{commentButtons?.map((type, index) => {
					return (
						commentCounts[type.key] > 0 && (
							<div className={`my-5 text-normal d-flex align-items-center`} key={index}>
								<div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
									{type.icon}
								</div>
								<div>
									<NavLink
										className={`${commentType === type.key ? 'text-normal' : 'text-muted'}`}
										to={`/notifications`}
										onClick={e => {
											e.preventDefault();

											setCommentType(type.key);
											setTab('unread_notifications');
											setActiveActionsInDrawer(type.label);
										}}
									>
										{commentCounts[type.key]} {type.label}
									</NavLink>
								</div>
							</div>
						)
					);
				})}
			</SwipeableDrawer>

			<SwipeableDrawer
				open={drawerWorksheetState}
				onClose={() => setDrawerWorksheetState(false)}
				color='inherit'
				anchor='bottom'
			>
				<div className={`my-4 text-normal d-flex align-items-center`}>
					<div style={{ marginLeft: '10px' }}>
						<NavLink
							className={`${worksheet.title ? 'text-normal' : 'text-muted'}`}
							to={`/notifications`}
							onClick={e => {
								e.preventDefault();
								setWorksheet({});
								setActiveWorksheetInDrawer('All worksheets');
								setDrawerWorksheetState(false);
							}}
						>
							{commentCounts['all']} All Worksheets
						</NavLink>
					</div>
				</div>
				{worksheetList
					.sort((a, b) => a.seq - b.seq)
					?.map((sheet, index) => {
						if (
							commentCounts['worksheets'] &&
							commentCounts['worksheets'][sheet._id] &&
							commentCounts['worksheets'][sheet._id] > 0
						) {
							return (
								<div className={`my-5 text-normal d-flex align-items-center`} key={sheet._id}>
									<div style={{ marginLeft: '10px' }} className='d-flex align-items-center'>
										<NavLink
											style={{ whiteSpace: 'nowrap' }}
											className={`${worksheet._id === sheet._id ? 'text-normal' : 'text-muted'}`}
											to={`/notifications`}
											onClick={e => {
												e.preventDefault();
												setWorksheet(sheet);
												setActiveWorksheetInDrawer(sheet.title);
											}}
										>
											{commentCounts['worksheets'][sheet._id]} {sheet.title}
										</NavLink>
										<NavLink
											className={`${
												worksheet._id === sheet._id ? 'text-normal' : 'text-muted'
											} ml-2`}
											to={`/chopping-interface/${sheet._id}`}
											target='_blank'
										>
											<AiOutlineLink className='text-normal mb-0' />
										</NavLink>
									</div>
								</div>
							);
						} else {
							return <></>;
						}
					})}

				{/* </div> */}
			</SwipeableDrawer>
		</div>
	);
};

const SideBarNotification = ({
	unreadNotifications,
	commentCounts,
	commentType,
	setCommentType,
	setTab,
	tab,
	commentButtons,
	worksheet,
	setWorksheet,
	worksheetList,
	setWorkSheetTitle,
	isMobile,
	goToNotificationSlide,
	savedNotifications,
	setWorksheetListByClient,
	worksheetListByClient,
	setActiveActionsInDrawer,
	removeFromSave,
	authors,
	selectedUser,
	setSelectedUser,
	getAuthorNotifications,
	authorsNotifications,
	setAuthorsNotifications,
	filterAuthors,
	filterAuthorsBasedOnWorksheet,
	showNotificationCount,
	selectedActions,
	setSelectedActions,
	selectedWorksheets,
	setSelectedWorksheets,
	selectedAuthors,
	setSelectedAuthors,
	currentFilterTab,
	setCurrentFilterTab
}) => {
	// const [currentFilterTab, setCurrentFilterTab] = useState('worksheet')
	const userIDs = unreadNotifications?.map(item => item.data?.comment?.user_id);

	const commentCountByAuthorId = authorId => {
		return unreadNotifications?.filter(notif => notif.data.sent_by._id === authorId)?.length || 0;
	};
	const getGroupHasComment = sheets => {
		let hasComments = false;
		sheets?.map(sheet => {
			if (commentCounts?.worksheets) {
				if (commentCounts?.worksheets[sheet?._id]) {
					hasComments = true;
				}
			}
		});

		return hasComments;
	};
	const filterTabs = {
		all: 'all',
		worksheet: 'worksheet',
		action: 'action',
		users: 'users'
	};
	const changeFilterTab = tabName => {
		setCurrentFilterTab(tabName);
	};
	const getActionsCount = () => {
		if (commentCounts) {
			let count = 0;
			for (let key in commentCounts) {
				if (commentCounts[key] > 0) {
					count += 1;
				}
			}
			return count;
		}
	};
	const getWorksheetCount = () => {
		if (commentCounts) {
			let count = 0;
			if (commentCounts.worksheets) {
				count = Object.keys(commentCounts?.worksheets).length;
			}
			if (count > 0) {
				return count;
			} else {
				return 0;
			}
		}
	};

	return (
		<div
			style={{ position: 'relative', overflowY: 'auto', background: '#111111' }}
			className={`${
				isMobile ? 'worksheet_content' : `worksheet_left_sidebar  ${styles.sidebarScreen}`
			}`}
		>
			<div
				className='tabs-container'
				style={{
					padding: '16px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					position: 'sticky',
					top: 0,
					background: '#111111',
					zIndex: '5'
				}}
			>
				<TabSwitch
					tabname={'Worksheets'}
					changeFilterTab={() => changeFilterTab(filterTabs.worksheet)}
					isActive={currentFilterTab === filterTabs.worksheet}
					count={getWorksheetCount()}
				/>
				<TabSwitch
					tabname={'Actions'}
					changeFilterTab={() => changeFilterTab(filterTabs.action)}
					isActive={currentFilterTab === filterTabs.action}
					count={getActionsCount()}
				/>
				<TabSwitch
					tabname={'Users'}
					changeFilterTab={() => changeFilterTab(filterTabs.users)}
					isActive={currentFilterTab === filterTabs.users}
					count={authors?.length}
				/>
			</div>

			<div className={`${isMobile ? '' : 'overlay_sidebar_notification'}`}></div>
			{currentFilterTab === filterTabs.all && (
				<>
					{commentCounts['all'] > 0 && (
						<NavLink
							className={`${styles.sidebarItem} ${commentType === '' ? 'text-normal' : ''}`}
							to={`/notifications`}
							onClick={e => {
								e.preventDefault();
								setCommentType('');
								setTab('unread_notifications');
								goToNotificationSlide();
								setWorkSheetTitle(`${unreadNotifications.length} Notifications`);
							}}
						>
							{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
						<RedBellIcon style={{ height: '22px', width: '22px' }} />
					</div>
					<span>{commentCounts['all']} Notifications </span> */}
							{currentFilterTab === filterTabs.all && (
								<Actioncontainer
									label={'Notifications'}
									icon={<RedBellIcon style={{ height: '22px', width: '22px' }} />}
									count={commentCounts['all']}
								/>
							)}
						</NavLink>
					)}
				</>
			)}

			{(currentFilterTab === filterTabs.action || currentFilterTab === filterTabs.all) && (
				<>
					{/* <Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} /> */}
					{commentCounts['client_conversation'] > 0 && <SegmentDivider name={'URGENT'} />}

					<Collapse in={commentCounts['client_conversation'] > 0}>
						<NavLink
							className={`${styles.sidebarItem} ${
								commentType === 'client_conversation' ? 'text-normal' : ''
							}`}
							to={`/notifications`}
							onClick={e => {
								e.preventDefault();
								setCommentType('client_conversation');
								setTab('unread_notifications');
								goToNotificationSlide();
								setSelectedActions(['Client Conversation']);
								// setWorkSheetTitle(getFilterTitle());
							}}
						>
							{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
						<CommentIcon style={{ width: '22px', height: '22px' }} type={'client_conversation'} />
					</div>
					<span>{commentCounts['client_conversation']} Client Conversation </span> */}
							<Actioncontainer
								label={'Client Conversation'}
								count={commentCounts['client_conversation']}
								icon={
									<CommentIcon
										style={{ width: '22px', height: '22px' }}
										type={'client_conversation'}
									/>
								}
							/>
						</NavLink>
					</Collapse>

					{(commentCounts['approve'] > 0 ||
						commentCounts['escalation'] > 0 ||
						commentCounts['mentions'] > 0) && <SegmentDivider name={'INTERNAL'} />}
					<Collapse in={commentCounts['mentions'] > 0}>
						<NavLink
							className={`${styles.sidebarItem} ${tab === 'unread_mentions' ? 'text-normal' : ''} `}
							to={`/notifications`}
							onClick={e => {
								e.preventDefault();
								setCommentType('');
								setTab('unread_mentions');
								goToNotificationSlide();
								setSelectedActions(['Mentions']);
								// setWorkSheetTitle(getFilterTitle());
							}}
						>
							{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
						<MentionIcon style={{ width: '22px', height: '22px' }} />
					</div>
					<span>{commentCounts['mentions']} Mentions </span> */}
							<Actioncontainer
								label={'Mentions'}
								count={commentCounts['mentions']}
								icon={<MentionIcon style={{ width: '22px', height: '22px' }} />}
							/>
						</NavLink>
					</Collapse>

					{/* {(commentCounts['mentions'] > 0 || commentCounts['client_conversation'] > 0) && (
				<Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} />
			)} */}

					{/* {(commentCounts['submission'] > 0 || commentCounts['approve'] > 0) && (
				<Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} />
			)} */}

					{commentButtons?.map((type, index) => {
						return (
							!['client_conversation', 'submission'].includes(type.key) && (
								<Collapse in={commentCounts[type.key] > 0}>
									<NavLink
										className={`${styles.sidebarItem} ${
											commentType === type.key ? 'text-normal' : ''
										} `}
										key={index}
										to={`/notifications`}
										onClick={e => {
											e.preventDefault();
											setCommentType(type.key);
											if (tab === 'authors') {
												console.log(tab);
												filterAuthors(type.key);
												return;
											}
											setTab('unread_notifications');
											goToNotificationSlide();
											setSelectedActions([type.label]);
											// setWorkSheetTitle(`${authorsNotifications.length}` + getFilterTitle() );
										}}
									>
										{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
								{type.icon}
							</div>
							<span>
								{commentCounts[type.key]} {type.label}
							</span> */}
										<Actioncontainer
											label={type.label}
											count={commentCounts[type.key]}
											icon={type.icon}
										/>
									</NavLink>
								</Collapse>
							)
						);
					})}
					{commentCounts['submission'] > 0 && <SegmentDivider name={'COMPLETIONS'} />}
					{commentButtons?.map((type, index) => {
						return (
							['submission'].includes(type.key) && (
								<Collapse in={commentCounts[type.key] > 0}>
									<NavLink
										className={`${styles.sidebarItem} ${
											commentType === type.key ? 'text-normal' : ''
										} `}
										key={index}
										to={`/notifications`}
										onClick={e => {
											e.preventDefault();
											setCommentType(type.key);
											if (tab === 'authors') {
												console.log(tab);
												filterAuthors(type.key);
												return;
											}
											setTab('unread_notifications');
											goToNotificationSlide();
											setSelectedActions([type.label]);
											// setWorkSheetTitle(`${authorsNotifications.length}` + getFilterTitle() );
										}}
									>
										{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
								{type.icon}
							</div>
							<span>
								{commentCounts[type.key]} {type.label}
							</span> */}
										<Actioncontainer
											label={type.label}
											count={commentCounts[type.key]}
											icon={type.icon}
										/>
									</NavLink>
								</Collapse>
							)
						);
					})}
					<Collapse timeout={1000} in={savedNotifications.length > 0}>
						<SegmentDivider name={'REVISIT'} />
						<div
							className={`${styles.sidebarItem} ${
								tab === 'saved_notifications' ? 'text-normal' : ''
							} `}
							onClick={e => {
								e.preventDefault();
								setTab('saved_notifications');
								goToNotificationSlide();
								setCommentType('');
							}}
						>
							{/* <div className='d-flex' style={{ width: '30px', marginRight: '10px' }}>
							<GoClock style={{ height: '22px', width: '22px' }} />
						</div>
						<span>{savedNotifications.length} Saved for later </span> */}
							<Actioncontainer
								icon={<GoClock style={{ height: '22px', width: '22px' }} />}
								label={'Saved for later'}
								count={savedNotifications.length}
							/>
						</div>
					</Collapse>
				</>
			)}
			{(currentFilterTab === filterTabs.worksheet || currentFilterTab === filterTabs.all) && (
				<>
					{/* <Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} /> */}
					{/* <NavLink
						className={`${styles.sidebarItem} ${worksheet._id ? 'text-normal' : ''} `}
						to={`/notifications`}
						onClick={e => {
							e.preventDefault();
							setWorksheet({});
							setWorkSheetTitle('All Worksheets');
							goToNotificationSlide();
						}}
					>
						<span>{commentCounts['all']} All Worksheets</span>
					</NavLink> */}
					{worksheetListByClient
						?.sort((a, b) => {
							if (a[0] === 'undefined') {
								return +1;
							} else if (b[0] === 'undefined') {
								return -100;
							} else {
								return a[0] - b[0];
							}
						})
						?.map(([clientId, sheets]) => {
							return (
								<div key={clientId} className={styles.worksheetFilters}>
									{getGroupHasComment(sheets) && (
										<SegmentDivider name={clientId === 'null' ? 'OTHERS' : clientId} />
									)}

									{sheets
										.sort((a, b) => a.seq - b.seq)
										?.map(sheet => {
											const hasComments = commentCounts?.worksheets?.[sheet._id] > 0;

											return (
												<>
													<Collapse in={hasComments}>
														<div
															className={`${styles.sidebarItem} ${
																worksheet._id === sheet._id ? 'text-normal' : ''
															}`}
															key={sheet._id}
															onClick={e => {
																setWorksheet(sheet);
																if (tab === 'authors') {
																	filterAuthorsBasedOnWorksheet(sheet._id);
																	return;
																}
																setTab('unread_notifications');

																// setWorkSheetTitle(sheet.title);
																goToNotificationSlide();
																setSelectedWorksheets([sheet.title]);
																// setWorkSheetTitle(getFilterTitle() +`${unreadNotifications.length}`);
															}}
														>
															{/* <div
													style={{ width: '100%' }}
													className='d-flex justify-content-between align-items-center'
												>
													<span>
														{commentCounts['worksheets'][sheet._id]} {sheet.title}
													</span>
													<NavLink
														style={{ width: '3em', marginBottom: '0px' }}
														className={`${
															worksheet._id === sheet._id ? 'text-normal' : 'text-muted'
														} ml-2 d-flex items-center`}
														to={`/chopping-interface/${sheet._id}`}
														target='_blank'
													>
														<AiOutlineLink className='text-normal mb-0' />
													</NavLink>
												</div> */}
															<WorksheetLabelConatiner
																name={sheet.title}
																count={commentCounts?.worksheets?.[sheet?._id] || 0}
															/>
														</div>
													</Collapse>
												</>
											);
										})}
								</div>
							);
						})}
				</>
			)}
			{/* <Divider style={{ background: 'rgb(255, 255, 255, 0.2)' }} /> */}

			{/* <NavLink
				className={`${styles.sidebarItem} ${worksheet === '' ? 'text-normal' : ''}`}
				to={`/notifications`}
				onClick={e => {
					e.preventDefault();
					setWorkSheetTitle('All Authors');
					setTab('unread_notifications');
					setSelectedUser({});
					goToNotificationSlide();
				}}
			>
				<span>{authors?.length} All Authors</span>
			</NavLink> */}

			{/* <Divider style={{ background: 'rgb(255, 255, 255, 0.2)', height: '0.5px' }} /> */}
			{(currentFilterTab === filterTabs.users || currentFilterTab === filterTabs.all) && (
				<>
					<div style={{ paddingBottom: '66px', paddingTop: '10px' }}>
						{authors?.map((user, index) => {
							if (userIDs.includes(user._id)) {
								return (
									user.name && (
										<div
											key={index}
											style={{ gap: '5px' }}
											className={`${styles.sidebarItem} ${
												selectedUser?.name === user.name ? 'text-normal' : ''
											} `}
											onClick={() => {
												setTab('authors');
												setSelectedUser(user);
												goToNotificationSlide();
												getAuthorNotifications(user._id);
											}}
										>
											{/* {commentCountByAuthorId(user._id)}
									
									<span>{user.name}</span> */}
											<AuthorContainer
												name={user.name}
												count={commentCountByAuthorId(user._id)}
												avatar={user.avatar}
											/>
										</div>
									)
								);
							} else {
								return null;
							}
						})}
					</div>
				</>
			)}
		</div>
	);
};

const MainContentNotification = ({
	filteredNotificationCount,
	loading,
	commentCounts,
	setDrawerWorksheetState,
	activeWorksheetInDrawer,
	setDrawerStateActions,
	activeActionsInDrawer,
	tab,
	setTab,
	markAllNotifications,
	unreadNotifications,
	getNotificationCount,
	setNotificationId,
	allNotifications,
	markNotificationRead,
	handleOpenTaskDetailsModal,
	unreadMentionNotifications,
	openTaskDetailsModal,
	handleCloseTaskDetailsModal,
	modalRef,
	handleCloseAsUnread,
	currentNotification,
	selectedTask,
	openNextNotification,
	workSheetTitle,
	setUnreadMentionNotifications,
	setUnreadNotifications,
	getUnreadNotifications,
	saveForLater,
	savedNotifications,
	removeFromSave,
	isMobile,
	tdpHeaderScroll,
	setTdpHeaderOnScroll,
	headerVisible,
	setHeaderVisible,
	boxRef,
	handleHeaderScroll,
	selectedUser,
	authorsNotifications,
	setAuthorsNotifications,
	commentType,
	worksheet,
	prefetchedTasks,
	handleUpdatePrefetchedTask,
	classes,
	fadingNotification,
	hasMarkedAll,
	setAnimationFinishedForMarkingAll
}) => {
	// console.log(getNotificationCount());
	// useEffect(() => {
	//   console.log(getNotificationCount() );
	// }, [])
	//testing deploy
	// const getScreenTitle = () => {
	// 	if (tab === 'saved_notifications') {
	// 		return "Saved For Later"

	// 	}
	// 	else if (tab === 'authors') {
	// 		return authorsNotifications.length + ' Notifications From ' + selectedUser?.name
	// 	}
	// 	else {
	// 		return workSheetTitle?.length > 0 ? workSheetTitle : `${unreadNotifications.length} Notifications`
	// 	}
	// }
	// tab, selectedAuthors, selectedActions, selectedWorksheets, authorsNotifications, selectedUser, workSheetTitle, unreadNotifications

	const getScreenTitle = () => {
		const numSavedNotifications = savedNotifications.length;
		const numAuthorsNotifications = authorsNotifications.length;
		const numUnreadNotifications = unreadNotifications.length;
		const numAllNotifications = allNotifications.length;
		const numUnreadMentionNotifications = unreadMentionNotifications.length;

		if (numUnreadMentionNotifications > 0 && tab === 'unread_mentions') {
			return `${numUnreadMentionNotifications} Unread Mentions`;
		} else if (numSavedNotifications > 0 && tab === 'saved_notifications') {
			return `${numSavedNotifications} Saved Notifications`;
		} else if (tab == 'all_notifications') {
			return `${numAllNotifications} All Notifications`;
		} else if (selectedUser && selectedUser.name?.length > 0) {
			if (commentType.length > 0 && worksheet.title) {
				return `${numAuthorsNotifications}  ${commentType} from ${selectedUser.name} on ${worksheet.title}`;
			} else if (commentType.length > 0) {
				return `${numAuthorsNotifications} ${commentType} from ${selectedUser.name}`;
			} else if (worksheet.title) {
				console.log(numAuthorsNotifications);
				return `${numAuthorsNotifications} Notifications from ${selectedUser.name} on ${worksheet.title}`;
			} else {
				console.log('a');
				return `${numAuthorsNotifications} Notifications from ${selectedUser.name}`;
			}
		} else if (commentType.length > 0 && worksheet.title) {
			return `${filteredNotificationCount} ${commentType} on ${worksheet.title}`;
		} else if (commentType.length > 0) {
			return `${commentCounts[commentType]} ${commentType}`;
		} else if (worksheet.title) {
			return `${numUnreadNotifications} Notifications on ${worksheet.title}`;
		} else {
			return `${numUnreadNotifications} Notifications`;
		}
	};

	const chekZero = () => {
		if (savedNotifications.length === 1) {
			setTab('unread_notifications');
			getUnreadNotifications();
		}
	};

	return (
		<div
			className='main-content worksheet_content responsive-main'
			style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: 0, position: 'relative' }}
		>
			<Backdrop className={`${classes.backDropSpinner}`} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<CommonHeader
				activeScreen={'notification'}
				title={getScreenTitle()}
				onTabChange={setTab}
				tabActive={tab}
				markAllNotifications={markAllNotifications}
				marginBottom={'0px'}
				position='sticky'
			/>
			<div className={styles.NotificationsList}>
				<div className={styles.notificationHeader} style={{ display: 'none' }}>
					<div className={styles.notificationCountContainer}>
						{/* <h1 className='page-title m-0 mt-2 py-1'> {commentCounts['all']} Notifications</h1> */}
						<h1 className='page-title m-0'>
							{/* {workSheetTitle?.length > 0 ? workSheetTitle : 'All Notifications'} */}
							{getScreenTitle()}
						</h1>
						{/* <div className={styles.drawerButtons}>
					<div onClick={() => setDrawerWorksheetState(true)}>{activeWorksheetInDrawer} &#9662;</div>
					<div onClick={() => setDrawerStateActions(true)}>{activeActionsInDrawer} &#9662;</div>
				</div> */}
					</div>
					<div className={styles.notificationSubheader}>
						{/* <div className='d-flex ml-0 mr-3 my-3'>
                        <div className='mx-2 d-flex align-items-center'>
                            <p className='text-normal mb-0'>
                                Comment Type
                            </p>
                        </div>
                        <div>
                            <select
                                value={commentType}
                                style={{ 
                                padding: '5px 10px'
                                }}
                                onChange={(e) => {setCommentType(e.target.value)}}
                            >
                                <option value="">All</option>
                                {commentButtons.map((type, index) => {
                                    return <option value={type.key} key={index}>{type.label}</option>
                                })}
                            </select>
                        </div>
                    </div> */}
						{/* <div className='d-flex justify-content-between'> */}
						<div>
							<span
								className={`pr-4 ${tab === 'unread_notifications' ? 'text-normal' : 'text-muted'}`}
								style={{ cursor: 'pointer' }}
								onClick={e => setTab('unread_notifications')}
							>
								Unread
							</span>
							<span
								className={`px-4 ${tab === 'all_notifications' ? 'text-normal' : 'text-muted'}`}
								style={{ cursor: 'pointer' }}
								onClick={e => setTab('all_notifications')}
							>
								All
							</span>
							<span
								className={`pl-4 ${tab === 'unread_mentions' ? 'text-normal' : 'text-muted'}`}
								style={{ cursor: 'pointer' }}
								onClick={e => setTab('unread_mentions')}
							>
								Unread Mentions
							</span>
						</div>
						<div
							style={{ cursor: 'pointer' }}
							onClick={e => {
								markAllNotifications();
							}}
						>
							Mark All
						</div>
						{/* </div> */}
					</div>
				</div>

				{tab === 'unread_notifications' && (
					<div
						className={`mb-4 ${styles.notificationListContainer}`}
						style={{ paddingBottom: '60px' }}
					>
						{unreadNotifications?.map((notification, index) => {
							if (notification.saved_for_later) {
								return '';
							} else {
								return (
									<Notification
										type='unread_notifications'
										notification={notification}
										getNotificationCount={getNotificationCount}
										setNotificationId={setNotificationId}
										key={index}
										isFirstItem={index === 0}
										markNotificationRead={markNotificationRead}
										handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
										setUnreadMentionNotifications={setUnreadMentionNotifications}
										unreadNotifications={unreadNotifications}
										setUnreadNotifications={setUnreadNotifications}
										getUnreadNotifications={getUnreadNotifications}
										saveForLater={saveForLater}
										removeFromSave={removeFromSave}
										checkZero={chekZero}
										isMobile={isMobile}
										fadingNotification={fadingNotification}
										hasMarkedAll={hasMarkedAll}
										animationDelayMultiplier={index}
										setAnimationFinishedForMarkingAll={setAnimationFinishedForMarkingAll}
										isLastItem={index === unreadNotifications.length - 1}
									/>
								);
							}
						})}
					</div>
				)}
				{tab === 'authors' && (
					<div
						className={`mb-4 ${styles.notificationListContainer}`}
						style={{ paddingBottom: '60px' }}
					>
						{authorsNotifications?.map((notification, index) => {
							if (notification.saved_for_later) {
								return '';
							} else {
								return (
									<Notification
										type='unread_notifications'
										notification={notification}
										getNotificationCount={getNotificationCount}
										setNotificationId={setNotificationId}
										key={index}
										isFirstItem={index === 0}
										markNotificationRead={markNotificationRead}
										handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
										setUnreadMentionNotifications={setUnreadMentionNotifications}
										unreadNotifications={unreadNotifications}
										setUnreadNotifications={setUnreadNotifications}
										getUnreadNotifications={getUnreadNotifications}
										saveForLater={saveForLater}
										removeFromSave={removeFromSave}
										checkZero={chekZero}
										isMobile={isMobile}
										fadingNotification={fadingNotification}
										hasMarkedAll={hasMarkedAll}
										animationDelayMultiplier={index}
										setAnimationFinishedForMarkingAll={setAnimationFinishedForMarkingAll}
										isLastItem={index === authorsNotifications.length - 1}
									/>
								);
							}
						})}
					</div>
				)}

				{tab === 'all_notifications' && (
					<div
						className={`mb-4 ${styles.notificationListContainer}`}
						style={{ paddingBottom: '60px' }}
					>
						{allNotifications?.map((notification, index) => {
							return (
								<Notification
									type='all_notifications'
									notification={notification}
									key={index}
									isFirstItem={index === 0}
									markNotificationRead={markNotificationRead}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									saveForLater={saveForLater}
									getNotificationCount={getNotificationCount}
									removeFromSave={removeFromSave}
									checkZero={chekZero}
									isMobile={isMobile}
									fadingNotification={fadingNotification}
									hasMarkedAll={hasMarkedAll}
									animationDelayMultiplier={index}
									setAnimationFinishedForMarkingAll={setAnimationFinishedForMarkingAll}
									isLastItem={index === allNotifications.length - 1}
								/>
							);
						})}
					</div>
				)}
				{tab === 'saved_notifications' && (
					<div
						className={`mb-4 ${styles.notificationListContainer}`}
						style={{ paddingBottom: '60px' }}
					>
						{savedNotifications?.map((notification, index) => {
							return (
								<Notification
									type='all_notifications'
									notification={notification}
									key={index}
									isFirstItem={index === 0}
									markNotificationRead={markNotificationRead}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									saveForLater={saveForLater}
									getNotificationCount={getNotificationCount}
									removeFromSave={removeFromSave}
									checkZero={chekZero}
									isMobile={isMobile}
									fadingNotification={fadingNotification}
									hasMarkedAll={hasMarkedAll}
									animationDelayMultiplier={index}
									setAnimationFinishedForMarkingAll={setAnimationFinishedForMarkingAll}
									isLastItem={index === savedNotifications.length - 1}
								/>
							);
						})}
					</div>
				)}

				{tab === 'unread_mentions' && (
					<div
						className={`mb-4 ${styles.notificationListContainer}`}
						style={{ paddingBottom: '60px' }}
					>
						{unreadMentionNotifications?.map((notification, index) => {
							return (
								<Notification
									type='unread_mentions'
									notification={notification}
									key={index}
									isFirstItem={index === 0}
									markNotificationRead={markNotificationRead}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									saveForLater={saveForLater}
									getNotificationCount={getNotificationCount}
									removeFromSave={removeFromSave}
									checkZero={chekZero}
									isMobile={isMobile}
									fadingNotification={fadingNotification}
									hasMarkedAll={hasMarkedAll}
									animationDelayMultiplier={index}
									setAnimationFinishedForMarkingAll={setAnimationFinishedForMarkingAll}
									isLastItem={index === unreadMentionNotifications.length - 1}
								/>
							);
						})}
					</div>
				)}
				{/* <Modal
				open={openTaskDetailsModal}
				aria-labelledby='modal-modal-title'
				onClose={handleCloseTaskDetailsModal}
				aria-describedby='modal-modal-description'
			>
				<Box className={`modal_container`} ref={modalRef}>
						<div
							style={{
								display: 'flex',
								gap:'20px',
								justifyContent: 'flex-end',
								cursor: 'pointer',
								position:'absolute',
								width:'100%',
								transition: 'transform 0.1s ease-in-out',
								transform:`translateY(${headerVisible})`,
								top:`0px`,
								background:'#5a5a5a',
								paddingTop:'10px',
								paddingBottom:'10px',
								marginTop:'-10px',
								overflow :'hidden',
								paddingRight:'14px',
								height:'50px',
								zIndex:200000
							}}
						>
							{!currentNotification?.saved_for_later && (
							 <><button onClick={() => {
								saveForLater(currentNotification._id)

							}} className={styles.tdpHeaderBtn} title='Save For Later'>
								<FaClock size={16}  />
							
							</button></>)}
							<button title='Close as Unread' className={styles.tdpHeaderBtn}  onClick={handleCloseAsUnread} style={{ color: 'white',width:'50px' }}>
						
							<IoMdMailUnread size={17}/>
							
							</button>
                             
							 <button title='Close' className={styles.tdpHeaderBtn} >
							<div className={`fa_cross_icon`}>

								<FaTimes size={10} onClick={handleCloseTaskDetailsModal} />
							</div>
							 </button>
						</div>
					<Box className={`modal_body pt-0`} onScroll={handleHeaderScroll}>

						<TaskDetails
							activeWorksheet={currentNotification?.data?.worksheet?._id}
							item_id={currentNotification?.data?.task?._id}
							openNextTaskOnNotficationPage={openNextNotification}
							comment_id={currentNotification?.data?.comment?._id}
						/>
			    	</Box>
					
				</Box>
			</Modal> */}
				<TaskDetailsPopup
					open={openTaskDetailsModal}
					selectedTask={prefetchedTasks.taskById[selectedTask?._id]}
					handleClose={handleCloseTaskDetailsModal}
					item_id={currentNotification?.data?.task?._id}
					openNextTaskOnNotficationPage={openNextNotification}
					comment_id={currentNotification?.data?.comment?._id}
					currentNotification={currentNotification}
					modalRef={modalRef}
					headerVisible={headerVisible}
					handleHeaderScroll={handleHeaderScroll}
					handleCloseAsUnread={handleCloseAsUnread}
					saveForLater={saveForLater}
					unreadsPresent={'true'}
					updatePrefetchedTask={handleUpdatePrefetchedTask}
					openedTask={selectedTask}
				/>
			</div>
		</div>
	);
};

const printDate = (rawdate, forTask = false) => {
	if (rawdate) {
		var date = moment(rawdate);
		var now = moment();

		if (forTask) {
			if (date.isSame(now, 'day')) {
				return 'Today';
			} else if (date.isSame(now.subtract(1, 'days'), 'day')) {
				return 'Yesterday';
			} else {
				return window.innerWidth <= 600
					? mobileDateFormatter(rawdate)
					: date.format('MMMM Do YYYY');
			}
		}
		// let forMobile = date.fromNow();
		// forMobile = forMobile.replace(" hours ago", "h");
		// forMobile = forMobile.replace("an hour ago", "1h");
		// forMobile = forMobile.replace(" minutes ago", "m");
		// forMobile = forMobile.replace("a minute ago", "1m");
		// forMobile = forMobile.replace(" days ago", "d");
		// forMobile = forMobile.replace("a day ago", "1d");
		return window.innerWidth <= 600 ? mobileTimeFormatter(rawdate) : date.fromNow();
	}
	return '';
};

const Notification = ({
	notification,
	markNotificationRead,
	isFirstItem,
	handleOpenTaskDetailsModal,
	getNotificationCount,
	setNotificationId,
	setUnreadMentionNotifications,
	unreadNotifications,
	setUnreadNotifications,
	getUnreadNotifications,
	saveForLater,
	removeFromSave,
	checkZero,
	type,
	isMobile,
	fadingNotification,
	hasMarkedAll,
	animationDelayMultiplier,
	setAnimationFinishedForMarkingAll,
	isLastItem
}) => {
	// const saveForLater = (notificationId,type) => {
	// 	const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notificationId}/save-for-later`;
	// 	axios
	// 		.post(url, {
	// 			status: true
	// 		})
	// 		.then(data => {

	// 			toastr.success("saved for later")
	// 			getUnreadNotifications()

	// 		})
	// 		.catch(e => console.log(e))
	// 		.finally(e => {
	// 			// setLoading(false);
	// 		});

	// }
	const notificationRef = useRef(null);

	const getIconData = (notificationType, commentType) => {
		if (notificationType === 'App\\Notifications\\TaskAssigned') {
			return 'task-assigned';
		}
		if (notificationType === 'App\\Notifications\\UserMentioned') {
			return 'mention';
		} else {
			return commentType;
		}
	};
	const Actions = () => {
		return (
			<>
				{/* <div className={`text-muted d-flex align-items-center`}>
					{!notification.read_at && (
						<div
							className={`${styles.fa_check_notification}`}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								markNotificationRead(notification._id);
							}}
						>
							<FaCheck size={15} />
						</div>
					)}
					{!notification.saved_for_later && (
						<div
							className={`${styles.fa_check_notification}`}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								saveForLater(notification._id, type);
							}}
						>
							<FaClock size={15} />
						</div>
					)}
					{notification.saved_for_later && (
						<div
							title='Remove From Save from Later'
							className={`${styles.fa_check_notification}`}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								removeFromSave(notification._id, type);
								checkZero();
							}}
						>
							<ImCross size={15} />
						</div>
					)}
				</div> */}
				<div className={styles.notificationActionBtnContainer}>
					{!notification.read_at && (
						<MarkReadButton
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								markNotificationRead(notification._id);
							}}
						/>
					)}
					{!notification.saved_for_later && (
						<SaveLaterButton
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								saveForLater(notification._id, type);
							}}
						/>
					)}
					{notification.saved_for_later && (
						<div
							title='Remove From Save from Later'
							className={`${styles.fa_check_notification}`}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								getNotificationCount();
								removeFromSave(notification._id, type);
								checkZero();
							}}
						>
							<ImCross size={15} />
						</div>
					)}
				</div>
			</>
		);
	};

	return (
		<div
			className={`${styles.notificationHover}  ${
				fadingNotification === notification._id && !hasMarkedAll
					? styles.anim + ' ' + styles.fadOutBack
					: ''
			} ${hasMarkedAll ? styles['marked_read__all'] : ''}`}
			ref={notificationRef}
			style={{
				'--delay-multiplier': animationDelayMultiplier
			}}
			{...(isLastItem
				? {
						onAnimationEnd: ev => {
							//* if this is the last notification,capture its animation ending event
							//* to clear the states and fire a backend request
							setAnimationFinishedForMarkingAll(true);
						}
				  }
				: {})}
		>
			<NavLink
				className={styles.notificationMainContainer}
				style={{ textDecoration: 'none', color: '#fff', overflowX: 'auto', width: '100%' }}
				to={`/chopping-interface/${notification.data?.worksheet?._id}?item_id=${notification.data?.task?._id}`}
				onClick={e => {
					e.preventDefault();
					handleOpenTaskDetailsModal(notification);
					if (setNotificationId) setNotificationId(notification._id);
				}}
			>
				<div className={`${styles.notificationInfoContainer}`}>
					<div className={styles.notificationMainItem}>
						<div className={`d-none d-sm-flex`}>
							{/* <div>
								{getNotificationTypeIcon(notification.type, notification.data?.comment?.type)}
								{notification.data?.sent_by?.name && (
									<CustomAvatar
										avatar={notification.data?.sent_by?.avatar}
										alt='profile'
										name={notification.data?.sent_by?.name}
										className={`${styles.notification_avatar}`}
									/>
								)}
							</div> */}
							<AvatarWithAction
								actionType={getIconData(notification.type, notification.data?.comment?.type)}
								avatar={notification.data?.sent_by?.avatar}
								name={notification.data?.sent_by?.name}
							/>
						</div>

						<div style={{ width: '100%' }}>
							<div className={`${styles.notificationMainTitle}`}>
								{notification.data?.task?.title || 'NA, Click here to check the task.'}
							</div>
							<div
								className='d-flex justify-between'
								style={{ width: '100%', justifyContent: 'space-between' }}
							>
								<div
									className={`text-small d-flex text-muted ${styles.horizontal_list} d-flex flex-wrap text-nowrap mt-1`}
								>
									<div className='d-inline d-sm-none' style={{ position: 'relative' }}>
										{/* {getNotificationTypeIcon(notification.type, notification.data?.comment?.type)} */}
										{notification.data?.sent_by?.name && (
											<AvatarWithAction
												actionType={getIconData(
													notification.type,
													notification.data?.comment?.type
												)}
												avatar={notification.data?.sent_by?.avatar}
												name={notification.data?.sent_by?.name}
											/>
											// <CustomAvatar
											// 	avatar={notification.data?.sent_by?.avatar}
											// 	alt='profile'
											// 	name={notification.data?.sent_by?.name}
											// 	className={`${styles.notification_avatar}`}
											// 	style={
											// 		notification.data?.sent_by?.avatar
											// 			? {
											// 				height: '20px',
											// 				width: '20px',
											// 				padding: '0px',
											// 				fontSize: '10px'
											// 			}
											// 			: {
											// 				height: 'auto',
											// 				width: 'auto',
											// 				padding: '5px 4px',
											// 				fontSize: '10px'
											// 			}
											// 	}
											// />
										)}
									</div>
									<div className={styles.notificationMetaData}>
										<span className={`${styles.separator} d-inline d-sm-none`}>
											{window.innerWidth > 600 ? '•' : ''}
										</span>

										<li className=''>
											{window.innerWidth <= 600
												? notification.data?.sent_by?.name?.split(' ')[0]
												: notification.data?.sent_by?.name}
										</li>
										<span className={`${styles.separator}`}>•</span>
										<li className=''>{notification.data?.worksheet?.title}</li>
										<span className={`${styles.separator}`}>•</span>
										<li className=''>{printDate(notification.created_at)}</li>
									</div>
								</div>
								{isMobile && <Actions />}
							</div>
						</div>
					</div>

					{!isMobile && (
						<div className={styles.notificationActionBtnContainer}>
							{!notification.read_at && (
								// <div
								// 	className={`${styles.fa_check_notification}`}
								// 	onClick={e => {
								// 		e.preventDefault();
								// 		e.stopPropagation();
								// 		getNotificationCount();
								// 		markNotificationRead(notification._id);
								// 	}}
								// >
								// 	<FaCheck size={15} />
								// </div>
								<MarkReadButton
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();

										//* determine this elements height and attach the height for transition
										const elementHeight = notificationRef.current.getBoundingClientRect().height;
										notificationRef.current.style.height = `${elementHeight}px`;
										setTimeout(() => {
											getNotificationCount();
											markNotificationRead(notification._id);
											notificationRef.current.style.height = 'auto';
										}, 200);
									}}
								/>
							)}
							{!notification.saved_for_later && (
								// <div
								// 	className={`${styles.fa_check_notification}`}
								// 	onClick={e => {
								// 		e.preventDefault();
								// 		e.stopPropagation();
								// 		getNotificationCount();
								// 		saveForLater(notification._id, type);
								// 	}}
								// >
								// 	<FaClock size={15} />
								// </div>
								<SaveLaterButton
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										getNotificationCount();
										saveForLater(notification._id, type);
									}}
								/>
							)}
							{notification.saved_for_later && (
								<div
									title='Remove From Save from Later'
									className={`${styles.fa_check_notification}`}
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										getNotificationCount();
										removeFromSave(notification._id, type);
										checkZero();
									}}
								>
									<ImCross size={15} />
								</div>
							)}
						</div>
					)}
				</div>

				<div className='text-normal d-flex align-items-center mb-0'>
					<div>
						{notification.data?.comment?.content && (
							<div
								className={`text-normal mb-0 ${styles.notificationMainComment} ${styles.notification_comment_title}`}
								style={{ overflowX: 'auto' }}
							>
								{parse(notification.data?.comment?.content, parseOptions)}
								{/* {cheerio.load(notification.data?.comment?.content).text()} */}
							</div>
						)}
					</div>
				</div>
			</NavLink>
			{/* <Divider style={{ background: '#ffffff1a', margin: '1px 0' }} /> */}
			<GradientBorder />
		</div>
	);
};

const getNotificationTypeIcon = (notification_type, comment_type = false) => {
	if (notification_type === 'App\\Notifications\\TaskCompleted') {
		return <GreenCheck className={`${styles.notification_type_icon}`} />;
	}
	if (notification_type === 'App\\Notifications\\TaskAssigned') {
		return <BlueFileTick className={`${styles.notification_type_icon}`} />;
	}

	if (notification_type === 'App\\Notifications\\CommentAdded') {
		return <CommentIcon type={comment_type} className={`${styles.notification_type_icon}`} />;
	}

	if (notification_type === 'App\\Notifications\\UserMentioned') {
		return <MentionIcon className={`${styles.notification_type_icon}`} />;
	}

	return '';
};

export default Notifications;
