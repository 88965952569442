const calculateEstimate = (direction, currentValue) => {
    if (direction === "up") {
        return currentValue < 0.25 ? currentValue + 0.125 : currentValue + 0.25
    }
    else if (direction === "down") {
        return currentValue > 0
            ? currentValue <= 0.25
                ? currentValue - 0.125
                : currentValue - 0.25
            : 0
    }
}

function linkify(inputText) {
    var replacedText;

    var urlPattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    replacedText = inputText.replace(urlPattern, function (match, capture) {
        if (capture.startsWith("https://rwlaravel.puneetpugalia.com")) {
            return capture;
        }
        else if (capture.startsWith("https://bc3-production-assets-cdn.basecamp-static.com")) {
            return capture;
        } else {
            return '<a href="' + capture + '" target="_blank" style="color: white; text-decoration: underline;" >' + capture + '</a>';
        }
    });

    var wwwPattern = /(^|[^\/])(www\.[\S]+(\b|$))/ig;
    replacedText = replacedText.replace(wwwPattern, '$1<a href="http://$2" target="_blank"  style="color: white; text-decoration: underline;">$2</a>');

    return replacedText;
}

export { calculateEstimate, linkify };