const BASE_URL = process.env.REACT_APP_LARAVEL_API_URL;

const API_URLS = {
	base_url: BASE_URL,
	getTeamMembers: `${BASE_URL}/team`,
	addTeamMember: `${BASE_URL}/team/add`,
	editTeamMember: _id => `${BASE_URL}/team/edit/${_id}`,
	setPassword: `${BASE_URL}/user/set_password`,
	unplannedTask: _id => `${BASE_URL}/task/unplanned${_id ? '/' + _id : ''}`,
};

export default API_URLS;
