import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import '@atlaskit/css-reset';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import theme from './muiCustomTheme';
import colors from './colorPallete';

import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
Bugsnag.start({
	apiKey: 'c535dd33558643d1e4cdb258a7f53cf7',
	plugins: [new BugsnagPluginReact()]
  })
  const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

BugsnagPerformance.start({ apiKey: 'c535dd33558643d1e4cdb258a7f53cf7' })
ReactDOM.render(
<ErrorBoundary>
		<Router>
			<ScThemeProvider theme={colors}>
				<MuiThemeProvider theme={theme}>
					<App />
				</MuiThemeProvider>
			</ScThemeProvider>
		</Router>
</ErrorBoundary>,
	
	document.getElementById('root')
);

serviceWorkerRegistration.register();
