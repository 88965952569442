import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSearchParams } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const token = localStorage.getItem('red_wing_token');

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'rgba(0,0,0,0.5)'
	},
	hide_heading: {
		display: 'none'
	}
}));

function PerformanceTeamLead() {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [users, setUsers] = useState(null);
	const [delegation, setDelegation] = useState(null);
	const [completion, setCompletion] = useState(null);
	const [dates, setDates] = useState(null);

	const id = searchParams.get('userId');

	useEffect(() => {
		setLoading(true);
		axios
			.get(
				`https://redwing.happygoals.app/pages/team_lead_performance_report.php?userid=${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Access-Control-Allow-Origin': '*'
					}
				}
			)
			.then(res => {
				console.log(res);
				setUsers(res.data.users);
				let data = res.data.data;
				let delegations = [];
				let completions = [];
				let dates = [];
				data.map(item => {
					delegations.push(item.delegated_budget);
					completions.push(item.completed_budget);
					dates.push(moment(item.date).format('DD MMM YYYY'));
				});
				setDelegation(delegations);
				setCompletion(completions);
				setDates(dates);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}, [id]);

	const options = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
				tools: {
					download: false // <== line to add
				}
			}
		},
		grid: {
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: false
				}
			}
		},
		annotations: {
			yaxis: [
				{
					y: 120,
					borderColor: '#FFFFFF'
				}
			]
		},

		xaxis: {
			categories: dates,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		yaxis: {
			min: 0,
			max: 120,
			tickAmount: 2,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		legend: {
			fontFamily: 'Poppins',
			labels: {
				colors: ['#FFFFFF']
			}
		}
	};
	const series = [
		{
			name: 'Delegation',
			data: delegation
		},
		{
			name: 'Completion',
			data: completion
		}
	];

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<div
				style={{ display: 'flex', gap: '2vw', width: '100%', color: 'white', padding: '3vh 2vw' }}
			>
				<div style={{ width: '20%', color: 'white' }}>
					{users?.map(item => {
						return (
							<div
								onClick={() => setSearchParams({ userId: item.user_id })}
								style={
									Number(item.user_id) === Number(id)
										? {
												marginBottom: '1vh',
												cursor: 'pointer',
												color: 'white',
												fontWeight: 'bold'
										  }
										: { marginBottom: '1vh', cursor: 'pointer', color: 'grey' }
								}
							>
								{item.name}
							</div>
						);
					})}
				</div>
				<div style={{ width: '80%' }}>
					{!loading && (
						<Chart
							options={options}
							series={series}
							type='bar'
							height='300'
							style={{ width: '100%' }}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default PerformanceTeamLead;
