export const MAX_RECENTLY_VISITED_TASKS = 5;
export const MAX_RECENTLY_VISITED_WORKSHEETS = 5;

export const CACHE_KEY_MAP = {
	RECENTLY_VISITED_TASKS_KEY: 'recently_visited_tasks',
	RECENTLY_VISITED_WORKSHEETS_KEY: 'recently_visited_worksheets'
};

export const cacheTaskInRecentlyVisitedList = task => {
	const cacheKey = CACHE_KEY_MAP.RECENTLY_VISITED_TASKS_KEY;
	let recentlyVisitedTasks = JSON.parse(localStorage.getItem(cacheKey)) || [];

	while (recentlyVisitedTasks.length >= MAX_RECENTLY_VISITED_TASKS) {
		recentlyVisitedTasks?.shift();
	}

	recentlyVisitedTasks?.push(task);

	localStorage.setItem(cacheKey, JSON.stringify(recentlyVisitedTasks));
};

export const cacheWorksheetInRecentlyVisitedList = worksheet => {
	const cacheKey = CACHE_KEY_MAP.RECENTLY_VISITED_WORKSHEETS_KEY;
	let recentlyVisitedWorksheets = JSON.parse(localStorage.getItem(cacheKey)) || [];

	while (recentlyVisitedWorksheets.length >= MAX_RECENTLY_VISITED_WORKSHEETS) {
		recentlyVisitedWorksheets?.shift();
	}

	recentlyVisitedWorksheets?.push(worksheet);

	localStorage.setItem(cacheKey, JSON.stringify(recentlyVisitedWorksheets));
};

