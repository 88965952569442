import React from 'react';

function ReportDocumentation() {
	return (
		<div style={{ color: 'white', width: '60%', margin: 'auto', padding: '5vh 0' }}>
			<div>Budgeting Tasks</div>
			<ul>
				<li>Team leaders assign the budget to every task</li>
				<li>{`The budget is assign by comment: !add_budget <hours>`}</li>
				<li>{`To reduce the budget, use comment: !reduce_budget <hours>`}</li>
				<li>
					If you feel that the budget is less or if you are facing any technical difficulties in a
					task, you can request an extra budget with a proper reason by commenting on the same task.{' '}
				</li>
				<li>
					The full-time intern is supposed to work for 40hours and a part-time intern for 25hours
					per week.
				</li>
			</ul>
			<br />
			<div>Important Points to Keep in Mind</div>
			<ul>
				<li>If there is no budget assigned to the task, ask the team leader to assign a budget</li>
				<li>
					If you feel the budget is low, ask the team leader to increase the budget after submitting
					the evidence of the task and before marking it complete.
				</li>
				<li>If you miss the budget on any task, it will affect your performance calculation</li>
			</ul>
			<br />
			<div>Performance Calculation</div>
			<ul>
				<li>
					The percentage shown on the dashboard is the percentage of the budget of tasks that are
					marked completed and the hours you are supposed to work as per your role.
				</li>
				<li>
					If your percentage is below 50% then it's extremely poor and it's mentioned in red color.
				</li>
				<li>
					If your percentage is between 50% and 70% then it's moderate and it's mentioned in yellow
					color.
				</li>
				<li>
					If your percentage is above 70% then it's very good and it's mentioned in green color.
				</li>
				<li>
					If a budget is not assigned to a task, you should request your team leader to assign the
					budget so that your performance is calculated correctly else your output will be shown
					less and your performance might get negative.{' '}
				</li>
				<li>
					When a task is reopened, the budget of that task is removed from the completion count and
					it will get carried forward when the task is marked completed.{' '}
				</li>
			</ul>
			<br />
			<div>Putting tasks on Hold</div>
			<ul>
				<li>
					If your task is on hold, you can add a comment as !add_to_hold, so that your team leads
					know you are idle and your tasks are on hold.{' '}
				</li>
				<li>
					After commenting !add_to_hold, do give the proper reason on the task and tag the team
					leads to it so that he can take proper action.
				</li>
				<li>
					You need to mark the task on hold only if the task is not clear to you or if you have some
					requirements regarding the task. And not when you are working on some other tasks.
				</li>
				<li>To remove a task from hold use comment: !remove_from_hold</li>
			</ul>
		</div>
	);
}

export default ReportDocumentation;
