import {
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as axiosBase from 'axios';

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState([]);
	const [message, setMessage] = useState('');

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		setErrors([]);

		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/login`,
				{
					email: email,
					password: password
				},
				{}
			)
			.then(res => {
				if (res.data.success === true) {
                    localStorage.setItem('access_token', res.data.token);
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    window.location.href = '/chopping-interface';
				} else {
					setMessage(res.data.message);
					console.log(res.data);
				}
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				if (error.response.status === 422) {
					console.log(error.response.data);
					setErrors(error.response.data.errors);
					setLoading(false);
				}
			});
	};

	return (
		<Grid
			container
			component='main'
			style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			<Grid item xs={12} sm={8} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>{/* <LockOutlinedIcon /> */}</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					{message && <div style={{ color: 'red' }}> {message}</div>}
					<Box
						component='form'
						noValidate
						sx={{ mt: 1 }}
						onSubmit={e => {
							handleSubmit(e);
						}}
					>
						<div>
							<TextField
								margin='normal'
								required
								fullWidth
								id='email'
								label='Email Address'
								name='email'
								autoComplete='email'
								autoFocus
								value={email}
								onChange={e => {
									setEmail(e.target.value);
								}}
							/>
							{errors.email && <div style={{ color: 'red' }}> {errors.email.toString()}</div>}
						</div>

						<div>
							<TextField
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type='password'
								id='password'
								autoComplete='current-password'
								onChange={e => {
									setPassword(e.target.value);
								}}
							/>
							{errors.password && <div style={{ color: 'red' }}> {errors.password.toString()}</div>}
						</div>

						<Button
							type='submit'
							fullWidth
							variant='contained'
							style={{ marginTop: 8, margionBottom: 2 }}
						>
							Sign In
						</Button>

						<a href={`${process.env.REACT_APP_LARAVEL_API_URL}/google/login`}>
							<Button
								type='button'
								fullWidth
								variant='contained'
								style={{ marginTop: 8, margionBottom: 2 }}
							>
								Sign In with google
							</Button>
						</a>
					</Box>
				</Box>
			</Grid>

			<Backdrop open={loading} style={{ zIndex: 10000 }}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Grid>
	);
};

export default Login;
