import React from 'react'

const AccordionTrigger = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M5.5 10.5265L9.90542 6.72726C10.0315 6.61852 10.0315 6.40912 9.90542 6.29922L5.5 2.5" stroke="white"/>
</svg>

  )
}

export default AccordionTrigger