import { Backdrop, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import * as axiosBase from 'axios';
import CommentIcon from 'components/CommentIcon/CommentIcon';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';
import WorksheetSidebar from 'components/WorksheetSidebar/WorksheetSidebar';
import parse from 'html-react-parser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import styles from './WorksheetActivity.module.css';
import ActivityDateContainer from './components/ActivityDateContainer';
import WorksheetActivityCard from './components/WorksheetActivityCard';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';



const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});
const parseOptions = {
	// replace: domNode => {
	//     if(domNode?.name === 'img'){
	//         return <img src={domNode.attribs.src} style={{maxWidth: '500px', maxHeight: '500px' }}/>
	//     }
	// }
};

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			color: "#fff",
			backgroundColor: "none",
			position: "absolute",
			top: 0,
			left: 0,
			zIndex: theme.zIndex.drawer - 1,
			opacity: 0.5,
			height: '100%',
			width: '100%',

		}
	})
);
toastr.options = {
	positionClass: 'toast-top-center',
	zIndex: 66666,
	hideDuration: 300,
	timeOut: 5000
};


const WorksheetActivity = ({ notificationCount, worksheet_id, unreadId }) => {
	//const { worksheet_id } = useParams();
	const [activity, setActivity] = useState([]);
	const [worksheet, setWorksheet] = useState({});
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
	const [selectedTask, setSelectedTask] = useState(null);
	const [activeWorksheet, setActiveWorksheet] = useState(null);
	const [loading, setLoading] = useState(false);
	const [totalHours, setTotalHours] = useState(0);
	const [metadataByWorksheetId, setMetaDataByWorksheetId] = useState({});
	const [idsOfNewComments, setIdsOfNewComments] = useState([]);
	const backDropClass = useStyles();
	const [notificationIds, setNotificationId] = useState({});
	const [notificationList, setNotificationList] = useState([])

	const navigate = useNavigate();

	const handleOpenTaskDetailsModal = comment => {
		const { task } = comment;
		setIdsOfNewComments(prev => prev.filter(id => id !== comment._id));
		setMetaDataByWorksheetId(prev => {
			if (prev[activeWorksheet]) {
				prev[activeWorksheet].tasks_with_new_comments -= 1;
			}
			return prev;
		});
		setSelectedTask(task);
		setOpenTaskDetailsModal(true);
	};
	const handleCloseTaskDetailsModal = () => setOpenTaskDetailsModal(false);

	useEffect(() => {
		if (worksheet_id) {
			setActiveWorksheet(worksheet_id);
		}
	}, [worksheet_id]);

	useEffect(() => {
		if (activeWorksheet) {
			getActivity();
			getAllWorksheetsMetadata();
			getUnreadNotifications(activeWorksheet);
		}
	}, [activeWorksheet]);

	const getActivity = () => {
		setLoading(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}/activity`;
		axios
			.get(url)
			.then(data => {
				setActivity(data.data.comments);
				setWorksheet(data.data.worksheet);
				setTotalHours(data.data.total_hours);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setLoading(false);
			});
	};

	const getAllWorksheetsMetadata = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/get-metadata-all`;
		axios
			.get(url)
			.then(res => {
				setMetaDataByWorksheetId(res.data.data);
			})
			.catch(e => console.log(e));
	};

	const getUnreadNotifications = activeWorkSheet => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification?type=unread${activeWorkSheet ? '&worksheet=' + activeWorkSheet : ''
			}`;
		axios
			.get(url)
			.then(res => {
				console.log(res.data.data);
				const newComments = res.data.data;
				const listOfIdsOfNewComments = newComments.map(comment => comment.data.comment?._id);
				newComments?.map((notif) => {
					setNotificationList((prev) => [...prev, notif])

				});
				setIdsOfNewComments(listOfIdsOfNewComments);
			})
			.catch(e => console.log(e));
	};

	const printDate = (rawdate, forTask = false) => {
		if (rawdate) {
			var date = moment(rawdate);
			var now = moment();

			if (forTask) {
				if (date.isSame(now, 'day')) {
					return 'Today';
				} else if (date.isSame(now.subtract(1, 'days'), 'day')) {
					return 'Yesterday';
				} else {
					return date.format('MMMM Do YYYY');
				}
			}
			if (now.diff(date, 'hours') < 24) {
				return date.fromNow();
			} else {
				return date.format('MMMM Do YYYY, h:mm:ss a');
			}
		}
		return '';
	};

	const handleWorksheetChange = (e, worksheet_id) => {
		e.preventDefault();

		if (worksheet_id !== activeWorksheet) {
			setActivity([]);
			setActiveWorksheet(worksheet_id);
			navigate(`/chopping-interface/${worksheet_id}`);
			//window.history.pushState('', '', `/chopping-interface/${worksheet_id}/activity`);
		}
	};

	const [isActive, setIsActive] = useState(false);
	const handleClick = () => {
		setIsActive(!isActive);
	};
	const markNotificationRead = (e,id) => {
		e.stopPropagation()

		let notificationId = '';
		notificationId=notificationList.map((item) => {
			if (item?.data?.comment?._id === id && item.data.comment._id) {
				return item._id
			}
		
		}).join('');
		console.log(notificationId)


		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notificationId}/read`;
		axios
			.post(url).then((data) => {
				toastr.success('Mark as read')

			}).catch((err) => {
				console.log(err)
				toastr.error('Something went wrong')

			})


	}
	const saveForLater = (e,id) => {
		e.stopPropagation()
		let notificationId = '';
		notificationId=notificationList.map((item) => {
			if (item?.data?.comment?._id === id && item.data.comment._id) {
				return item._id
			}
		
		}).join('');
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/notification/${notificationId}/save-for-later`;
		axios
			.post(url, {
				status: true
			}).then((data) => {
				toastr.success('Saved for later')

			}).catch((err) => {
				console.log(err)
				toastr.error('Something went wrong')

			})

	}
	return (
		<div style={{ width: '100%' }}>

			{/* <div className={isActive ? 'd-none' : 'worksheet_left_sidebar'}>
				<WorksheetSidebar
					activeWorksheet={activeWorksheet}
					notificationCount={notificationCount}
					handleWorksheetChange={handleWorksheetChange}
					metadataByWorksheetId={metadataByWorksheetId}
				/>
			</div> */}
			<div className='main-content on-mobile-padding' style={{ position: 'relative', zIndex: '1', padding: '0px' }}>
				{/* <Backdrop className={``} open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop> */}
				<Backdrop className={backDropClass.root} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				{/* <div className='d-flex justify-content-between pl-1 pr-3'>
					<div>
						<h1 className='page-title'>{worksheet?.title}</h1>
					</div>
					<div className={`px-0 d-flex justify-content-end`}>
						<OpenInFullIcon className={styles.openicon} onClick={handleClick} />
					</div>
				</div> */}

				<div style={{ width: '100%' }}>
					{/* <div style={{ marginBottom: '20px' }}>
						<span className={`pl-1 pr-3 ${styles.listingsHeading} text-normal text-muted`}>
							<Link
								className='text-normal text-muted'
								to={`/chopping-interface/${activeWorksheet}`}
							>
								<span>{totalHours}</span> hours of work
							</Link>
						</span>
						<span className={`px-3 `}>
							<span className='text-normal'>Activity</span>
						</span>
						<span className={`px-3`}>
							<Link
								className='text-normal text-muted'
								to={`/chopping-interface/${activeWorksheet}/completion/today`}
							>
								Completion
							</Link>
						</span>
					</div> */}
					{Object.keys(activity).map((value, key) => {
						return (
							<div key={key}>
								{/* <h3 className='text-center page-title'>{printDate(value, true)}</h3> */}
								<ActivityDateContainer date={printDate(value, true)} />


								{activity[value].map((comment, index) => {
									const isNewComment = idsOfNewComments?.includes(comment._id);

									return (
										<>
											<WorksheetActivityCard
												title={comment.task.title}
												actionType={comment?.type}
												avatar={comment?.user?.avatar}
												name={comment?.user?.name}
												lineage={worksheet?.title}
												time={printDate(comment.created_at)}
												content={comment.content ? parse(comment?.content, parseOptions):''}
												isUnread={isNewComment}
												markNotificationRead={(e) => markNotificationRead(e,comment._id)}
												saveForLater={(e)=>saveForLater(e,comment._id)}
												handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
												comment={comment}

											/>
											{/* <div
											className={styles.activityCard}
											style={{
												background: isNewComment ? '#2a3b35' : '#2f2f2f'
											}}
										>
											<div className={`${styles.activity}`} key={index}>
												<div className={`text-normal ${styles.icon_container}`}>
													<CommentIcon
														type={comment.type}
														className={`${styles.activity_type_icon}`}
													/>
													{comment?.user && (
														<CustomAvatar
															avatar={comment?.user?.avatar}
															alt='profile'
															name={comment?.user?.name}
															className={`${styles.activity_avatar}`}
														/>
													)}
												</div>
												<div className='text-normal d-flex align-items-center'>
													<div>
														<div>
															<NavLink
																className='text-normal'
																to={`/`}
																onClick={e => {
																	e.preventDefault();
																	handleOpenTaskDetailsModal(comment);
																}}
															>
																{comment.task.title}
															</NavLink>
														</div>
														<div className={`text-small ${styles.horizontal_list}`}>
															<li className=''>{comment?.user?.name}</li>
															<span className={`${styles.separator}`}>•</span>
															<li className=''>{worksheet?.title}</li>
															<span className={`${styles.separator}`}>•</span>
															<li className=''>{printDate(comment.created_at)}</li>
														</div>
													</div>
												</div>
											</div>
											<div className='text-normal' style={{ overflowX: 'scroll' }}>
												{parse(comment.content, parseOptions)}
											</div>
										</div> */}
										</>
									);
								})}
							</div>
						);
					})}
				</div>
				{/* <Modal
                    open={openTaskDetailsModal}
                    onClose={handleCloseTaskDetailsModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={`modal_container`}>
                        <Box className={`modal_header`}>
                            Task Details
                        </Box>
                        <Box className={`modal_body`}>
                            <TaskDetails item_id={selectedTask?._id} />
                        </Box>
                    </Box>
                </Modal> */}
				<TaskDetailsPopup
					open={openTaskDetailsModal}
					handleClose={handleCloseTaskDetailsModal}
					activeWorksheet={activeWorksheet}
					item_id={selectedTask?._id}
					openedTask={selectedTask}

				/>
			</div>
		</div>
	);
};

export default WorksheetActivity;

