import { createSlice } from '@reduxjs/toolkit';
import * as axiosBase from 'axios';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});


const dataSlice = createSlice({
  name: 'data',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    fetchPresetTasksRequest: state => {
      state.loading = true;
    },
    fetchPresetTasksSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchPresetTasksFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchPresetTasksRequest, fetchPresetTasksSuccess, fetchPresetTasksFailure } = dataSlice.actions;

export const fetchPresetTasks = () => async dispatch => {
    dispatch(fetchPresetTasksRequest());
    const url =
    `${process.env.REACT_APP_LARAVEL_API_URL}/preset-tasks`;
    axios
    .get(url)
    .then(data => {
        if(data.data.success){
            dispatch(fetchPresetTasksSuccess(data.data.data.preset_tasks));
        }else{
            dispatch(fetchPresetTasksFailure(data.data.message));
        }
    })
    .catch(e => console.log(e));
};

export default dataSlice.reducer;