import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
  body,
  #root{
    font-family: 'Poppins', 'sans-serif', 'Digital Dismay';
    background-color: #181A1E;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  }

  html {
    font-size: 62.5%;
    // DISBALE TEXT SELECTION ON PAGES
    // -moz-user-select: none;
    // -webkit-user-select: none;
    // -ms-user-select:none; 
    // user-select:none;
    // -o-user-select:none;

    @media (max-width: 800px) {
      font-size: 56.25%;
    }

    @media (max-width: 600px) {
      font-size: 50%;
    }
  }

  .fade_in_bottom {
  	animation-name: fadeInBottom;
  	animation-duration: 300ms;
  	animation-fill-mode: both;
  }

  @keyframes fadeInBottom {
  	from {
  		opacity: 0;
  		transform: translateY(60px);
  	}
  	to {
  		opacity: 1;
  	}
  }

  .fade_out_bottom {
  	animation-name: fadeOutBottom;
  	animation-duration: 300ms;
  	animation-fill-mode: both;
  }

  @keyframes fadeOutBottom {
  	from {
  		opacity: 1;
  		transform: translateY(0);
  	}
  	to {
  		opacity: 0;
      transform: translateY(60px);
  	}
  }


`;

export default GlobalStyle;
