import csvDownload from 'json-to-csv-export';

/**
 *
 * @param {Array} data CSV Content
 * @param {Array} headers CSV Headers
 * @param {Object} config CSV Config
 */
export const exportToCSV = (data, headers, config = {}) => {
	if (!data || !headers) {
		throw new Error('Data or headers are missing.');
	}
	const { filename = 'redwing-csv', delimiter = ',', ...rest } = config;
	const dataToConvert = {
		data,
		headers: headers,
		delimiter,
		filename,
		...rest
	};

	csvDownload(dataToConvert);
};

