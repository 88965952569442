import  React ,{useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import DialogTitle from '@mui/material/DialogTitle';
import styles from './Team.module.css';

export default function AddEditModal(props) {
	const {
		openModal,
		setOpenModal,
		modalFormValues,
		setModalFormValues,
		setTeam,
		content,
		modalOperation,
		setModalOperation,
		modalHeaderText,
		modalSubTitle,
		setModalSubTitle,
		modalAgreeText,
		modalCancelText,
		handleModalSubmit,
		user_id,
		setErrorMessage
	} = props;

	useEffect(() => {
		console.log('mou');
		return () => {
			console.log('un mou');
			
		};
	}, []);
	const handleClose = () => {
		setOpenModal(false);
	//	window.location.reload()
		setModalFormValues({
				name: '',
				email: '',
				role: '',
				daily_hours_to_complete: ''
			});
			setModalSubTitle("")
			setModalOperation("edit")
			setErrorMessage({
				name: '',
				email: '',
				role: '',
				daily_hours_to_complete: ''
			});
		
	};

	return (
		<Dialog
			open={openModal}
			onClose={handleClose}
			PaperProps={{
				sx: {
					width: '30%'
				}
			}}
		>
			<DialogTitle
				sx={{
					paddinng: '1rem'
				}}
			>
				{modalHeaderText}
			</DialogTitle>
			<DialogContentText
				sx={{
					fontSize: '16px',
					color: 'lightgreen',
					padding: '0 1rem '
				}}
			>
				{modalSubTitle}
			</DialogContentText>
			<div className={styles.addEditModal}>
				<form onSubmit={handleModalSubmit} className={styles.form}>
					{content}

					<DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Button onClick={handleClose}>{modalCancelText}</Button>
						<Button type='submit'>{modalAgreeText}</Button>
					</DialogActions>
				</form>
			</div>
		</Dialog>
	);
}
