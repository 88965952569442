import { Backdrop, Box, Breadcrumbs, CircularProgress, Modal, Typography } from '@material-ui/core';
import * as axiosBase from 'axios';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { Link, useParams, useNavigate } from 'react-router-dom';
import styles from './UserUnplannedTasks.module.css';
import { GrNext } from 'react-icons/gr';
import { IconContext } from 'react-icons';
import { BsChevronRight } from 'react-icons/bs';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';
import API_URLS from 'screens/Team/apiUrls';
import TaskIcon from 'components/TaskIcon/TaskIcon';
import TaskDetails from 'screens/ChoppingInterface/TaskDetails';
import { TaskDetailsPopup } from 'components/TaskDetailsPopup/TaskDetailsPopup';

const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

const UserUnassignedTasks = () => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [activeUser, setActiveUser] = useState(undefined);
	const [tasks, setTasks] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [hideChilds, setHideChilds] = useState([]);
	const { user_id } = useParams();
	const [selectedTask, setSelectedTask] = useState(null);
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);

	// ---------------------------------------------------------

	const handleOpenTaskDetailsModal = task => {
		console.log(task);
		setSelectedTask(task);
		setOpenTaskDetailsModal(true);
	};
	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
	};

	// ---------------------------------------------------------

	// useEffect(() => {
	// 	setLoading(true);
	// 	axios
	// 		.get(`${process.env.REACT_APP_LARAVEL_API_URL}/users`)
	// 		.then(res => {
	// 			setLoading(false);
	// 			console.log({ res });
	// 			setUsers(res.data.users);
	// 		})
	// 		.catch(error => {
	// 			console.error(error);
	// 		});
	// }, []);

	// useEffect(() => {
	// 	if (user_id) {
	// 		let user = users.find(value => {
	// 			return value._id == user_id;
	// 		});
	// 		setTasks([]);
	// 		setActiveUser(user);
	// 	}
	// }, [user_id, users]);

	const getTodoList = () => {
		setLoading(true);

		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/task/unassigned`)
			.then(data => data.data)
			.then(data => {
				console.log({ data });
				setLoading(false);
				setTasks(data.tasks);
				setWorksheets(data.worksheets);

				console.log({ data });
			})
			.catch(e => console.log(e));
	};

	// taking user_id when clicking on a user and setting it as activeUser and changing id in url as well
	const handleUserChange = (e, user_id) => {
		e.preventDefault();
		window.history.pushState('', '', `/tasks/unassigned/${user_id}`);

		if (user_id !== activeUser?._id || !activeUser) {
			let user = [...users].filter(value => {
				return value._id === user_id;
			})[0];
			setTasks([]);
			setActiveUser(user);
		}
	};

	// fetching user task when it changes
	useEffect(() => {
		getTodoList();
	}, [activeUser]);

	return (
		<div>
			{/* <Backdrop className={styles.backdrop} open={loading}  style={{ zIndex: 10000 }}>
				<CircularProgress color='inherit' />
			</Backdrop> */}
			<div className={`body_container ${styles.main}`}>
				<div className={styles.leftSidebar}>
					<div>
						{users?.map((user, index) => {
							return (
								user.name && (
									<div key={index} style={{ display: 'flex', gap: '5px', marginBottom: '1vh' }}>
										<CustomAvatar
											avatar={user.avatar}
											alt='profile'
											name={user.name}
											style={{
												width: '24px',
												height: '24px',
												fontSize: '10px',
												borderRadius: '100%'
											}}
										/>
										<div>
											<div
												style={
													user._id === user_id
														? {
																cursor: 'pointer',
																color: 'white'
														  }
														: {
																cursor: 'pointer',
																color: 'grey'
														  }
												}
											>
												<Link
													onClick={e => handleUserChange(e, user._id)}
													to='handling_in_handleUserChange'
													//		to={`/tasks/unplanned/${user?._id}`}
													// to={`../${user?._id}`}
													style={{ color: 'white' }}
												>
													{user.name}
												</Link>
											</div>
											{/* <div style={{ display: 'flex' }}>
                                            {dots ? (
                                                dots.map(x => {
                                                    if (x.user_id === user.user_id) {
                                                        return (
                                                            // <div style={{ cursor: 'pointer' }} className='leavesCount'>
                                                            // 	<GoPrimitiveDot color={getColor(x.weekly_hours_percentage)} />
                                                            // 	<div className='LeavesCounttip'>
                                                            // 		<div>{moment(x.start_date).format('D MMM YYYY')}</div>
                                                            // 		<div>{moment(x.end_date).format('D MMM YYYY')}</div>
                                                            // 		<div>{x.weekly_hours_percentage}%</div>
                                                            // 	</div>
                                                            // </div>
                                                            x.weekly_report.map(y => {
                                                                return (
                                                                    <div
                                                                        style={{ cursor: 'pointer' }}
                                                                        className='leavesCount'
                                                                    >
                                                                        <GoPrimitiveDot
                                                                            color={getColor(y.weekly_hours_percentage)}
                                                                        />
                                                                        <div className='LeavesCounttip'>
                                                                            <div>{moment(y.start_date).format('D MMM YYYY')}</div>
                                                                            <div>{moment(y.end_date).format('D MMM YYYY')}</div>
                                                                            <div>{y.weekly_hours_percentage}%</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        );
                                                    }
                                                })
                                            ) : (
                                                <PulseLoader
                                                    color='#4FFFB0'
                                                    loading
                                                    size={6}
                                                    style={{ marginBottom: '5px' }}
                                                />
                                            )}
                                        </div> */}
										</div>
									</div>
								)
							);
						})}
					</div>
				</div>
				<div className={`main-content`}>
					<h1 className={`page-title`}>
						Unassigned Task {activeUser?.name && `of ${activeUser.name}`}
					</h1>

					<div style={{ zIndex: '10', width: '99%' }} className={styles.trans}>
						{tasks.length > 0 &&
							tasks
								.filter(function (item) {
									return item.parent_id === 0 || item.parent_id === '';
								})
								.sort((a, b) => a.seq - b.seq)
								.map((item, index) => {
									return (
										<ListItem
											key={item._id}
											item={item}
											index={index}
											tasks={tasks}
											hideChilds={hideChilds}
											setHideChilds={setHideChilds}
											handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
											activeUser={activeUser}
											worksheets={worksheets}
										/>
									);
								})}
					</div>
				</div>
			</div>
			{/* <Modal
				open={openTaskDetailsModal}
				onClose={handleCloseTaskDetailsModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box className={`modal_container`}>
					<Box className={`modal_body`}>
						<div>
							<div className={`fa_cross_icon`}>
								<FaTimes size={10} onClick={handleCloseTaskDetailsModal} />
							</div>
						</div>
						<TaskDetails
							// activeWorksheet={activeWorksheet}
							item_id={selectedTask?._id}
							// updateTaskOnChoppingInterface={updateTaskFromTaskDetailsModal}
						/>
					</Box>
				</Box>
			</Modal> */}
			    <TaskDetailsPopup
					open={openTaskDetailsModal}
					handleClose={handleCloseTaskDetailsModal}
					item_id={selectedTask?._id}
				/>
		</div>
	);
};

const ListItem = ({
	index,
	item,
	tasks,
	hideChilds,
	setHideChilds,
	handleOpenTaskDetailsModal,
	activeUser,
	worksheets,
	depth = 0
}) => {
	const filteredTodo = tasks
		.filter(function (childItem) {
			return childItem.parent_id === item._id;
		})
		.sort((a, b) => a.seq - b.seq);
	const parent = [...tasks].filter(function (value) {
		return value._id !== item.parent_id;
	})[0];

	//console.log({ activeUser });
	return (
		<div className={styles.rowContainer} key={index}>
			{activeUser && activeUser._id !== item.assginee && (
				<div>
					{filteredTodo.length > 0 && filteredTodo[0].assginee === activeUser._id && (
						<div>
							<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
						</div>
					)}
					{!hideChilds.includes(item._id) &&
						filteredTodo.map((childItem, childIndex) => {
							var childPrevItem = tasks.filter(function (childItem) {
								return childItem.parent_id === item._id;
							})[childIndex === 0 ? 0 : childIndex - 1];
							return (
								<ListItem
									key={'child-' + childItem._id}
									item={childItem}
									index={childIndex}
									tasks={tasks}
									depth={depth + 1}
									hideChilds={hideChilds}
									handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
									setHideChilds={setHideChilds}
									activeUser={activeUser}
									worksheets={worksheets}
								/>
							);
						})}
				</div>
			)}
			{activeUser &&
				(item.parent_id === 0 || item.parent_id === '') &&
				activeUser._id === item.assginee && (
					<div>
						{filteredTodo.length > 0 && filteredTodo[0].assginee === activeUser._id && (
							<div>
								<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
							</div>
						)}
					</div>
				)}
			{(item.parent_id === 0 || item.parent_id === '') && !activeUser && (
				<div>
					<div>
						<DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
					</div>
				</div>
			)}
			{((activeUser && activeUser._id === item.assginee) || !activeUser) && (
				<div
					className='d-flex'
					style={{
						width: '100%',
						marginLeft: item.parent_id === '' ? '0px' : '15px'
					}}
				>
					{filteredTodo.length > 0 && hideChilds.includes(item._id) && (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ width: '20px', cursor: 'pointer', marginLeft: '-20px', marginBottom: '5px' }}
						>
							<AiOutlineRight
								onClick={e => {
									setHideChilds(prev => {
										return prev.filter(function (value) {
											return value !== item._id;
										});
									});
								}}
							/>
						</div>
					)}

					{/* checkbox */}
					<div style={{ width: '20px' }}>
						<div className='d-flex justify-content-center'>
							<TaskIcon item={item} />
						</div>
						{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
							<div
								className={`d-flex justify-content-center align-items-center ${styles.verticalLinesContainer}`}
								onClick={e => {
									setHideChilds(prev => [...prev, item._id]);
								}}
							>
								{filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
									<div className={styles.verticalLines}></div>
								)}
							</div>
						)}
					</div>

					<div style={{ width: 'calc(100% - 30px)' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
								marginLeft: `10px`,
								marginBottom: '5px'
							}}
						>
							{/* estimate */}
							{
								<div className='d-flex align-items-end' style={{}}>
									{item.estimate !== 0 ? (
										<div
											className='d-flex align-items-end'
											style={{
												// border: `1px solid ${
												// 	item.estimate <= 2 || filteredTodo.length > 0
												// 		? '#5FB924'
												// 		: item.estimate > 2 && item.estimate < 5
												// 		? '#FCBA03'
												// 		: '#FF0000'
												// }`,

												// textAlign: 'center',
												borderRadius: '12px',
												color: `${
													item.estimate <= 2 || filteredTodo.length > 0
														? '#5FB924'
														: item.estimate > 2 && item.estimate < 5
														? '#FCBA03'
														: '#FF0000'
												}`,
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : item.estimate !== 0 && item.completed ? (
										<div
											className='d-flex align-items-end'
											style={{
												// background: '#5FB924',

												// textAlign: 'center',
												// borderRadius: '12px',
												fontSize: '12px',
												lineHeight: '13px',
												// border: '1px solid #5FB924',
												padding: '1px 0',
												color: '#5FB924',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
										</div>
									) : (
										<div
											className='d-flex align-items-end'
											style={{
												// textAlign: 'center',
												borderRadius: '12px',
												fontSize: '12px',
												lineHeight: '13px',
												padding: '1px 0',
												opacity: '60%',
												fontFamily: 'Roboto Mono',
												fontStyle: 'normal'
											}}
										>
											<p className='m-0 p-0'>0.00</p>
										</div>
									)}
								</div>
							}

							{/* input value */}
							<div style={{ position: 'relative', width: '100%' }}>
								<span
									style={{
										background: 'transparent',
										color: 'white',
										border: 0,
										fontFamily: 'Poppins',
										fontSize: '13px',
										width: '100%',
										cursor: "pointer",
										opacity:
											item.estimate === 0 &&
											(filteredTodo.length === 0 ||
												filteredTodo
													.map(value => value.estimate)
													.reduce((prev, next) => prev + next) === 0)
												? '60%'
												: '100%'
									}}
									onClick={()=>{handleOpenTaskDetailsModal(item)}}
								>
									{item.title !== '' ? item.title : ''}
								</span>
							</div>
							{/* {item.assginee !== 0 &&
                            users &&
                            users.map(user => {
                                if (user.user_id === Number(item.assginee)) {
                                    return <div style={{ color: '#7A8AED' }}>{`@${user.name}`}</div>;
                                }
                            })} */}
						</div>

						{!hideChilds.includes(item._id) &&
							filteredTodo.map((childItem, childIndex) => {
								var childPrevItem = tasks.filter(function (childItem) {
									return childItem.parent_id === item._id;
								})[childIndex === 0 ? 0 : childIndex - 1];
								return (
									<ListItem
										key={'child-' + childItem._id}
										item={childItem}
										index={childIndex}
										tasks={tasks}
										depth={depth + 1}
										handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
										hideChilds={hideChilds}
										setHideChilds={setHideChilds}
										activeUser={activeUser}
										worksheets={worksheets}
									/>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
};

export default UserUnassignedTasks;
