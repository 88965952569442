import React from 'react'
import './NotificationComponentsStyles.css'

const SegmentDivider = ({name}) => {
  return (
    <div className='segment-divider'>
      <span>{name?.toUpperCase()}</span>
      <div className='segment-line'></div>

    </div>
  )
}

export default SegmentDivider