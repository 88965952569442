import React, { useEffect, useState } from 'react';
import globalStyles from '../BigDashboard/BigDashboard.module.css';
import styles from './Timesheet.module.css';
import * as axiosBase from 'axios';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import { Backdrop, Box, Button, CircularProgress } from '@material-ui/core';
import UserTasks from './components/UserTasks';
import { exportToCSV } from 'utils/csvUtils';

const accessToken = localStorage.getItem('access_token');

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});

const initialSelectionData = {
	group: null,
	worksheet: null,
	teamMember: null,
	month: null
};

const FETCH_TASKS_STATUS = {
	IDLE: 'IDLE',
	IN_PROGRESS: 'IN_PROGRESS',
	FINISHED: 'FINISHED'
};

const listOfMonths = [
	{ label: 'January', value: 1 },
	{ label: 'February', value: 2 },
	{ label: 'March', value: 3 },
	{ label: 'April', value: 4 },
	{ label: 'May', value: 5 },
	{ label: 'June', value: 6 },
	{ label: 'July', value: 7 },
	{ label: 'August', value: 8 },
	{ label: 'September', value: 9 },
	{ label: 'October', value: 10 },
	{ label: 'November', value: 11 },
	{ label: 'December', value: 12 }
];

const Timesheet = () => {
	const initialWindowSize = (() => {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	})();

	const [isLoadingGroups, setIsLoadingGroups] = useState(false);
	const [worksheetGroups, setWorksheetGroups] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [worksheets, setWorksheets] = useState([]);

	const [selectionData, setSelectionData] = useState({
		...initialSelectionData
	});
	const [windowSize, setWindowSize] = useState(initialWindowSize);
	const [isMobile, setIsMobile] = useState(false);
	const [isFetchingTasks, setIsFetchingTasks] = useState(FETCH_TASKS_STATUS.IDLE);
	const [totalEstimate, setTotalEstimate] = useState(0);
	const [worksheetsOfSelectedGroup, setWorksheetsOfSelectedGroup] = useState([]);
	const [selectedTask, setSelectedTask] = useState(null);
	const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);

	const handleOpenTaskDetailsModal = task => {
		setSelectedTask(task);
		setOpenTaskDetailsModal(true);
	};
	const handleCloseTaskDetailsModal = () => {
		setOpenTaskDetailsModal(false);
	};

	// Filter only child task which has no children
	const filterTasks = listOfTasks => {
		const parentTaskIds = listOfTasks.filter(task => !!task.parent_id).map(task => task.parent_id);
		const filteredTasks = listOfTasks.filter(task => !parentTaskIds.includes(task._id));
		return filteredTasks;
	};

	const getWorksheetList = () => {
		setIsLoadingGroups(true);
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
		axios
			.get(url)
			.then(data => {
				const allWorksheets = data.data.worksheets;
				let includedWorksheetGroups = new Set();
				const tempWorksheetGroups = [];
				allWorksheets.forEach(worksheet => {
					if (includedWorksheetGroups.has(worksheet.client_name)) {
						const insertedGroupIdx = tempWorksheetGroups.findIndex(
							group => group.value === worksheet.client_name
						);
						tempWorksheetGroups[insertedGroupIdx].access.concat(worksheet.access);
					} else {
						includedWorksheetGroups.add(worksheet.client_name);
						tempWorksheetGroups.push({
							label: worksheet.client_name,
							value: worksheet.client_name,
							access: worksheet.access
						});
					}
				});
				setWorksheetGroups(tempWorksheetGroups);
				setWorksheets(allWorksheets);
			})
			.catch(e => console.log(e))
			.finally(e => {
				setIsLoadingGroups(false);
			});
	};

	const getTodoList = async () => {
		if (!selectionData.teamMember) {
			return;
		}
		setIsFetchingTasks(FETCH_TASKS_STATUS.IN_PROGRESS);
		const filters = {
			year: new Date().getFullYear(),
			month: selectionData.month?.value,
			client_name: selectionData.group?.value,
			user_id: selectionData.teamMember?._id
		};
		if (selectionData.worksheet?._id) {
			filters.worksheet_id = selectionData.worksheet?._id;
		}
		if (selectionData.teamMember?._id) {
			filters.user_id = selectionData.teamMember?._id;
		}
		const queryString = new URLSearchParams(filters).toString();
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/timesheet?${queryString}`;

		await axios
			.get(url, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then(res => {
				const completedTasks = res.data.data;
				const filteredCompletedTasks = completedTasks;
				setIsFetchingTasks(FETCH_TASKS_STATUS.FINISHED);
				setTasks(filteredCompletedTasks);
				const totalTimeTaken = completedTasks.reduce((acc, task) => acc + task.estimate, 0);
				setTotalEstimate(totalTimeTaken);
			})
			.catch(e => {
				console.log(e);
				setIsFetchingTasks(FETCH_TASKS_STATUS.FINISHED);
			});
	};

	const handleClickViewCompletedTasksBtn = async () => {
		getTodoList();
	};

	const handleExportCSVBtnClick = () => {
		const data = filterTasks(tasks)
			.sort((a, b) => a.seq - b.seq)
			.map(task => ({
				task: task.title,
				timeSpent: task.estimate
			}));

		const headers = ['Task', 'Time Spent'];
		exportToCSV(data, headers);
	};

	const handleChangeSelectionData = (key, value) => {
		setTasks([]);
		setSelectionData(prev => ({
			...prev,
			[key]: value
		}));
	};

	useEffect(() => {
		getWorksheetList();
	}, []);

	useEffect(() => {
		if (windowSize.innerWidth <= 600) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [windowSize.innerWidth]);

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(initialWindowSize);
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	let resultNode;

	if (isFetchingTasks === FETCH_TASKS_STATUS.FINISHED) {
		if (tasks.length) {
			if (isMobile) {
				resultNode = (
					<Box className={styles.swiperContainer}>
						<div style={{ height: '100%', width: '100%', zIndex: '9999', overflowY: 'auto' }}>
							<UserTasks
								tasks={tasks}
								user={selectionData.teamMember}
								totalEstimate={totalEstimate}
								worksheets={worksheets}
								filters={selectionData}
							/>
						</div>
					</Box>
				);
			} else {
				resultNode = (
					<Box
						style={{
							padding: '16px 0px'
						}}
					>
						<UserTasks
							tasks={tasks}
							activeUser={selectionData.teamMember}
							totalEstimate={totalEstimate}
							hideChilds={[]}
							setHideChilds={() => {}}
							worksheets={worksheets}
							handleOpenTaskDetailsModal={handleOpenTaskDetailsModal}
							selectedTask={selectedTask}
							handleCloseTaskDetailsModal={handleCloseTaskDetailsModal}
							openTaskDetailsModal={openTaskDetailsModal}
						/>
					</Box>
				);
			}
		} else {
			resultNode = (
				<Box p={2} mt={10}>
					<h3 style={{ textAlign: 'center', color: 'white' }}>No Tasks Found</h3>
				</Box>
			);
		}
	}

	return (
		<div
			className={globalStyles.global}
			style={{
				padding: '0 32px',
				paddingBottom: 0
			}}
		>
			<Helmet>
				<meta name='apple-mobile-web-app-capable' content='yes' />
			</Helmet>
			<Box
				style={{
					backgroundColor: '#181A1E',
					padding: '24px 0',
					position: 'sticky',
					top: 0,
					zIndex: 1
				}}
			>
				<h1 style={{ color: '#fff' }}>Timesheet</h1>

				<Box
					mt='20px'
					style={{
						display: 'flex',
						gap: '16px'
					}}
				>
					<Select
						name='worksheet_group'
						options={worksheetGroups}
						classNamePrefix='select'
						placeholder='Select Worksheet Group'
						isLoading={isLoadingGroups}
						loadingMessage={() => <p>Loading Groups...</p>}
						styles={{
							input: styles => ({
								...styles,
								width: '100px'
							}),
							menuLists: styles => ({
								...styles,
								color: 'black'
							}),
							option: styles => ({
								...styles,
								color: 'black',
								zIndex: '99999'
							})
						}}
						onChange={value => {
							handleChangeSelectionData('group', value);
							const members = value.access
								.filter(member => !!member.user)
								.map(member => member.user);
							const worksheetsOfCurrentGroup = worksheets.filter(
								worksheet => worksheet.client_name === value.label
							);
							setTeamMembers(members);
							setWorksheetsOfSelectedGroup(worksheetsOfCurrentGroup);
						}}
						value={selectionData.group}
					/>
					<Select
						name='worksheet'
						options={worksheetsOfSelectedGroup}
						getOptionLabel={worksheet => worksheet.title}
						getOptionValue={worksheet => worksheet._id}
						isDisabled={!selectionData.group}
						classNamePrefix='select'
						placeholder='Select Worksheet'
						styles={{
							input: styles => ({
								...styles,
								width: '100px'
							}),
							menuLists: styles => ({
								...styles,
								color: 'black'
							}),
							option: styles => ({
								...styles,
								color: 'black',
								zIndex: '99999'
							})
						}}
						onChange={value => {
							handleChangeSelectionData('worksheet', value);
						}}
						value={selectionData.worksheet}
					/>
					<Select
						name='team_member'
						options={teamMembers}
						getOptionLabel={member => member.name}
						getOptionValue={member => member._id}
						classNamePrefix='select'
						placeholder='Select Team Member'
						styles={{
							input: styles => ({
								...styles,
								width: '100px'
							}),
							menuLists: styles => ({
								...styles,
								color: 'black'
							}),
							option: styles => ({
								...styles,
								color: 'black',
								zIndex: '99999'
							})
						}}
						onChange={val => {
							handleChangeSelectionData('teamMember', val);
						}}
						value={selectionData.teamMember}
					/>
					<Select
						name='month'
						options={listOfMonths}
						classNamePrefix='select'
						placeholder='Select Month'
						styles={{
							input: styles => ({
								...styles,
								width: '80px'
							}),
							menuLists: styles => ({
								...styles,
								color: 'black'
							}),
							option: styles => ({
								...styles,
								color: 'black',
								zIndex: '99999'
							})
						}}
						onChange={value => {
							handleChangeSelectionData('month', value);
						}}
						value={selectionData.month}
					/>
					<Button
						size='small'
						style={{
							background: '#fff'
						}}
						onClick={handleClickViewCompletedTasksBtn}
					>
						View
					</Button>
				</Box>
				{tasks.length ? (
					<Button
						size='small'
						variant='contained'
						color='primary'
						onClick={handleExportCSVBtnClick}
						style={{
							display: 'block',
							marginTop: '16px',
							marginLeft: 'auto'
						}}
					>
						Export as CSV
					</Button>
				) : null}
			</Box>

			{isFetchingTasks === FETCH_TASKS_STATUS.IN_PROGRESS ? (
				<Box
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 'calc(100vh - 160px)'
					}}
					mt='20px'
				>
					<CircularProgress
						size={50}
						style={{
							color: '#fff'
						}}
					/>
				</Box>
			) : null}

			{resultNode}
		</div>
	);
};

export default Timesheet;

