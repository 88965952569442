import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import useLongPress from '../../hooks/useLongPress';
import { Link, useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { BsRecordCircleFill } from 'react-icons/bs';
import present from '../../assets/icons/present.svg';
import absent from '../../assets/icons/absent.svg';
import {
	ClickAwayListener,
	FormControl,
	Grid,
	Input,
	InputLabel,
	Modal,
	Select,
	Snackbar,
	Tooltip,
	Typography
} from '@material-ui/core';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentLoader from 'react-content-loader';
import Button from '@mui/material/Button';
import { position } from 'polished';
import { style } from '@mui/system';
import '../TeamWork/TeamWork.css';
import { ModalBody } from '../TeamWork/Style';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Alert } from '@material-ui/lab';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');
function copyToClip(str) {
	function listener(e) {
		e.clipboardData.setData('text/html', str);
		e.clipboardData.setData('text/plain', str);
		e.preventDefault();
	}
	document.addEventListener('copy', listener);
	document.execCommand('copy');
	document.removeEventListener('copy', listener);
}
function NewTeamTableChopping({
	onlyRedwing,
	users,
	isPerformance5,
	isPerformance15,
	isPerformance0,
	default1,
	sorting,
	notredwing,
	sortingColumn,
	setSortingColumn,
	sortingOrder,
	projects,
	data,
	getTeamWorkData,
	setLoading,
	isPlayground,
	isMultitasking,
	isProjectSame,
	isIdle,
	projectId,
	toggel,
	singleUsers,
	projectName
}) {
	const [newUsers, SetNewUsers] = useState([]);
	var dummyData = users;
	const redwingProjectId = 23190856;
	const [totalTasks, setTotalTasks] = useState(null);
	const [onHoldTasks, setOnHoldTasks] = useState(0);
	const [activityCount, setActivityCount] = useState(0);
	const [onboardUserModal, setOnboardUserModal] = useState(false);
	const [onboardUserName, setOnboardUserName] = useState('');
	const [onboardUserEmail, setOnboardUserEmail] = useState('');
	const [onboardProjectId, setonboardProjectId] = useState('');
	const [totalUnplanned, setTotalUnplanned] = useState(null);

	var localData = localStorage.getItem('redwing_data_chopping');
	// If no tasks --> Table name = Idle and at the last
	// If only on one project --> Table name = project name
	// If more than 1 project --> Table name = Multi tasking and at the top
	localData = JSON.parse(localData);
	// console.log(dummyData);

	useEffect(() => {
		let users = localData?.users;
		let total = 0;
		users?.map(item => {
			total += item.unplanned_tasks;
		});
		setTotalUnplanned(total);
	}, [localData]);

	useEffect(() => {
		if (notredwing && !default1) {
			SetNewUsers(
				users.filter(
					user => !user.project_ids.includes(redwingProjectId) || user.project_ids.length > 1
				)
			);
			setTotalTasks(
				users
					.filter(
						user => !user.project_ids.includes(redwingProjectId) || user.project_ids.length > 1
					)
					.map(each => each.team_load_tasks)
					.reduce((acc, item) => acc + item, 0)
			);
			setOnHoldTasks(
				users
					.filter(
						user => !user.project_ids.includes(redwingProjectId) || user.project_ids.length > 1
					)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (isPlayground && !default1) {
			SetNewUsers(
				users.filter(
					user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
				)
			);
			setTotalTasks(
				users
					.filter(
						user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
					)
					.map(each => each.team_load_tasks)
					.reduce((acc, item) => acc + item, 0)
			);
			setOnHoldTasks(
				users
					.filter(
						user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
					)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (isMultitasking && !default1) {
			SetNewUsers(users.filter(user => user.project_ids.length > 1));
			setTotalTasks(
				users
					.filter(user => user.project_ids.length > 1)
					.map(each => each.team_load_tasks)
					.reduce((acc, item) => acc + item, 0)
			);
			setOnHoldTasks(
				users
					.filter(user => user.project_ids.length > 1)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (isProjectSame && !default1) {
			SetNewUsers(
				users.filter(
					user =>
						user.project_ids.length === 1 &&
						!user.project_ids.includes(redwingProjectId) &&
						user.project_ids[0] === projectId
				)
			);
			setTotalTasks(
				users
					.filter(
						user =>
							user.project_ids.length === 1 &&
							!user.project_ids.includes(redwingProjectId) &&
							user.project_ids[0] === projectId
					)
					.map(each => each.tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
			setOnHoldTasks(
				users
					.filter(
						user =>
							user.project_ids.length === 1 &&
							!user.project_ids.includes(redwingProjectId) &&
							user.project_ids[0] === projectId
					)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (isIdle && !default1) {
			SetNewUsers(users.filter(user => user.tasks_count === 0));
			setOnHoldTasks(
				users
					.filter(user => user.tasks_count === 0)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (onlyRedwing && !default1) {
			SetNewUsers(
				users.filter(
					user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
				)
			);
			setTotalTasks(
				users
					.filter(
						user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
					)
					.map(each => each.team_load_tasks)
					.reduce((acc, item) => acc + item, 0)
			);
			setOnHoldTasks(
				users
					.filter(
						user => user.project_ids.includes(redwingProjectId) && user.project_ids.length === 1
					)
					.map(each => each.on_hold_tasks_count)
					.reduce((acc, item) => acc + item, 0)
			);
		}
		if (isPerformance5 && !default1) {
			const boolValue = users.filter(user => user.completed_todo > 5);
			if (boolValue === null || boolValue === undefined) {
				SetNewUsers([]);
			} else {
				SetNewUsers(users.filter(user => user.completed_todo > 5));
				setTotalTasks(
					users
						.filter(user => user.completed_todo > 5)
						.map(each => each.team_load_tasks)
						.reduce((acc, item) => acc + item, 0)
				);
				setOnHoldTasks(
					users
						.filter(user => user.completed_todo > 5)
						.map(each => each.on_hold_tasks_count)
						.reduce((acc, item) => acc + item, 0)
				);
			}
		}
		if (isPerformance15 && !default1) {
			const boolValue = users.filter(user => user.completed_todo <= 5 && user.completed_todo >= 1);
			if (boolValue === null || boolValue === undefined) {
				SetNewUsers([]);
			} else {
				SetNewUsers(users.filter(user => user.completed_todo <= 5 && user.completed_todo >= 1));
				setTotalTasks(
					users
						.filter(user => user.completed_todo <= 5 && user.completed_todo >= 1)
						.map(each => each.team_load_tasks)
						.reduce((acc, item) => acc + item, 0)
				);
				setOnHoldTasks(
					users
						.filter(user => user.completed_todo <= 5 && user.completed_todo >= 1)
						.map(each => each.on_hold_tasks_count)
						.reduce((acc, item) => acc + item, 0)
				);
			}
		}
		if (isPerformance0 && !default1) {
			const boolValue = users.filter(user => user.completed_todo < 1);
			if (boolValue === null || boolValue === undefined) {
				SetNewUsers([]);
			} else {
				SetNewUsers(users.filter(user => user.completed_todo < 1));
				setTotalTasks(
					users
						.filter(user => user.completed_todo < 1)
						.map(each => each.team_load_tasks)
						.reduce((acc, item) => acc + item, 0)
				);
				setOnHoldTasks(
					users
						.filter(user => user.completed_todo < 1)
						.map(each => each.on_hold_tasks_count)
						.reduce((acc, item) => acc + item, 0)
				);
			}
		}
		if (
			default1 &&
			!isMultitasking &&
			!isProjectSame &&
			!isPerformance5 &&
			!isIdle &&
			!onlyRedwing
		) {
			SetNewUsers(users);
			setTotalTasks(users.map(each => each.team_load_tasks).reduce((acc, item) => acc + item, 0));
			setOnHoldTasks(
				users.map(each => each.on_hold_tasks_count).reduce((acc, item) => acc + item, 0)
			);
		}
		setActivityCount(
			newUsers.map(user => user.completed_todo).reduce((acc, item) => acc + item, 0)
		);
	}, [
		newUsers,
		users,
		notredwing,
		default1,
		isPlayground,
		isMultitasking,
		isProjectSame,
		isIdle,
		onlyRedwing,
		isPerformance5,
		isPerformance15,
		isPerformance0,
		projectId
	]);

	const handleOnboardToProject = (user_id, user_name) => {
		setOnboardUserModal(true);
		setOnboardUserName(user_name);
	};

	const onboardToProject = () => {
		if (onboardProjectId && onboardUserEmail) {
			setLoading(true);
			setOnboardUserModal(false);
			axios
				.get(
					`${process.env.REACT_APP_API_URL}/pages/join_project.php?name=${onboardUserName}&email=${onboardUserEmail}&work_project_id=${onboardProjectId}`,
					{
						headers: {
							Authorization: `${token}`,
							'Access-Control-Allow-Origin': '*'
						}
					}
				)
				.then(res => {
					if (res.data.status === true) {
						alert(res.data.msg);
						setOnboardUserName('');
						setOnboardUserEmail('');
						setonboardProjectId('');
						getTeamWorkData();
						// getProjectData();
					} else {
						setOnboardUserModal(true);
						alert('Something went wrong');
						// console.log(res.data);
					}
					setLoading(false);
				})
				.catch(error => {
					console.error(error);
					setLoading(false);
				});
		}
	};

	const renderTableHeading = () => {
		return (
			<thead>
				<tr>
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('name');
							if (sortingOrder === 'ASC') {
								sorting('name', 'ASC');
							} else {
								sorting('name', 'DEC');
							}
						}}
						style={{
							// transform: 'translateX(-6px)',
							fontSize: '14px',
							lineHeight: '21px',
							fontFamily: 'Poppins',
							fontWeight: '500',
							minWidth: '170px'
						}}
					>
						{/* {isPlayground && "Redwing"} */}
						{isMultitasking && `Multitasking (${newUsers.length})`}
						{isProjectSame && `${projectName} (${newUsers.length})`}
						{isIdle && `Idle (${newUsers.length})`}
						{notredwing && `Client Projects (${newUsers.length})`}
						{onlyRedwing && `Playground (${newUsers.length})`}
						{default1 && `All Users (${users.length})`}
						{isPerformance0 && `0 green ticks (${newUsers.length})`}
						{isPerformance15 && `1-5 green ticks (${newUsers.length})`}
						{isPerformance5 && `5+ green ticks (${newUsers.length})`}
						{isPlayground && ` Playground User (${newUsers.length})`}
						{sortingColumn === 'name' ? (
							<a href='/' style={{ color: 'white', marginLeft: '2px' }}>
								{sortingOrder === 'ASC' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
							</a>
						) : (
							''
						)}
					</th>

					{/* today */}
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('daily_hours_percentage');
							if (sortingOrder === 'ASC') {
								sorting('daily_hours_percentage', 'ASC');
							} else {
								sorting('daily_hours_percentage', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
									textAlign: 'left',
									//paddingRight: '5rem',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '100%'
									minWidth: '130px'
								}
								: {
									textAlign: 'left',
									paddingRight: '5rem',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '100%',
									position: 'relative',
									//right: '545px'
									minWidth: '130px'
								}
						}

					// className="activity"
					>
						<div style={{ display: 'flex' }}>
							<div className='activity-heading' style={{ cursor: 'pointer' }}>
								Today
								{localData.completed_tasks !== 0 && '(' + localData.completed_tasks + ')'}
							</div>
							<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
								{sortingOrder === 'ASC' && sortingColumn === 'daily_hours_percentage' ? (
									<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
								) : sortingOrder === 'DEC' && sortingColumn === 'daily_hours_percentage' ? (
									<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
								) : (
									<></>
								)}
							</a>
						</div>
					</th>

					{/* this week */}
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('weekly_hours_percentage');
							if (sortingOrder === 'ASC') {
								sorting('weekly_hours_percentage', 'ASC');
							} else {
								sorting('weekly_hours_percentage', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
									// display: 'flex',
									// justifyContent: 'center',
									textAlign: 'center',
									paddingRight: '5rem',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500'
									//width: '100%'
								}
								: {
									textAlign: 'center',
									paddingRight: '5rem',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '100%',
									position: 'relative'
									//right: '545px'
								}
						}

					// className="activity"
					>
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
								This Week
								{/* {`(${activityCount})`} */}
							</div>
							<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
								{sortingOrder === 'ASC' && sortingColumn === 'weekly_hours_percentage' ? (
									<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
								) : sortingOrder === 'DEC' && sortingColumn === 'weekly_hours_percentage' ? (
									<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
								) : (
									<></>
								)}
							</a>
						</div>
					</th>

					{/* <th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('weekly_completed_todo');
							if (sortingOrder === 'ASC') {
								sorting('weekly_completed_todo', 'ASC');
							} else {
								sorting('weekly_completed_todo', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										'white-space': 'nowrap'
								  }
								: {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										'white-space': 'nowrap',
										position: 'relative',
										right: '315px'
								  }
						}
					>
						Weekly completed budget
						{sortingColumn === 'weekly_completed_todo' ? (
							<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
								{sortingOrder === 'ASC' ? (
									<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
								) : (
									<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
								)}
							</a>
						) : (
							''
						)}{' '}
					</th> */}

					{/* <th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('weekly_average_per_day');
							if (sortingOrder === 'ASC') {
								sorting('weekly_average_per_day', 'ASC');
							} else {
								sorting('weekly_average_per_day', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										'white-space': 'nowrap'
								  }
								: {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										'white-space': 'nowrap',
										position: 'relative',
										right: '315px'
								  }
						}
					>
						Weekly average per day
						{sortingColumn === 'weekly_average_per_day' ? (
							<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
								{sortingOrder === 'ASC' ? (
									<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
								) : (
									<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
								)}
							</a>
						) : (
							''
						)}{' '}
					</th> */}

					{/* workload */}
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('pending_tasks_budget');
							if (sortingOrder === 'ASC') {
								sorting('pending_tasks_budget', 'ASC');
							} else {
								sorting('pending_tasks_budget', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									minWidth: '130px',
									cursor: 'pointer'
								}
								: {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									position: 'relative',
									//right: '305px',
									minWidth: '130px',
									cursor: 'pointer'
								}
						}
					>
						Workload {totalTasks !== 0 && '(' + totalTasks + ')'}
						{/* {sortingColumn === 'workload' ? (
							<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
								{sortingOrder === 'ASC' ? (
									<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
								) : (
									<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
								)}
							</a>
						) : (
							''
						)}{' '} */}
						<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
							{sortingOrder === 'ASC' && sortingColumn === 'pending_tasks_budget' ? (
								<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
							) : sortingOrder === 'DEC' && sortingColumn === 'pending_tasks_budget' ? (
								<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
							) : (
								<></>
							)}
						</a>
					</th>

					{/* on hold */}
					{/* <th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('on_hold_tasks_count');
							if (sortingOrder === 'ASC') {
								sorting('on_hold_tasks_count', 'ASC');
							} else {
								sorting('on_hold_tasks_count', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									minWidth: '130px',
									cursor: 'pointer'
								}
								: {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									position: 'relative',
									//right: '305px',
									minWidth: '130px',
									cursor: 'pointer'
								}
						}
					>
						On Hold {onHoldTasks !== 0 && '(' + onHoldTasks + ')'}
						<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
							{sortingOrder === 'ASC' && sortingColumn === 'on_hold_tasks_count' ? (
								<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
							) : sortingOrder === 'DEC' && sortingColumn === 'on_hold_tasks_count' ? (
								<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
							) : (
								<></>
							)}
						</a>
					</th> */}

					{/* unplanned-tasks */}
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('unplanned_tasks');
							if (sortingOrder === 'ASC') {
								sorting('unplanned_tasks', 'ASC');
							} else {
								sorting('unplanned_tasks', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									minWidth: '130px',
									cursor: 'pointer'
								}
								: {
									//textAlign: 'center',
									fontSize: '14px',
									lineHeight: '21px',
									fontFamily: 'Poppins',
									fontWeight: '500',
									//width: '1%',
									whiteSpace: 'nowrap',
									position: 'relative',
									//right: '315px',
									minWidth: '130px',
									cursor: 'pointer'
								}
						}
					>
						Unestimated Tasks {totalUnplanned !== 0 && '(' + totalUnplanned + ')'}
						<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
							{sortingOrder === 'ASC' && sortingColumn === 'unplanned_tasks' ? (
								<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
							) : sortingOrder === 'DEC' && sortingColumn === 'unplanned_tasks' ? (
								<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
							) : (
								<></>
							)}
						</a>
					</th>

					{/* projects */}
					{/* 
					<th
						onClick={e => {
							e.preventDefault();
							setSortingColumn('project_ids');
							if (sortingOrder === 'ASC') {
								sorting('project_ids', 'ASC');
							} else {
								sorting('project_ids', 'DEC');
							}
						}}
						style={
							newUsers.length !== 0
								? {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										whiteSpace: 'nowrap',
										cursor: 'pointer'
								  }
								: {
										textAlign: 'center',
										fontSize: '14px',
										lineHeight: '21px',
										fontFamily: 'Poppins',
										fontWeight: '500',
										width: '1%',
										whiteSpace: 'nowrap',
										position: 'relative',
										right: '315px',
										cursor: 'pointer'
								  }
						}
					>
						Projects 
						<a style={{ color: 'white', marginLeft: '2px' }} href='/'>
							{sortingOrder === 'ASC' && sortingColumn === 'project_ids' ? (
								<ArrowUpwardIcon style={{ position: 'relative', top: '2px' }} />
							) : sortingOrder === 'DEC' && sortingColumn === 'project_ids' ? (
								<ArrowDownwardIcon style={{ position: 'relative', top: '2px' }} />
							) : (
								<></>
							)}
						</a>
					</th> 
					*/}


				</tr>
			</thead>
		);
	};

	useEffect(() => {
		if (newUsers) {
			let total_pending = 0;
			newUsers.map(user => {
				total_pending += user.pending_tasks_budget;
			});
			setTotalTasks(total_pending);
		}
	}, [newUsers]);

	return (
		<>
			{newUsers.length !== 0 && (
				<table cellSpacing='0' cellPadding='0'>
					{renderTableHeading()}
					<tbody>
						{newUsers.length !== 0 ? (
							newUsers.map((user, key) => {
								if (!toggel) {
									if (user.projects.length === 1) {
										return (
											<TableRow
												key={key}
												img={user.avatar}
												user_id={user.user_id}
												weekly_average={user.weekly_average_per_day}
												weekly_count={user.weekly_completed_todo}
												name={user.name}
												active={user.active_count}
												active_todo={user.active_todo_count}
												projects={user.project_ids}
												completed_todo={user.completed_todo}
												last_active_at={user.last_active_at}
												excluded_from_efficiency={user.excluded_from_efficiency}
												projectsdata={projects}
												data={data.users}
												getTeamWorkData={getTeamWorkData}
												setLoading={setLoading}
												daily_hours_to_complete={user.daily_hours_to_complete}
											/>
										);
									}
								}
								return (
									<TableRow
										key={key}
										img={user.avatar}
										user_id={user._id}
										tasks={user.tasks_count}
										name={user.name}
										active={user.active_count}
										active_todo={user.active_todo_count}
										projects={user.project_ids}
										completed_todo={user.completed_todo}
										last_active_at={user.last_active_at}
										excluded_from_efficiency={user.excluded_from_efficiency}
										projectsdata={projects}
										data={data.users}
										getTeamWorkData={getTeamWorkData}
										setLoading={setLoading}
										handleOnboardToProject={handleOnboardToProject}
										weekly_average={user.weekly_average_per_day}
										weekly_count={user.weekly_completed_todo}
										weekly_completed_budget={user.weekly_completed_budget}
										weekly_average_per_day={user.weekly_average_per_day}
										pending_tasks_budget={user.pending_tasks_budget}
										daily_hours_percentage={user.daily_hours_percentage}
										weekly_hours_percentage={user.weekly_hours_percentage}
										montly_hours_percentage={user.montly_hours_percentage}
										user_projects={user.projects}
										performance_message={user.performance_message}
										on_hold_tasks_count={user.on_hold_tasks_count}
										unplanned_tasks={user.unplanned_tasks}
										total_delegated_budget={user.total_delegated_budget}
										completed_delegated_budget={user.completed_delegated_budget}
										daily_performance_message={user.daily_performance_message}
										upcoming_leaves={user.upcoming_leaves}
										upcoming_leaves_with_reasons={user.upcoming_leaves_with_reasons}
										daily_hours_to_complete={user.daily_hours_to_complete}
										role={user.role}
										total_delegated_percentage={user.total_delegated_percentage}
									/>
								);
							})
						) : (
							<ContentLoader
								speed={2}
								width={700}
								height={450}
								viewBox='0 0 700 450'
								backgroundColor='rgb(46, 45, 45)'
								foregroundColor='rgb(12, 10, 39)'
							>
								<rect x='0' y='6.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='10' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='10' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='10' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='10' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='46.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='50' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='50' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='50' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='50' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='86.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='90' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='90' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='90' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='90' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='126.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='130' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='130' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='130' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='130' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='166.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='170' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='170' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='170' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='170' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='206.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='210' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='210' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='210' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='210' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='246.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='250' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='250' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='250' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='250' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='286.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='290' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='290' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='290' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='290' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='326.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='330' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='330' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='330' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='330' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='366.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='370' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='370' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='370' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='370' rx='4' ry='4' width='30' height='13' />
								<rect x='0' y='406.5' rx='10' ry='10' width='20' height='20' />
								<rect x='25' y='410' rx='4' ry='4' width='60' height='13' />
								<rect x='160' y='410' rx='4' ry='4' width='160' height='13' />
								<rect x='520' y='410' rx='4' ry='4' width='30' height='13' />
								<rect x='590' y='410' rx='4' ry='4' width='30' height='13' />
							</ContentLoader>
						)}
					</tbody>
				</table>
			)}
			<Modal
				open={onboardUserModal}
				onClose={() => {
					setOnboardUserModal(false);
				}}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Grid
					container
					spacing={3}
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ height: '100vh' }}
				>
					<Grid
						item
						xs={10}
						sm={8}
						md={5}
						style={{
							width: 400,
							backgroundColor: 'white',
							border: '2px solid #000',
							padding: '3px'
						}}
					>
						<ModalBody>
							<div className='modal_header'>
								<Typography variant='h4' style={{ position: 'relative' }}>
									Onboard {onboardUserName} to
									<CloseIcon
										fontSize='large'
										style={{ position: 'absolute', right: '0', cursor: 'pointer' }}
										onClick={() => {
											setOnboardUserModal(false);
										}}
									/>
								</Typography>
							</div>
							<div className='modal_body'>
								<FormControl fullWidth>
									<Input
										type='text'
										label='email'
										placeholder='Enter Email address'
										value={onboardUserEmail}
										onChange={e => {
											setOnboardUserEmail(e.target.value);
										}}
										className='mb-4'
									/>
								</FormControl>
								<FormControl fullWidth>
									<InputLabel id='select-project-label'>Select Project</InputLabel>
									<Select
										labelId='select-project-label'
										id='select-project'
										label='Age'
										value={onboardProjectId}
										onChange={e => {
											setonboardProjectId(e.target.value);
										}}
									>
										{projects.map(project => {
											return <MenuItem value={project.project_id}>{project.name}</MenuItem>;
										})}
									</Select>
								</FormControl>
								<Button
									variant='contained'
									onClick={() => {
										onboardToProject();
									}}
								>
									Add
								</Button>
							</div>
						</ModalBody>
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}

export default NewTeamTableChopping;

const TableRow = props => {
	const [AvatarClick, setAvatarClick] = useState();
	const [ref, setRef] = useState(null);
	const navigate = useNavigate();

	const getProjectname = projectid => {
		// for (let i = 0; i < props.projectsdata.length; i++) {
		// 	if (props.projectsdata[i].project_id === projectid) {
		// 		let c = getProjectCount(projectid, props.pending_budget);
		// 		let s = props.projectsdata[i].name;
		// 		// if(s.length>13){
		// 		// 	s=s.slice(0,12)+'...';
		// 		// }
		// 		return s + ' (' + c + ')';
		// 	}
		// }

		let name = props.user_projects[projectid].project_name;
		let budget = props.user_projects[projectid].pending_budget;
		return name + ' (' + budget + ')';
	};
	const getProjectCount = (projectid, userid) => {
		for (let i = 0; i < props.data.length; i++) {
			if (props.data[i].user_id === userid) {
				return props.data[i]?.projects[projectid]?.count;
			}
		}
	};
	const handleDeleteMember = user_id => {
		axios
			.delete(
				`${process.env.REACT_APP_LARAVEL_API_URL}/user/${user_id}`,
				{
					headers: {
						'Authorization': `Bearer ${accessToken}`,
						'Accept': 'application/json',
					}
				}
			)
			.then(res => {
				if (res.data.success === true) {
					alert(res.data.message);
					// getProjectData();
				} else {
					alert('Something went wrong');
					// console.log(res.data);
				}
				props.getTeamWorkData();
				props.setLoading(false);
			})
			.catch(error => {
				console.error(error);
				props.setLoading(false);
			});
	};
	const onAvatarLongPress = () => {
		// props.setDeleteMember({img:props.img, name:props.name, user_id:props.user_id})
		// props.setOpenDeleteModal(true)
		// if (window.confirm('Do you want to delete this user?')) {
		// 	handleDeleteMember(props.user_id);
		// }
	};
	const onAvatarClick = () => {
		if (AvatarClick === 'triggered') {
			setAvatarClick('nontriggered');
		} else {
			setAvatarClick('triggered');
		}
	};
	const defaultOptions = {
		shouldPreventDefault: true,
		delay: 500
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [openDailyTooltip, setOpenDailyTooltip] = useState(false);
	const [openWeeklyTooltip, setOpenWeeklyTooltip] = useState(false);

	const longPressAvatarEvent = useLongPress(onAvatarLongPress, onAvatarClick, defaultOptions);

	const DeleteUserBySingleClick = e => {
		e.preventDefault();
		handleClose();
		const data = window.confirm('Are You sure want to delete ?');
		if (data) {
			handleDeleteMember(props.user_id);
		}
	};

	const ExcludeUserFromEffiCalc = e => {
		e.preventDefault();
		handleClose();
		const data = window.confirm('Are You sure?');
		if (data) {
			props.setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/pages/exclude_user.php`,
					{ user_id: props.user_id, status: !props.excluded_from_efficiency },
					{
						headers: {
							Authorization: `Bearer ${token}`,
							'Access-Control-Allow-Origin': '*'
						}
					}
				)
				.then(res => {
					if (res.data.success === true) {
						alert(res.data.message);
						// getProjectData();
					} else {
						alert('Something went wrong');
						// console.log(res.data);
					}
					props.getTeamWorkData();
					props.setLoading(false);
				})
				.catch(error => {
					console.error(error);
					props.setLoading(false);
				});
		}
	};

	const [chartTooltip, setChartTooltip] = useState('');
	const [copySuccess, setCopySuccess] = useState(false);

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	const randomColor = () => {
		// let color = Math.floor(Math.random() * 16777215).toString(16);
		let items = ['#F74539', '#ECAF02', '#5FB924'];
		let item = items[Math.floor(Math.random() * items.length)];
		return item;
	};

	const checkColor = leaves => {
		let data = leaves?.filter(
			item => moment(item).format('D MM YYYY') === moment(new Date()).format('D MM YYYY')
		);
		if (data?.length !== 0) {
			return 'grey';
		}
	};

	const checkLeaves = leaves => {
		if (leaves.length !== 0) {
			let flag = false;
			leaves = leaves.map(item => parseInt(moment(item).format('D')));
			leaves.map((item, index) => {
				if (leaves[index] + 1 === leaves[index + 1]) {
					flag = true;
				}
			});
			return flag;
		} else {
			return false;
		}
	};

	const checkGaps = leaves => {
		let arr = [];
		if (leaves.length !== 0) {
			var start = moment(new Date()).format('YYYY-MM-DDT00:00:00.000000[Z]');
			var end = moment(leaves[leaves?.length - 1]).format('YYYY-MM-DDT00:00:00.000000[Z]');

			var loop = start;
			while (loop <= end) {
				if (leaves?.filter(item => moment(item).format('YYYY-MM-DDT00:00:00.000000[Z]') === loop).length !== 0) {
					arr.push({ available: false, date: moment(loop).format('DD MMM YYYY') });
				} else {
					arr.push({ available: true, date: moment(loop).format('DD MMM YYYY') });
				}

				var newDate = moment(loop, 'YYYY-MM-DDT00:00:00.000000[Z]').add(1, 'days').format('YYYY-MM-DDT00:00:00.000000[Z]');
				loop = newDate;
			}
		}
		return arr;
	};

	const getLeaveReason = (date, leavesArr) => {
		let data = leavesArr?.filter(
			item => moment(item.date).format('DD MM YYYY') === moment(date).format('DD MM YYYY')
		)[0];
		return data.reason;
	};

	const getLeaveType = (date, leavesArr) => {
		let data = leavesArr?.filter(
			item => moment(item.date).format('DD MM YYYY') === moment(date).format('DD MM YYYY')
		)[0];
		return data.half_day;
	};

	return (
		<>
			<Snackbar
				open={copySuccess}
				onClose={() => {
					setCopySuccess(false);
				}}
				autoHideDuration={2000}
			>
				<Alert severity='success'>Copied!</Alert>
			</Snackbar>

			<tr style={{ marginTop: '0', paddingTop: '0' }}>
				<td style={{ fontSize: '14px' }}>
					<Grid container spacing={2}>
						<Menu
							id='demo-positioned-menu'
							aria-labelledby='demo-positioned-button'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
						>
							<MenuItem onClick={DeleteUserBySingleClick}>Delete {props.name}</MenuItem>
							<MenuItem onClick={handleClose}>Transfer all task of this user</MenuItem>
							<MenuItem
								onClick={() => {
									handleClose();
									props.handleOnboardToProject(props.user_id, props.name);
								}}
							>
								Onboard User to another project
							</MenuItem>
							<MenuItem
								onClick={() =>
									navigate({
										pathname: '/performance/user',
										search: `?userId=${props.user_id}`
									})
								}
							>
								Past Performance
							</MenuItem>
							<MenuItem onClick={ExcludeUserFromEffiCalc}>
								{props.excluded_from_efficiency
									? 'Include in efficiency calculations'
									: 'Exclude from efficiency calculations'}
							</MenuItem>
						</Menu>
						<Grid item xs={2} sm={1} style={{}}>
							<CustomAvatar
								avatar={props.img} alt='profile'
								name={props.name}
								style={{ width: '24px', height: '24px', fontSize: "10px" }}
								onClick={handleClick}
							/>
						</Grid>
						<Grid item xs={8} sm={10} style={{ fontSize: '14px' }}>
							<div style={{ display: 'flex', gap: '10px' }}>
								<a
									href={`https://3.basecamp.com/4954106/reports/users/progress/${props.user_id}`}
									style={{
										color:
											props.name.split(' ')[0] === 'Kajal'
												? 'white'
												: props.active_todo === 0
													? 'red'
													: moment().diff(moment(props.last_active_at), 'hours') >= 3
														? '#EDFC45'
														: 'white',
										paddingLeft: '2rem',
										fontSize: '14px'
									}}
									target='_blank'
									rel='noreferrer'
								>
									<div
										style={{
											color: checkColor(props.upcoming_leaves),
											display: 'flex',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										{props.name.split(' ')[0]}
										{props.upcoming_leaves && props.upcoming_leaves?.length !== 0 && (
											<div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
												{checkGaps(props.upcoming_leaves, props.upcoming_leaves_with_reasons).map(
													item => {
														if (item.available) {
															return (
																<div
																	className='leavesCount'
																	style={{
																		width: '9px'
																	}}
																>
																	<img
																		src={present}
																		alt='present'
																		style={{ width: '9px', height: '9px' }}
																	/>
																	<div
																		style={{ display: 'inline-flex' }}
																		className='LeavesCounttip'
																	>
																		{item.date}
																		{/* {props.upcoming_leaves_with_reasons &&
																		props.upcoming_leaves_with_reasons.length !== 0 &&
																		getLeaveReason(item.date, props.upcoming_leaves_with_reasons)} */}
																	</div>
																</div>
															);
														} else {
															return (
																<div
																	className='leavesCount'
																	style={{
																		width: '9px'
																	}}
																>
																	{getLeaveType(item.date,props.upcoming_leaves_with_reasons) && <span style={{ color: 'red', fontWeight: 'bold' }}>-</span>}
																	{!getLeaveType(item.date,props.upcoming_leaves_with_reasons) && 
																		<img
																			src={absent}
																			alt='absent'
																			style={{ width: '9px', height: '9px' }}
																		/>
																	}
																	<div
																		//style={{ display: 'inline-flex' }}
																		className='LeavesCounttip'
																	>
																		<div>{item.date}</div>
																		<div>
																			{props.upcoming_leaves_with_reasons &&
																				props.upcoming_leaves_with_reasons.length !== 0 &&
																				getLeaveReason(
																					item.date,
																					props.upcoming_leaves_with_reasons
																				)}
																		</div>
																	</div>
																</div>
															);
														}
													}
												)}
											</div>
										)}
									</div>
								</a>
								{/* {
									props.role === 'team_lead' && props.total_delegated_percentage !== 0 && 
									(
										<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
											<CircularProgressbar
												value={props.total_delegated_percentage}
												strokeWidth={22}
												counterClockwise
												styles={buildStyles({
													pathColor: getColor(props.total_delegated_percentage),
													textColor: getColor(props.total_delegated_percentage),
													trailColor: '#414141',
													strokeLinecap: 'round'
												})}
											/>
										</div>
									)
								} */}
								{/* <div style={{ display: 'flex', gap: '3px' }}>
									{props.upcoming_leaves?.length !== 0 && (
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{checkGaps(props.upcoming_leaves).map(item => {
												if (item.available) {
													return (
														<div className='leavesCount'>
															<BsRecordCircleFill color='green' />
															<div style={{ display: 'inline-flex' }} className='LeavesCounttip'>
																{item.date}
															</div>
														</div>
													);
												} else {
													return (
														<div className='leavesCount'>
															<IoClose color='red' />
															<div style={{ display: 'inline-flex' }} className='LeavesCounttip'>
																{item.date}
															</div>
														</div>
													);
												}
											})}
										</div>
									)}
								</div> */}
							</div>
							{/* {(props.total_delegated_budget || props.completed_delegated_budget) && (
								<div style={{ paddingLeft: '1.5vw' }}>
									<small>
										Delegated: {props.total_delegated_budget} <br /> Completed:{' '}
										{props.completed_delegated_budget}
									</small>
								</div>
							)} */}
						</Grid>
					</Grid>
				</td>

				{/* daily progress */}
				<td
					style={{
						transform: 'translate(0, 0px)',
						fontSize: '14px',
						position: 'relative',
						zIndex: openDailyTooltip ? 8 : undefined,
						maxWidth: '150px'
					}}
				>
					<div
						style={{
							margin: '0 0.5vw',
							position: 'relative',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{props.daily_hours_percentage !== 0 && (
							<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
								<Link
									to={`/completion/${props.user_id}`}
									target='_blank'
									rel='noreferrer'
								>
									<CircularProgressbar
										value={props.daily_hours_percentage}
										//text={`${props.daily_hours_percentage}%`}
										strokeWidth={22}
										counterClockwise
										styles={buildStyles({
											pathColor: getColor(props.daily_hours_percentage),
											textColor: getColor(props.daily_hours_percentage),
											trailColor: '#414141',
											strokeLinecap: 'round'
										})}
									/>
								</Link>
							</div>
						)}
						<Tooltip
							role={'button'}
							PopperProps={{
								disablePortal: true
							}}
							open={openDailyTooltip}
							title={
								<ClickAwayListener
									onClickAway={() => {
										setTimeout(() => {
											setOpenDailyTooltip(false);
										}, 100);
									}}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: props.daily_performance_message
										}}
										id={props.user_id + '_msg'}
										onClick={e => {
											copyToClip(props.daily_performance_message);
											setCopySuccess(true);
											setOpenDailyTooltip(false);
										}}
										ref={ref}
										style={{
											fontSize: 12,
											lineHeight: 'normal',
											textAlign: 'left',
											pointerEvents: 'all',
											width: '220px'
										}}
									></div>
								</ClickAwayListener>
							}
						>
							<div
								style={{ color: getColor(props.daily_hours_percentage), marginLeft: '6px' }}
								className='weekPerformance'
								onClick={() => {
									//	setOpenDailyTooltip(true);
									navigate(`/completion/${props.user_id}`)
								}}
							>
								{props.daily_hours_percentage !== 0 ? props.daily_hours_percentage + '%' : '-'}
							</div>
						</Tooltip>
					</div>
				</td>

				{/* weekly progress */}
				<td
					style={{
						transform: 'translate(0, 0px)',
						fontSize: '14px',
						position: 'relative',
						zIndex: openWeeklyTooltip ? 8 : undefined,
						maxWidth: '150px'
					}}
				>
					<div
						style={{
							margin: '0 0.5vw',
							position: 'relative',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{props.weekly_hours_percentage !== 0 && (
							<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
								<CircularProgressbar
									value={props.weekly_hours_percentage}
									//text={`${props.weekly_hours_percentage}%`}
									strokeWidth={22}
									counterClockwise
									styles={buildStyles({
										pathColor: getColor(props.weekly_hours_percentage),
										textColor: getColor(props.weekly_hours_percentage),
										trailColor: '#414141',
										strokeLinecap: 'round'
									})}
								/>
							</div>
						)}
						<Tooltip
							role={'button'}
							PopperProps={{
								disablePortal: true
							}}
							open={openWeeklyTooltip}
							title={
								<ClickAwayListener
									onClickAway={() => {
										setTimeout(() => {
											setOpenWeeklyTooltip(false);
										}, 100);
									}}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: props.performance_message
										}}
										id={props.user_id + '_msg'}
										onClick={e => {
											copyToClip(props.performance_message);
											setCopySuccess(true);
											setOpenWeeklyTooltip(false);
										}}
										ref={ref}
										style={{
											fontSize: 12,
											lineHeight: 'normal',
											textAlign: 'left',
											pointerEvents: 'all'
										}}
									></div>
								</ClickAwayListener>
							}
						>
							<div
								style={{ color: getColor(props.weekly_hours_percentage), marginLeft: '6px' }}
								className='weekPerformance'
								onClick={() => {
									setOpenWeeklyTooltip(true);
								}}
							>
								{props.weekly_hours_percentage !== 0 ? props.weekly_hours_percentage + '%' : '-'}
							</div>
						</Tooltip>
					</div>
				</td>

				{/* <td>
				{props.weekly_completed_budget === 0 ? (
					<div>{props.weekly_completed_budget}</div>
				) : (
					<div>{props.weekly_completed_budget}</div>
				)}
			</td> */}

				{/* <td
				style={{
					transform: 'translateX(35px)',
					fontSize: '14px',
					position: 'relative',
					left: '3px'
				}}
			>
				{props.weekly_average_per_day === 0 ? (
					<div>{props.weekly_average_per_day}</div>
				) : props.weekly_average_per_day < 3 ? (
					<div style={{ color: 'red' }}>{props.weekly_average_per_day}</div>
				) : (
					<div>{props.weekly_average_per_day}</div>
				)}
			</td> */}

				{/* workload */}
				<td
					style={{
						// transform: 'translateX(25px)',
						fontSize: '14px',
						position: 'relative',
						// display: 'flex',
						// alignItems: 'center',
						gap: '10px'
					}}
				>
					{props.pending_tasks_budget !== 0 && (
						<Link
							to={`/tasks/assigned/${props.user_id}`}
							style={{
								color:
									props?.pending_tasks_budget >= 16 ||
										props?.pending_tasks_budget <= 4 ||
										(props.upcoming_leaves &&
											props.upcoming_leaves.length !== 0 &&
											checkLeaves(props.upcoming_leaves))
										? 'red'
										: 'white',
								fontSize: '14px',
								zIndex: '-1',
								margin: '0 20px',
							}}
							target='_blank'
							rel='noreferrer'
						>
							{props.pending_tasks_budget}
						</Link>
					)}
				</td>

				{/* on hold */}
				{/* <td
					style={{
						// transform: 'translateX(25px)',
						fontSize: '14px',
						position: 'relative',
						left: '15px'
					}}
				>
					{props.on_hold_tasks_count > 0 && (
						<a
							href={`https://redwing.happygoals.app/pages/onhold_task.php?userid=${props.user_id}`}
							target='_blank'
							rel='noreferrer'
							style={{ color: 'red' }}
						>
							{' '}
							{props.on_hold_tasks_count}
						</a>
					)}
				</td> */}

				{/* unplanned-tasks */}
				<td
					style={{
						// transform: 'translateX(25px)',
						fontSize: '14px',
						position: 'relative',
						left: '15px'
					}}
				>
					{props.unplanned_tasks !== 0 && (
						<a
							// href={`https://redwing.happygoals.app/pages/unplanned_task.php?userid=${props.user_id}`}
							href={`/tasks/unestimated/${props.user_id}`}
							target='_blank'
							rel='noreferrer'
							style={{ color: 'white' }}
						>
							{props.unplanned_tasks}
						</a>
					)}
				</td>

				{/* projects column */}
				<td
					style={{
						textAlign: 'center',
						// transform: 'translateX(-8px)',
						position: 'relative',
						left: '3px'
					}}
				>
					<p
						style={{
							color:
								props.projects.length > 3
									? 'red'
									: props.projects.length === 1
										? '#98FB58'
										: 'white',
							fontSize: '13px',
							position: 'relative',
							display: 'inline-block'
						}}
						className='projectCount'
					>
						{props.projects.length > 0 ? props.projects.length : ''}

						<div style={{ display: 'inline-block' }} className='ProjectCounttip'>
							{props.projects.map((each, i) => {
								const projectname = getProjectname(each);

								return <div>{projectname}</div>;
							})}
						</div>
					</p>
				</td>
			</tr>
		</>
	);
};
