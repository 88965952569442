import React, { useEffect, useState } from 'react';
import * as axiosBase from 'axios';

const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): {};
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	}
});

function PerformanceFeedbackReport() {
	const [feedbacks, setFeedbacks] = useState(null);
	const [defaulters, setDefaulters] = useState(null);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/performance/feedback`, )
			.then(res => {
				if (res.data.status) {
					setFeedbacks(res.data.feedbacks);
					setDefaulters(res.data.user_list_without_feedbacks);
				}
				//setLoading(false);
			})
			.catch(error => {
				console.error(error);
				//setLoading(false);
			});
	}, []);

	return (
		<div style={{ paddingTop: '5vh', color: 'white' }}>
			<div style={{ fontSize: '3vh', fontWeight: 'bold', textAlign: 'center' }}>
				Performance Feedback Report
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', gap: '5vw', marginTop: '5vh' }}>
				<div style={{ width: '60%' }}>
					{feedbacks?.map(item => {
						return (
							<div
								style={{
									marginBottom: '3vh',
									paddingBottom: '3vh',
									borderBottom: '1px solid grey'
								}}
							>
								<div>{item.name}</div>
								<div style={{ color: 'gray' }}>Can you share your thoughts?</div>
								<div style={{ marginBottom: 20, fontWeight: 'normal' }}>{item.feedback}</div>

								<div style={{ color: 'gray' }}>Was there any painpoints you were facing?</div>
								<div style={{ marginBottom: 20 }}>{item.painpoints}</div>

								<div style={{ color: 'gray' }}>
									Your output was less than the excepted. Can you elaborate where the rest of the
									time go?
								</div>
								<div style={{ marginBottom: 20 }}>{item.shortage_of_work}</div>

								{item.issues.length !== 0 && (
									<>
										<div style={{ color: 'gray' }}>Issues:</div>
										{item.issues.map(item => {
											return <div>{item}</div>;
										})}
									</>
								)}
							</div>
						);
					})}
				</div>
				<div>
					<div style={{ fontWeight: 'bold', marginBottom: '2vh' }}>Feedback not submitted</div>
					<div>
						{defaulters?.map(item => {
							return <div style={{ color: 'red' }}>{item.name}</div>;
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PerformanceFeedbackReport;
