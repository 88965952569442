import CommentIcon from 'components/CommentIcon/CommentIcon';
import React from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TaskIcon from 'components/TaskIcon/TaskIcon';

const ChildTasks = ({
	item,
	tasks,
	title,
	lastComment,
	estimate,
	hasNotification,
	commentCounts,
	worksheet,
	tasksWithNewComments
}) => {
	const filteredTodo = worksheet.filter(element => element.parent_id === item._id);

	return (
		<div
			style={{
				background: '#000',
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				color: '#fff',
				width: '100%',
				padding: '2px'
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%'
				}}
			>
				<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
					{/* <CommentIcon type={lastComment} style={{ margin: '0px' }} /> */}
					<TaskIcon item={item} />

					<div className='d-flex align-items-start' style={{ marginTop: '1px' }}>
						{estimate !== 0 ? (
							<div
								className='d-flex align-items-end'
								style={{
									borderRadius: '12px',
									color: `${
										estimate <= 2 ? '#5FB924' : estimate > 2 && estimate < 5 ? '#FCBA03' : '#FF0000'
									}`,
									fontSize: '9px',
									lineHeight: '13px',
									padding: '1px 0',
									fontFamily: 'Roboto Mono',
									fontStyle: 'normal'
								}}
							>
								<p className='m-0 p-0'>{estimate.toFixed(2)}</p>
							</div>
						) : estimate !== 0 ? (
							<div
								className='d-flex align-items-end'
								style={{
									fontSize: '12px',
									lineHeight: '13px',
									padding: '1px 0',
									color: '#5FB924',
									fontFamily: 'Roboto Mono',
									fontStyle: 'normal'
								}}
							>
								<p className='m-0 p-0'>{estimate.toFixed(2)}</p>
							</div>
						) : (
							<div
								className='d-flex align-items-end'
								style={{
									// textAlign: 'center',
									borderRadius: '12px',
									fontSize: '12px',
									lineHeight: '13px',
									padding: '1px 0',
									opacity: '60%',
									color: 'white',
									fontFamily: 'Roboto Mono',
									fontStyle: 'normal'
								}}
							>
								<p className='m-0 p-0'>0.00</p>
							</div>
						)}
					</div>

					<p style={{ margin: '0px', fontSize: '11px' }}>{title}</p>
				</div>
				<div className='d-none d-sm-flex align-items-center' style={{ maxWidth: '40px' }}>
					{commentCounts > 0 && (
						<>
							{hasNotification ? (
								<ChatBubbleIcon style={{ color: '#5FB924', cursor: 'pointer' }} onClick={e => {}} />
							) : (
								<ChatBubbleOutlineIcon style={{ cursor: 'pointer' }} onClick={e => {}} />
							)}{' '}
							<p
								className='text-small my-0 mx-1 p-0'
								style={{
									fontSize: '10px',
									color: hasNotification ? '#5FB924' : '#ffffff'
								}}
							>
								{commentCounts}
							</p>
						</>
					)}
				</div>
			</div>
			{filteredTodo.length > 0 ? (
				<div style={{ marginLeft: '20px' }}>
					{filteredTodo.map(grandChildren => (
						<ChildTasks
							key={grandChildren._id}
							item={grandChildren}
							worksheet={worksheet}
							tasks={tasks}
							title={grandChildren.title}
							estimate={grandChildren.estimate}
							lastComment={grandChildren.last_comment_type}
							hasNotification={tasksWithNewComments[grandChildren._id]}
							commentCounts={grandChildren.comments_count}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

export default ChildTasks;

