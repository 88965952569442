import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const LoginCallback = () => {
    const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get('code');

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_LARAVEL_API_URL}/oauth2callback`, { code: code })
            .then(data => {
                if (data.data.success) {
                    localStorage.setItem('access_token', data.data.token);
                    localStorage.setItem('user', JSON.stringify(data.data.user));
                    window.location.href = '/chopping-interface';
                }
            });
	}, [code]);

    return <h2>Loading...</h2>
}

export default LoginCallback;