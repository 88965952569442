import React from 'react'
import './NotificationComponentsStyles.css'
import CountContainer from './CountContainer'

const WorksheetLabelConatiner = ({name,count}) => {
  return (
    <div className='worksheet-label'>
      <div className='worksheet-name-label'>

        <span>{name}</span>
      </div>
        <CountContainer count={count} />

    </div>
  )
}

export default WorksheetLabelConatiner