import React, { useState } from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { Alert } from '@material-ui/lab';
import { ClickAwayListener, Tooltip, Snackbar } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import styles from './User.module.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

function PerformanceCharts({
	weeklyData,
	monthlyData,
	weeklyDates,
	monthlyDates,
	weeklyChartData,
	monthlyChartData,
	users,
	userData,
	showWeeklyPerformance,
	showMonthlyPerformance,
}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const id = searchParams.get('userId');
	const [isTeamLead, setIsTeamLead] = useState(false);

	const [chartType, setChartType] = useState('Weekly');

	useEffect(() => {
		if (id) {
			//console.log(id);
			let newUsers = users?.filter(item => item !== '');
			let data = newUsers?.filter(item => item.user_id === Number(id))[0];
			if (data && data.role === 'team_lead') {
				setIsTeamLead(true);
			} else {
				setIsTeamLead(false);
			}
		}
	}, [id]);

	function copyToClip(str) {
		function listener(e) {
			e.clipboardData.setData('text/html', str);
			e.clipboardData.setData('text/plain', str);
			e.preventDefault();
		}
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	const weeklyOptions = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
				tools: {
					download: false // <== line to add
				}
			}
		},
		grid: {
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: false
				}
			}
		},
		annotations: {
			yaxis: [
				{
					y: 40,
					borderColor: '#FFFFFF'
				}
			]
		},

		xaxis: {
			categories: weeklyChartData?.weeklyDate,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		yaxis: {
			min: 0,
			max: 40,
			tickAmount: 2,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		legend: {
			show: true,
			fontFamily: 'Poppins',
			labels: {
				colors: ['white']
			}
		}
	};
	const weeklySeries = [
		{
			name: 'Weekly Completed Budget',
			data: weeklyChartData?.weeklyArr
		},
		{
			name: 'Budget Assigned',
			data: weeklyChartData?.weeklyEstimates
		}
	];

	const monthlyOptions = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
				tools: {
					download: false // <== line to add
				}
			}
		},
		grid: {
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: false
				}
			}
		},
		annotations: {
			yaxis: [
				{
					y: 160,
					borderColor: '#FFFFFF'
				}
			]
		},

		xaxis: {
			categories: monthlyChartData?.monthlyDate,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		yaxis: {
			min: 0,
			max: 160,
			tickAmount: 2,
			labels: {
				style: {
					colors: 'white',
					fontSize: '12px'
				}
			}
		},
		legend: {
			show: true,
			fontFamily: 'Poppins',
			labels: {
				colors: ['white']
			}
		}
	};
	const monthlySeries = [
		{
			name: 'Monthly Completed Budget',
			data: monthlyChartData?.monthlyArr
		},
		{
			name: 'Budget Assigned',
			data: monthlyChartData?.monthlyEstimates
		}
	];

//	console.log({ monthlyOptions }, { monthlySeries });

	return (
		<>
			<div className={styles.responsive1}>

				{showWeeklyPerformance &&
					(<div style={{ width: '100%' }}>
						<div style={{ fontWeight: 'bold' }}>Weekly Performance</div>
						<div
							style={{
								display: 'flex',
								width: '100%'
							}}
						>
							<Chart
								options={weeklyOptions}
								series={weeklySeries}
								type='bar'
								height='200'
								style={{ width: '100%' }}
							/>
						</div>
						<table>
							<tr>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Weekly Performance</th>
								<th>Absent</th>
								<th>Low Output Days</th>
								<th>Extra Effort Days</th>
								<th>Leaves</th>
								{isTeamLead && (
									<>
										<th>Tasks Estimated</th>
										<th>Hours Estimated</th>
									</>
								)}
							</tr>
							{weeklyData?.map((item, index) => {
								return (
									<WeeklyTableRow
										start_date={item.start_date}
										end_date={item.end_date}
										performance_message={item.performance_message}
										weekly_hours_percentage={item.weekly_hours_percentage}
										absent_count={item.absent_count}
										absent={item.absent}
										low_output_days_count={item.low_output_days_count}
										low_output_days={item.low_output_days}
										extra_effort_days_count={item.extra_effort_days_count}
										extra_effort_days={item.extra_effort_days}
										leaves={item.leaves}
										leaves_count={item.leaves_count}
										hours_estimated={item.estimates_completed}
										tasks_estimated={item.estimates_tasks}
									/>
								);
							})}
						</table>
					</div>
					)}
				{showMonthlyPerformance && (<div style={{ width: '100%',  }}>
					<div style={{ fontWeight: 'bold' }}>Monthly Performance</div>
					<div
						style={{
							display: 'flex',
							width: '100%'
						}}
					>
						<Chart
							options={monthlyOptions}
							series={monthlySeries}
							type='bar'
							height='200'
							style={{ width: '100%' }}
						/>
					</div>
					<table>
						<tr>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Monthly Performance</th>
							<th>Absent</th>
							<th>Low Output Days</th>
							<th>Extra Effort Days</th>
							<th>Leaves</th>
							{isTeamLead && (
								<>
									<th>Tasks Estimated</th>
									<th>Hours Estimated</th>
								</>
							)}
						</tr>
						{monthlyData?.map((item, index) => {
							return (
								<MonthlyTableRow
									start_date={item.start_date}
									end_date={item.end_date}
									performance_message={item.performance_message}
									monthly_hours_percentage={item.monthly_hours_percentage}
									absent_count={item.absent_count}
									absent={item.absent}
									low_output_days_count={item.low_output_days_count}
									low_output_days={item.low_output_days}
									extra_effort_days_count={item.extra_effort_days_count}
									extra_effort_days={item.extra_effort_days}
									leaves={item.leaves}
									leaves_count={item.leaves_count}
									hours_estimated={item.estimates_completed}
									tasks_estimated={item.estimates_tasks}
								/>
							);
						})}
					</table>
				</div>)}

			</div>

			<div className={styles.responsive2} onChange={e => setChartType(e.target.value)}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<select
						style={{
							fontSize: '18px',
							border: 'none',
							background: '#181A1E',
							color: 'white',
							fontFamily: 'Poppins',
							fontWeight: '600',
							outline: 'none'
						}}
					>
						<option value='Weekly'>Weekly Performance</option>
						<option value='Monthly'>Monthly Performance</option>
					</select>
					<img
						src={userData?.avatar}
						style={{ width: '27px', height: '27px', borderRadius: '100%' }}
					/>
				</div>
				{chartType === 'Weekly' ? (
					<div style={{ width: '100%', marginTop: '10px' }}>
						<div className={styles.chart}>
							<Chart
								options={weeklyOptions}
								series={weeklySeries}
								type='bar'
								height='300'
								className={styles.weeklyChart}
							/>
						</div>
						<table className={styles.performanceTable}>
							<tr>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Performance</th>
								<th>Absent</th>
								<th>Low Output Days</th>
								<th>Extra Effort Days</th>
								<th>Leaves</th>
								{isTeamLead && (
									<>
										<th>Tasks Estimated</th>
										<th>Hours Estimated</th>
									</>
								)}
							</tr>
							{weeklyData?.map((item, index) => {
								return (
									<WeeklyTableRow
										start_date={item.start_date}
										end_date={item.end_date}
										performance_message={item.performance_message}
										weekly_hours_percentage={item.weekly_hours_percentage}
										absent_count={item.absent_count}
										absent={item.absent}
										low_output_days_count={item.low_output_days_count}
										low_output_days={item.low_output_days}
										extra_effort_days_count={item.extra_effort_days_count}
										extra_effort_days={item.extra_effort_days}
										leaves={item.leaves}
										leaves_count={item.leaves_count}
										hours_estimated={item.estimates_completed}
										tasks_estimated={item.estimates_tasks}
									/>
								);
							})}
						</table>
					</div>
				) : (
					<div style={{ width: '100%', marginTop: '10px' }}>
						<div className={styles.chart}>
							<Chart
								options={monthlyOptions}
								series={monthlySeries}
								type='bar'
								height='300'
								className={styles.monthlyChart}
							/>
						</div>
						<table className={styles.performanceTable}>
							<tr>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Performance</th>
								<th>Absent</th>
								<th>Low Output Days</th>
								<th>Extra Effort Days</th>
								<th>Leaves</th>
								{isTeamLead && (
									<>
										<th>Tasks Estimated</th>
										<th>Hours Estimated</th>
									</>
								)}
							</tr>
							{monthlyData?.map((item, index) => {
								return (
									<MonthlyTableRow
										start_date={item.start_date}
										end_date={item.end_date}
										performance_message={item.performance_message}
										monthly_hours_percentage={item.monthly_hours_percentage}
										absent_count={item.absent_count}
										absent={item.absent}
										low_output_days_count={item.low_output_days_count}
										low_output_days={item.low_output_days}
										extra_effort_days_count={item.extra_effort_days_count}
										extra_effort_days={item.extra_effort_days}
										leaves={item.leaves}
										leaves_count={item.leaves_count}
										hours_estimated={item.estimates_completed}
										tasks_estimated={item.estimates_tasks}
									/>
								);
							})}
						</table>
					</div>
				)}
			</div>
		</>
	);
}

export default PerformanceCharts;

const WeeklyTableRow = ({
	start_date,
	end_date,
	performance_message,
	weekly_hours_percentage,
	absent_count,
	absent,
	low_output_days_count,
	low_output_days,
	extra_effort_days_count,
	extra_effort_days,
	leaves,
	leaves_count,
	tasks_estimated,
	hours_estimated
}) => {
	const [ref, setRef] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);

	function copyToClip(str) {
		function listener(e) {
			e.clipboardData.setData('text/html', str);
			e.clipboardData.setData('text/plain', str);
			e.preventDefault();
		}
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	return (
		<>
			<Snackbar
				open={copySuccess}
				onClose={() => {
					setCopySuccess(false);
				}}
				autoHideDuration={2000}
			>
				<Alert severity='success'>Copied!</Alert>
			</Snackbar>
			<tr className={styles.rowColor}>
				<td>{moment(start_date).format('D MMM YYYY')}</td>
				<td>{moment(end_date).format('D MMM YYYY')}</td>
				<td>
					<Tooltip
						role={'button'}
						PopperProps={{
							disablePortal: true
						}}
						open={openTooltip}
						title={
							<ClickAwayListener
								onClickAway={() => {
									setTimeout(() => {
										setOpenTooltip(false);
									}, 100);
								}}
							>
								<div
									dangerouslySetInnerHTML={{
										__html: performance_message
									}}
									id={'_msg'}
									onClick={e => {
										copyToClip(performance_message);
										setCopySuccess(true);
										setOpenTooltip(false);
									}}
									ref={ref}
									style={{
										fontSize: 7,
										lineHeight: 'normal',
										textAlign: 'left',
										pointerEvents: 'all'
									}}
								></div>
							</ClickAwayListener>
						}
					>
						<div
							style={{
								color: getColor(weekly_hours_percentage),
								marginLeft: '6px',
								display: 'flex',
								alignItems: 'center',
								gap: '5px'
							}}
							className='weekPerformance'
							onClick={() => {
								setOpenTooltip(true);
							}}
						>
							<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
								<CircularProgressbar
									value={weekly_hours_percentage}
									strokeWidth={18}
									counterClockwise
									styles={buildStyles({
										pathColor: 'red',
										textColor: 'red',
										trailColor: '#414141',
										strokeLinecap: 'round'
									})}
								/>
							</div>
							{weekly_hours_percentage !== 0 ? weekly_hours_percentage + '%' : ''}
						</div>
					</Tooltip>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{absent_count !== 0 ? absent_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{absent?.map(each => {
							return <div>{moment(each).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{low_output_days_count !== 0 ? low_output_days_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{low_output_days?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{extra_effort_days_count !== 0 ? extra_effort_days_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{extra_effort_days?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{leaves_count !== 0 ? leaves_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{leaves?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td>{tasks_estimated}</td>
				<td>{hours_estimated}</td>
			</tr>
		</>
	);
};

const MonthlyTableRow = ({
	start_date,
	end_date,
	performance_message,
	monthly_hours_percentage,
	absent_count,
	absent,
	low_output_days_count,
	low_output_days,
	extra_effort_days_count,
	extra_effort_days,
	leaves,
	leaves_count,
	tasks_estimated,
	hours_estimated
}) => {
	const [ref, setRef] = useState(null);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);

	function copyToClip(str) {
		function listener(e) {
			e.clipboardData.setData('text/html', str);
			e.clipboardData.setData('text/plain', str);
			e.preventDefault();
		}
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	const getColor = value => {
		if (value > 70) {
			return '#5FB924';
		} else if (value < 50) {
			return '#FF0000';
		} else if (value >= 50 && value <= 70) {
			return '#FCBA03';
		}
	};

	return (
		<>
			<Snackbar
				open={copySuccess}
				onClose={() => {
					setCopySuccess(false);
				}}
				autoHideDuration={2000}
			>
				<Alert severity='success'>Copied!</Alert>
			</Snackbar>
			<tr className={styles.rowColor}>
				<td>{moment(start_date).format('D MMM YYYY')}</td>
				<td>{moment(end_date).format('D MMM YYYY')}</td>
				<td>
					<Tooltip
						role={'button'}
						PopperProps={{
							disablePortal: true
						}}
						open={openTooltip}
						title={
							<ClickAwayListener
								onClickAway={() => {
									setTimeout(() => {
										setOpenTooltip(false);
									}, 100);
								}}
							>
								<div
									dangerouslySetInnerHTML={{
										__html: performance_message
									}}
									id={'_msg'}
									onClick={e => {
										copyToClip(performance_message);
										setCopySuccess(true);
										setOpenTooltip(false);
									}}
									ref={ref}
									style={{
										fontSize: 7,
										lineHeight: 'normal',
										textAlign: 'left',
										pointerEvents: 'all'
									}}
								></div>
							</ClickAwayListener>
						}
					>
						<div
							style={{
								color: getColor(monthly_hours_percentage),
								marginLeft: '6px',
								display: 'flex',
								alignItems: 'center',
								gap: '5px'
							}}
							className='weekPerformance'
							onClick={() => {
								setOpenTooltip(true);
							}}
						>
							<div style={{ width: '25px', height: '25px', zIndex: 50 }}>
								<CircularProgressbar
									value={monthly_hours_percentage}
									strokeWidth={18}
									counterClockwise
									styles={buildStyles({
										pathColor: 'red',
										textColor: 'red',
										trailColor: '#414141',
										strokeLinecap: 'round'
									})}
								/>
							</div>
							{monthly_hours_percentage !== 0 ? monthly_hours_percentage + '%' : ''}
						</div>
					</Tooltip>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{absent_count !== 0 ? absent_count : ''}{' '}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{absent?.map(each => {
							return <div>{moment(each).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{low_output_days_count !== 0 ? low_output_days_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{low_output_days?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{extra_effort_days_count !== 0 ? extra_effort_days_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{extra_effort_days?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td style={{ cursor: 'pointer' }} className='absentCount'>
					{leaves_count !== 0 ? leaves_count : ''}
					<div style={{ display: 'inline-block' }} className='AbsentCounttip'>
						{leaves?.map(each => {
							return <div>{moment(each.date).format('D MMM YYYY')}</div>;
						})}
					</div>
				</td>
				<td>{tasks_estimated}</td>
				<td>{hours_estimated}</td>
			</tr>
		</>
	);
};
