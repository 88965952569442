import CustomAvatar from '../CustomAvatar/CustomAvatar';
import { Link } from 'react-router-dom';

export const UsersListSidebar = ({ users, handleUserChange, user_id, goToTasksSlide }) => {
    return (
        <div>
            {users?.map((user, index) => {
                return (
                    user.name && (
                        <div key={index} style={{ display: 'flex', gap: '5px', marginBottom: '1vh' }}>
                            <CustomAvatar
                                avatar={user.avatar}
                                alt='profile'
                                name={user.name}
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    fontSize: '10px',
                                    borderRadius: '100%'
                                }}
                            />
                            <div>
                                <div
                                    style={
                                        user._id === user_id
                                            ? {
                                                cursor: 'pointer',
                                                color: 'white'
                                            }
                                            : {
                                                cursor: 'pointer',
                                                color: 'grey'
                                            }
                                    }
                                >
                                    <Link
                                        to={`/tasks/assigned/${user._id}`}
                                        style={{ color: 'white' }}
                                        onClick={e => {
                                            handleUserChange(e, user._id);
                                            goToTasksSlide();
                                        }}
                                    >
                                        {user.name}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                );
            })}
        </div>
    )
}