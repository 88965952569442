const color = {
	primary_dark: '#16691E',
	primary: '#32883A',
	secondary: '#327888',
	secondary_dark: '#307383',
	black: '#000',
	white: '#fff',
	tomato: '#E81224'
};

export default color;
