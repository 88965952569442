import CustomAvatar from 'components/CustomAvatar/CustomAvatar'
import React from 'react'
import CountContainer from './CountContainer'

const AuthorContainer = ({ name, avatar,count }) => {
    return (
        <div className='author-container'>
            <div className='user-label'>

            <CustomAvatar
                avatar={avatar}
                alt='profile'
                name={name}
                style={{
                    width: '24px',
                    height: '24px',
                    fontSize: '10px',
                    borderRadius: '100%'
                }}
            />
            <span>{name}</span>
            </div>
            <CountContainer count={count}/>
        </div>
    )
}

export default AuthorContainer