import React from 'react'
import './hotkeys.css'
import { CopyAll } from '@mui/icons-material'
import Approved from '../../../../assets/icons/com-approve.svg'
import asQuote from '../../../../assets/icons/com-comment.svg'
import CommentIcon from 'components/CommentIcon/CommentIcon'
import { AiOutlineCopy, AiOutlineLink } from 'react-icons/ai'
const Hotkeys = ({
    isSubmission,
    isClientCon ,
    handleCopyTitle,
    handleCopyWithLineage,
    handleCopyTextSubmission,
    handleCopyClientConversation,
    handleCopyAsQuote,
    handleCopyWithP1,
    handleCopyWithTick
 

}
) => {
    const KeyButton = ({ icon, name, onClick}) => {
        return <div className='key-button' onClick={onClick}>
            <div className='key-icon'>
                {icon}
            </div>
            <span>{name}</span>
        </div>

    }
    const CopySvg=()=>{
        return <svg xmlns="http://www.w3.org/2000/svg" width="9.65" height="12.25" viewBox="0 0 10 14" fill="none">
        <path d="M9.1875 0.875H1.85938C1.79922 0.875 1.75 0.924219 1.75 0.984375V1.75C1.75 1.81016 1.79922 1.85938 1.85938 1.85938H8.64062V11.2656C8.64062 11.3258 8.68984 11.375 8.75 11.375H9.51562C9.57578 11.375 9.625 11.3258 9.625 11.2656V1.3125C9.625 1.07051 9.42949 0.875 9.1875 0.875ZM7.4375 2.625H0.4375C0.195508 2.625 0 2.82051 0 3.0625V10.3182C0 10.4344 0.0464843 10.5451 0.128516 10.6271L2.49785 12.9965C2.52793 13.0266 2.56211 13.0512 2.59902 13.0717V13.0977H2.65645C2.7043 13.1154 2.75488 13.125 2.80684 13.125H7.4375C7.67949 13.125 7.875 12.9295 7.875 12.6875V3.0625C7.875 2.82051 7.67949 2.625 7.4375 2.625ZM2.59766 11.7059L1.42051 10.5273H2.59766V11.7059ZM6.89062 12.1406H3.47266V10.1992C3.47266 9.89707 3.22793 9.65234 2.92578 9.65234H0.984375V3.60938H6.89062V12.1406Z" fill="white"/>
        </svg>
    }
    return (
        <div className='keys-container'>
            <KeyButton icon={<CopySvg/>} onClick={handleCopyTitle} name={'Title'} />
            <KeyButton icon={<img src={Approved} />} onClick={handleCopyWithTick} name={'Done'} />
            <KeyButton icon={<CopySvg/>} onClick={handleCopyWithLineage} name={'with lineage'} />
            {isSubmission && <KeyButton icon={<CommentIcon type={'submission'} />} onClick={handleCopyTextSubmission} name={'Text Submission'} />}
            {isClientCon && <KeyButton icon={<CommentIcon type={'client_conversation'} />} onClick={handleCopyClientConversation} name={'Client Conv'} />}
            <KeyButton icon={<img src={asQuote} />} name={'as quote'} onClick={handleCopyAsQuote}/>
            <KeyButton icon={<CopySvg/>} onClick={handleCopyWithP1} name={<span>with <span className='uni'>✅</span> P1</span>} />

        </div>
    )
}

export default Hotkeys