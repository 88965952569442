import * as axiosBase from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import './Deliveries.css'
import { Backdrop, CircularProgress } from '@material-ui/core';
import AvatarWithAction from '../../components/AvtarWithAction/AvatarWithAction'


const Deliveries = () => {
const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`,
		'Access-Control-Allow-Origin': '*'
	}
});
    const [worksheetList,setWorksheetList]=useState([])
    const [worksheetListByClient,setWorksheetListByClient]=useState()
    const [loading,setLoading]=useState(true);
    const groupBy = (xs, key) => {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	}
    const getWorksheetList = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
		axios
			.get(url)
			.then(data => {
				;
				setWorksheetList(data.data.worksheets);
                setLoading(false)
			})
			.catch(e => console.log(e));
	};

    useEffect(()=>{
        getWorksheetList()

    },[])

	useEffect(() => {
		let groupedWorksheets = groupBy(worksheetList, 'client_name');
		const worksheetsEntries = Object.entries(groupedWorksheets);
		setWorksheetListByClient(worksheetsEntries);
        console.log(worksheetsEntries)
	}, [worksheetList]);


    const list={
        name:'',
         casesArray: [
            'approve',
            'submission',
            'escalation',
            'de-escalation',
            'followup',
            'dos_and_donts',
            'discussion',
            'reopen',
            'involve',
            'comment',
            'client_conversation'
        ]
    }
  return (
    <div className='delivery-list'>
        <Backdrop className='backdrop' open={loading}>
            <CircularProgress color='inherit'/>
        </Backdrop>
        <h2>Deliveries List</h2>
        {
            worksheetListByClient?.map((arr)=>{
                if(arr[0] !== 'undefined'){

                    return <a className='link-container' target='_blank' href={`/deliveries/${arr[0]}`}>{arr[0]}</a>
                }
            })
        }

        {/* {
            list.casesArray.map((item)=>{
                return <div style={{margin:'10px',marginLeft:'50px'}}><AvatarWithAction name={list.name} actionType={item}/></div>
            })
        } */}
        

    </div>
  )
}

export default Deliveries