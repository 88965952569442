import {
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as axiosBase from 'axios';
import API_URLS from 'screens/Team/apiUrls';

import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router-dom';

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});

const PasswordSetup = () => {
	const { token, email } = useParams();

	//	const token = localStorage.getItem('set_password_token');

	//const { isUserLoggedIn, accessToken } = useAuth();
	//console.log('hook', isUserLoggedIn, token);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [message, setMessage] = useState('');
	const [formValues, setFormValues] = useState({
		email: email,
		password: '',
		password_confirmation: ''
	});
	const handleFormChange = element => {
		setFormValues({ ...formValues, [element.name]: element.value });
	};
	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		setErrors([]);

		axios
			.post(
				API_URLS.setPassword,
				{
					token,
					...formValues
				},
				{}
			)
			.then(res => {
				console.log({ res });
				if (res.data.success === true) {
					localStorage.setItem('access_token', res.data.token);
					window.location.href = '/chopping-interface';
				} else {
					setMessage(res.data.message);
					console.log(res.data);
				}
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				if (error.response.status === 422) {
					console.log(error.response.data);
					setErrors(error.response.data.errors);
					setLoading(false);
				}
			});
	};

	return (
		<Grid
			container
			component='main'
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'white'
			}}
		>
			<Grid item xs={12} sm={8} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>{/* <LockOutlinedIcon /> */}</Avatar>
					<Typography component='h1' variant='h5'>
						Set Password
					</Typography>
					{message && <div style={{ color: 'red' }}> {message}</div>}
					<Box
						component='form'
						noValidate
						sx={{ mt: 1, minWidth: '50%' }}
						onSubmit={e => {
							handleSubmit(e);
						}}
					>
						<div>
							{/* <TextField
								margin='normal'
								required
								fullWidth
								id='email'
								label='Email Address'
								name='email'
								autoComplete='email'
								autoFocus
								value={email}
                disabled
								onChange={e => {
									handleFormChange(e.target);
								}}
							/> 
						       {errors.email && <div style={{ color: 'red' }}> {errors.email.toString()}</div>} */}
							<p style={{textAlign :"center"}}>{email}</p>
						</div>

						<div>
							<TextField
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type='password'
								id='password'
								value={formValues.password}
								autoComplete='current-password'
								onChange={e => {
									handleFormChange(e.target);
								}}
							/>
							{errors.password && <div style={{ color: 'red' }}> {errors.password.toString()}</div>}
						</div>
						<div>
							<TextField
								margin='normal'
								required
								fullWidth
								name='password_confirmation'
								label='Confirm Password'
								type='password'
								id='password_cornformation'
								value={formValues.password_confirmation}
								autoComplete='current-password'
								onChange={e => {
									handleFormChange(e.target);
								}}
							/>
							{errors.password_confirmation && (
								<div style={{ color: 'red' }}> {errors.password.toString()}</div>
							)}
						</div>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							style={{ marginTop: 8, margionBottom: 2 }}
						>
							Set password
						</Button>
					</Box>
				</Box>
			</Grid>

			<Backdrop open={loading} style={{ zIndex: 10000 }}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Grid>
	);
};

export default PasswordSetup;
