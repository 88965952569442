import React from 'react';
import { Flex } from 'screens/RapidEstimation/RapidEstimationStyles';
import styled from 'styled-components';
export const TopStatistics = ({ text, count = 0, link, chip }) => {
	return (
		<div
			style={{
				color: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'flex-start'
			}}
		>
			{link ? (
				<StyledA
					style={{
						fontSize: 25,
						color: 'white',
						display: Flex,
						justifyContent: 'center',
						marginBottom: 0
					}}
					href={link}
					target='_blank'
				>
					{count}
				</StyledA>
			) : (
				<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
					<div
						style={{
							fontSize: '25px'
						}}
					>
						{count}
					</div>
					{chip !== 0 && chip && (
						<div
							style={{
								background: '#7A8AED',
								color: 'white',
								borderRadius: '19px',
								padding: '2px 10px',
								textAlign: 'center',
								fontSize: '20px'
							}}
						>
							{chip}
						</div>
					)}
				</div>
			)}

			{/* {text === 'On Hold' || text === 'Unplanned' || text === 'Sleeping' ? ( */}
				<div style={{ fontSize: 12, marginTop: 6, lineHeight: '10px' }}>{text}</div>
			{/* ) : (
				<div style={{ fontSize: 12, marginTop: 6, lineHeight: '10px' }}>{text}</div>
			)} */}
		</div>
	);
};

const StyledA = styled.a`
	color: white;
	font-size: 25;

	&:hover {
		cursor: pointer;
	}
`;
