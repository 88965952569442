import React, { useEffect, useState } from 'react';
import * as axiosBase from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from "@material-ui/core";
import parse from "html-react-parser";
import styles from './Performance.module.css';

const accessToken = localStorage.getItem('access_token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): {};
const axios = axiosBase.create({
	headers: {
		'Accept': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	}
});

function PerformanceFeedback() {
	const [feedback, setFeedback] = useState('');
	const [painpoints, setPainPoints] = useState('');
	const [shortage_of_work, setShortageOfWork] = useState('');
	const [issues, setIssues] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [success, setSuccess] = useState('');
	const [loading, setLoading] = useState(false);
	const [performance, setPerformance] = useState({});

	const id = searchParams.get('userId');

	const checkboxInputs = [
		'Electricity issues',
		'Struggling with complicated tasks',
		'Idle, no tasks to work on',
		'College commitments'
	];

	useEffect(() => {
		getPerformance();
	}, []);

	const getPerformance = () => {
		setLoading(true);
		axios
			.get(
				`${process.env.REACT_APP_LARAVEL_API_URL}/performance/current-week/${user._id}`
			)
			.then(res => {
				setPerformance(res.data.data);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				//setLoading(false);
			});
	};

	const submitFeedback = e => {
		setLoading(true);

		e.preventDefault();
		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/performance/feedback`,
				{
					feedback: feedback,
					painpoints: painpoints,
					shortage_of_work: shortage_of_work,
					issues: issues
				}
			)
			.then(res => {
				if (res.data.success) {
					setSuccess(res.data.message);
					setFeedback('');
					setPainPoints('');
					setShortageOfWork('');
					setIssues([]);
				}
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleIssueChange = e => {
		let newArray = [...issues, e.target.value];
		if (issues.includes(e.target.value)) {
			newArray = newArray.filter(x => x !== e.target.value);
		}
		setIssues(newArray);
	};

	return (
		<div style={{ textAlign: 'center', paddingTop: '10vh' }}>
			<Backdrop className="backdrop" open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop>
			<div style={{ color: 'white', fontSize: '3vh', fontWeight: 'bold' }}>
				Performance Feedback
			</div>

			{success && (
				<div style={{ textAlign: 'center', color: 'green', margin: '3vh 0' }}>{success}</div>
			)}

			<div className='d-flex justify-content-center my-5' style={{ textAlign: 'left', color: 'white' }}>
				<div>
					{performance?.performance_message && parse(performance.performance_message)}
				</div>
			</div>

			<form onSubmit={submitFeedback}>
				<label style={{ color: 'white' }}>Can you share your thoughts?</label>
				<br />
				<textarea
					required
					rows={4}
					name='feedback'
					value={feedback}
					onChange={e => {
						setFeedback(e.target.value);
					}}
					style={{
						width: '50%',
						resize: 'none',
						outline: 'none',
						fontFamily: 'Poppins',
						padding: '1vh 1vw',
						marginBottom: 20
					}}
				/>
				<br />
				<label style={{ color: 'white' }}>Was there any painpoints you were facing?</label>
				<br />
				<textarea
					required
					rows={4}
					name='painpoints'
					value={painpoints}
					onChange={e => {
						setPainPoints(e.target.value);
					}}
					style={{
						width: '50%',
						resize: 'none',
						outline: 'none',
						fontFamily: 'Poppins',
						padding: '1vh 1vw',
						marginBottom: 20
					}}
				/>
				<br />
				<label style={{ color: 'white' }}>
					Your output was less than the excepted. Can you elaborate where the rest of the time go?
				</label>
				<br />
				<textarea
					rows={4}
					name='shortage_of_work'
					value={shortage_of_work}
					required
					onChange={e => {
						setShortageOfWork(e.target.value);
					}}
					style={{
						width: '50%',
						resize: 'none',
						outline: 'none',
						fontFamily: 'Poppins',
						padding: '1vh 1vw',
						marginBottom: 20
					}}
				/>
				<br />
				<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
					{checkboxInputs.map(item => {
						return (
							<div
								style={{
									color: 'white',
									display: 'flex',
									alignItems: 'center',
									gap: '5px',
									justifyContent: 'center'
								}}
							>
								<input type='checkbox' value={item} onChange={e => handleIssueChange(e)} />
								<div>{item}</div>
							</div>
						);
					})}
				</div>
				<br />
				<button
					type='submit'
					style={{ border: 'none', padding: '1vh 1vw', cursor: 'pointer', marginTop: '1vh' }}
				>
					SUBMIT
				</button>
			</form>
		</div>
	);
}

export default PerformanceFeedback;
