import React, { useState } from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { Alert } from '@material-ui/lab';
import { ClickAwayListener, Tooltip, Snackbar } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

export default function OwnerPerformance({
	weeklyData,
	monthlyData,
	weeklyDates,
	monthlyDates,
	weeklyChartData,
	monthlyChartData,
	users
}) {
    return (
		<>
			<div style={{ width: '100%' }}>
				<div style={{ fontWeight: 'bold' }}>Weekly Performance</div>
				{/* <div
					style={{
						display: 'flex',
						width: '100%'
					}}
				>
					<Chart
						options={weeklyOptions}
						series={weeklySeries}
						type='bar'
						height='200'
						style={{ width: '100%' }}
					/>
				</div> */}
				<table>
					<tr>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Tasks Assigned</th>
						<th>Tasks Completed</th>
						<th>Budget Completed</th>
					</tr>
					{weeklyData?.map((item, index) => {
						return (
                            <tr>
                                <td>{moment(item.start_date).format('D MMM YYYY')}</td>
                                <td>{moment(item.end_date).format('D MMM YYYY')}</td>
                                <td>{item.assigned_todos}</td>
                                <td>{item.completed_todos}</td>
                                <td>{item.weekly_completed_budget}</td>
                            </tr>
                        );
					})}
				</table>
			</div>
			<div style={{ width: '100%', marginTop: '10vh' }}>
				<div style={{ fontWeight: 'bold' }}>Monthly Performance</div>
				{/* <div
					style={{
						display: 'flex',
						width: '100%'
					}}
				>
					<Chart
						options={monthlyOptions}
						series={monthlySeries}
						type='bar'
						height='200'
						style={{ width: '100%' }}
					/>
				</div> */}
				<table>
					<tr>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Tasks Assigned</th>
						<th>Tasks Completed</th>
						<th>Budget Completed</th>
					</tr>
					{monthlyData?.map((item, index) => {
						return (
                            <tr>
                                <td>{moment(item.start_date).format('D MMM YYYY')}</td>
                                <td>{moment(item.end_date).format('D MMM YYYY')}</td>
                                <td>{item.assigned_todos}</td>
                                <td>{item.completed_todos}</td>
								<td>{item.monthly_completed_budget}</td>
                            </tr>
                        );
					})}
				</table>
			</div>
		</>
	);
}