import { Backdrop, CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';
import TaskIcon from 'components/TaskIcon/TaskIcon';
import WorksheetSidebar from 'components/WorksheetSidebar/WorksheetSidebar';
import { useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import styles from './WorksheetCompletionList.module.css';

const token = localStorage.getItem('red_wing_token');
const accessToken = localStorage.getItem('access_token');
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: "#fff",
            backgroundColor: "none",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: theme.zIndex.drawer - 1,
            opacity: 0.5,
            height: '100%',
            width: '100%',

        }
    })
);
const WorksheetCompletionList = ({ notificationCount, worksheet_id }) => {
    const [loading, setLoading] = useState(false);
    const [activeWorksheet, setActiveWorksheet] = useState(undefined);
    const [tasks, setTasks] = useState([]);
    const [worksheets, setWorksheets] = useState([]);
    const [hideChilds, setHideChilds] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    // const { worksheet_id } = useParams();
    const backDropClass = useStyles()


    useEffect(() => {
        if (worksheet_id) {
            setActiveWorksheet(worksheet_id);
        }
    }, [worksheet_id]);

    const getTodoList = () => {
        setLoading(true);
        const url =
            `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet/${activeWorksheet}/completion/today`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        })
            .then(data => data.json())
            .then(data => {
                setWorksheets(data.worksheets);
                setTasks(data.tasks);
                setTotalHours(data.total_hours);
            })
            .catch(e => console.log(e))
            .finally(e => { setLoading(false); });
    };

    const handleWorksheetChange = (e, worksheet_id) => {
        e.preventDefault();
        if ((worksheet_id !== activeWorksheet?._id)) {
            setTasks([]);
            setActiveWorksheet(worksheet_id);
            window.history.pushState('', '', `/chopping-interface/${worksheet_id}/completion/today`);
        }
    };

    useEffect(() => {
        if (activeWorksheet) {
            getTodoList();
        }
    }, [activeWorksheet]);


    return (
        <div>
            {/* <Backdrop className="backdrop" open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop> */}
            <div className={`body_container worksheet_body`}>
                {/* <div className={`worksheet_left_sidebar`}>
                    <WorksheetSidebar activeWorksheet={activeWorksheet} notificationCount={notificationCount} handleWorksheetChange={handleWorksheetChange} />
                </div> */}
                <div style={{ zIndex: '1' }} className={`main-content on-mobile-padding`}>
                    <Backdrop className={backDropClass.root} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {/* <div className='px-1'>
                        <h1 className={`page-title`}>{worksheets && worksheets[0] ? worksheets[0]?.title || worksheets[0].first_task : ''}</h1>
                    </div> */}

                    <div style={{ zIndex: '10', width: '99%' }} className={styles.trans}>
                        {/* <div style={{ marginBottom: '20px' }}>
                            <span className={`pl-1 pr-3 ${styles.listingsHeading} text-normal text-muted`}>
                                <Link className='text-normal text-muted' to={`/chopping-interface/${activeWorksheet}`}><span>{totalHours}</span> hours of work</Link>
                            </span>
                            <span className={`px-3 `}>
                                <Link className='text-normal text-muted' to={`/chopping-interface/${activeWorksheet}/activity`}>Activity</Link>
                            </span>
                            <span className={`px-3`}>
                                <span className='text-normal'>Completion</span>
                            </span>
                        </div> */}
                        {tasks.length > 0 &&
                            tasks
                                .filter(function (item) {
                                    return item.parent_id === 0 || item.parent_id === '' || item.parent_id === null;
                                })
                                .sort((a, b) => a.seq - b.seq)
                                .map((item, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            item={item}
                                            index={index}
                                            tasks={tasks}
                                            hideChilds={hideChilds}
                                            setHideChilds={setHideChilds}
                                            worksheets={worksheets}
                                        />
                                    );
                                })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ListItem = ({
    index,
    item,
    tasks,
    hideChilds,
    setHideChilds,
    worksheets,
    depth = 0
}) => {

    const filteredTodo = tasks
        .filter(function (childItem) {
            return childItem.parent_id === item._id;
        })
        .sort((a, b) => a.seq - b.seq);
    const parent = [...tasks].filter(function (value) {
        return value._id !== item.parent_id;
    })[0];

    return (
        <div className={styles.rowContainer}>
            {
                (item.parent_id === 0 || item.parent_id === "" || item.parent_id === null) &&
                <div>
                    <div>
                        <DisplayBreadcrumbs item={item} tasks={tasks} worksheets={worksheets} />
                    </div>
                </div>
            }

            <div
                className='d-flex'
                style={{
                    width: '100%',
                    marginLeft: (item.parent_id === 0 || item.parent_id === "" || item.parent_id === null) ? '0px' : '15px'
                }}
            >

                {filteredTodo.length > 0 && hideChilds.includes(item._id) && (
                    <div
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '20px', cursor: 'pointer', marginLeft: '-20px', marginBottom: '5px' }}
                    >
                        <AiOutlineRight onClick={(e) => {
                            setHideChilds((prev) => {
                                return prev.filter(function (value) {
                                    return value !== item._id;
                                });
                            });
                        }} />
                    </div>
                )}

                {/* checkbox */}
                <div style={{ width: '20px' }}>
                    <div className='d-flex justify-content-center'>
                        <TaskIcon item={item} />
                    </div>
                    {filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
                        <div
                            className={`d-flex justify-content-center align-items-center ${styles.verticalLinesContainer}`}
                            onClick={e => {
                                setHideChilds(prev => [...prev, item._id]);
                            }}
                        >
                            {filteredTodo.length > 0 && !hideChilds.includes(item._id) && (
                                <div className={styles.verticalLines}></div>
                            )}
                        </div>
                    )}
                </div>

                <div style={{ width: 'calc(100% - 30px)' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginLeft: `10px`,
                            marginBottom: '5px'
                        }}
                    >
                        {/* estimate */}
                        {(
                            <div className='d-flex align-items-end' style={{}}>
                                {item.estimate !== 0 ? (
                                    <div
                                        className='d-flex align-items-end'
                                        style={{
                                            // border: `1px solid ${
                                            // 	item.estimate <= 2 || filteredTodo.length > 0
                                            // 		? '#5FB924'
                                            // 		: item.estimate > 2 && item.estimate < 5
                                            // 		? '#FCBA03'
                                            // 		: '#FF0000'
                                            // }`,

                                            // textAlign: 'center',
                                            borderRadius: '12px',
                                            color: `${item.estimate <= 2 || filteredTodo.length > 0
                                                ? '#5FB924'
                                                : item.estimate > 2 && item.estimate < 5
                                                    ? '#FCBA03'
                                                    : '#FF0000'
                                                }`,
                                            fontSize: '12px',
                                            lineHeight: '13px',
                                            padding: '1px 0',
                                            fontFamily: 'Roboto Mono',
                                            fontStyle: 'normal'
                                        }}
                                    >
                                        <p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
                                    </div>
                                ) : item.estimate !== 0 && item.completed ? (
                                    <div
                                        className='d-flex align-items-end'
                                        style={{
                                            // background: '#5FB924',

                                            // textAlign: 'center',
                                            // borderRadius: '12px',
                                            fontSize: '12px',
                                            lineHeight: '13px',
                                            // border: '1px solid #5FB924',
                                            padding: '1px 0',
                                            color: '#5FB924',
                                            fontFamily: 'Roboto Mono',
                                            fontStyle: 'normal'
                                        }}
                                    >
                                        <p className='m-0 p-0'>{item.estimate.toFixed(2)}</p>
                                    </div>
                                ) : (
                                    <div
                                        className='d-flex align-items-end'
                                        style={{
                                            // textAlign: 'center',
                                            borderRadius: '12px',
                                            fontSize: '12px',
                                            lineHeight: '13px',
                                            padding: '1px 0',
                                            opacity: '60%',
                                            fontFamily: 'Roboto Mono',
                                            fontStyle: 'normal'
                                        }}
                                    >
                                        <p className='m-0 p-0'>0.00</p>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* input value */}
                        <div style={{ position: 'relative', width: '100%' }}>
                            <Link
                                style={{
                                    background: 'transparent',
                                    color: 'white',
                                    border: 0,
                                    fontFamily: 'Poppins',
                                    fontSize: '13px',
                                    width: '100%',
                                    opacity: item.estimate === 0 && (filteredTodo.length === 0 || filteredTodo.map(value => value.estimate)
                                        .reduce((prev, next) => prev + next) === 0) ? '60%' : '100%'
                                }}
                                to={`/chopping-interface/${item.worksheet_id}?item_id=${item._id}`}
                            >
                                {item.title !== '' ? item.title : ''}
                            </Link>

                        </div>
                        {/* {item.assginee !== 0 &&
                        users &&
                        users.map(user => {
                            if (user.user_id === Number(item.assginee)) {
                                return <div style={{ color: '#7A8AED' }}>{`@${user.name}`}</div>;
                            }
                        })} */}
                    </div>

                    {!hideChilds.includes(item._id) &&
                        filteredTodo.map((childItem, childIndex) => {
                            var childPrevItem = tasks.filter(function (childItem) {
                                return childItem.parent_id === item._id;
                            })[childIndex === 0 ? 0 : childIndex - 1];
                            return (
                                <ListItem
                                    key={'child-' + childIndex}
                                    item={childItem}
                                    index={childIndex}
                                    tasks={tasks}
                                    depth={depth + 1}
                                    hideChilds={hideChilds}
                                    setHideChilds={setHideChilds}
                                    worksheets={worksheets}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default WorksheetCompletionList;