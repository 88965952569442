import React from 'react';
import CommentIcon from 'components/CommentIcon/CommentIcon';

const TaskIcon = ({ item, ...otherProps  }) => {
    return (
        <>
            {item.completed && (
                <CommentIcon type={'approve'} {...otherProps}/>
            )}
            {!item.completed && item.last_comment_type !== 'comment' && item.last_comment_type !== 'approve' &&(
                <CommentIcon type={item.last_comment_type} {...otherProps}/>
            )}
            {!item.completed && (item.last_comment_type === 'comment' || (item.last_comment_type === 'approve' && !item.completed) ) &&(
                <CommentIcon type={false} {...otherProps}/>
            )}
        </>
    )
};

export default TaskIcon;
