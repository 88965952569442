import { createSlice } from '@reduxjs/toolkit';

const searchIndexSlice = createSlice({
  name: 'data',
  initialState: {
    documents: []
  },
  reducers: {
    setSearchIndex: (state, action) => {
      state.documents = action.payload;
    },
  },
});

export const { setSearchIndex } = searchIndexSlice.actions;

export default searchIndexSlice.reducer;