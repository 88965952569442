import React from 'react';
import {
	BlueTick,
	DownArrowOrange,
	UpArrowOrange,
	UserIcon,
	GreenCheck,
	TreeOpenIcon,
	CommentTypeNew,
	InvolveNew,
	DiscussionNew,
	ReOpenNew,
	DoDontNew,
	ApprovedStickerNew,
	EscalateCommonNew,
	ClientConversationNew,
	SubmissionNew
} from 'assets/icons';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { AiFillWechat, AiOutlineWarning } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';

const CommentIcon = ({ type, ...otherProps }) => {
	switch (type) {
		case 'escalation':
			return <EscalateCommonNew {...otherProps} />;
		case 'de-escalation':
			return <EscalateCommonNew {...otherProps} />;
		case 'followup':
			return <EscalateCommonNew {...otherProps} />;
		case 'approve':
			return <ApprovedStickerNew {...otherProps} />;
		case 'client_conversation':
			return <ClientConversationNew {...otherProps} />;
		case 'submission':
			return <SubmissionNew {...otherProps} />;
		case 'comment':
			return <CommentTypeNew {...otherProps} />;
		case 'discussion':
			return <DiscussionNew {...otherProps} />;
		case 'dos_and_donts':
			return <DoDontNew {...otherProps} />;
		case 'involve':
			return <InvolveNew {...otherProps} />;
		case 'reopen':
			return <ReOpenNew {...otherProps} />;
		default:
			return <TreeOpenIcon {...otherProps} />;
	}
};

export default CommentIcon;
