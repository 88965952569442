import React from 'react'
import estimateIcon from '../../../../assets/icons/estimateIcon.svg';
import authorIcon from '../../../../assets/icons/user-icon.png';
import calenderIcon from '../../../../assets/icons/calender.svg';
import tree from '../../../../assets/icons/tree.png';
import './metaData.css'

const TaskMetaDataContainer = ({ type, dataName, dataValue }) => {
    const getIconType=()=>{
        switch(type){
            case 'estimate':
                return <img src={estimateIcon} alt="estimateIcon"/>
            case 'author':
                return <img src={authorIcon} alt="authorIcon"/>
            case 'created' :
                return <img src={calenderIcon} alt="calenderIcon"/>
            case 'subtask' :
                return <img src={tree} alt="treeIcon"/>
        }

    }
    return (
        <div className='meta-data-container'>
            {getIconType()}
            <div>
                <span className='data-name'>{dataName}</span>
                <span className='data-value'>{dataValue}</span>
            </div>


        </div>
    )
}

export default TaskMetaDataContainer